import * as PIXI from "pixi.js"
import { formatSeconds } from "../utils/format"
import Markers, { cutMarkers, markMarkers, spanHints, spanMarkers } from "./markers"
import { BeginEnd, Cut, CutMark, Mark, Span } from "./utils"
import { drawLine } from "./lines"
import _ from "lodash"
import { DATA_FREQUENCY } from "../utils/constants"
import { addCuts, removeCuts } from "../utils/cutting"

export const GRAPH_HEIGHT = 140
export const GRAPH_PADDING_TOP = 34
const MARKER_SPACE_HEIGHT = 16
const GRAPH_DATA_PADDING = 5
const STROKE_WIDTH: number = 1.5
const TICK_MARKER_COLOR = 0x333333
const PREDICTION_MARKER_COLOR = 0x2d35cf
const TIME_MARKER_RESOLUTION = 20
const PREDICTION_MARKER_OFFSET = -2

interface PredictionElement {
  time_start: number
  time_end: number
  type: string
}

type editMarkT = (v: { id: string; reptime: number }) => void
type editSpanT = (v: { id: string; values: BeginEnd }) => void

type colorMapper = (idx: number) => string

interface ConstructorParams {
  container: PIXI.Container
  data: number[][]
  interactionmanager: PIXI.InteractionManager
  editMark: editMarkT
  editSpan: editSpanT

  cuts: Cut[]
  color: colorMapper
}

class Graph {
  container: PIXI.Container
  interactionmanager: PIXI.InteractionManager

  repmarkers: Markers<Mark>
  spanmarkers: Markers<Span>
  spanhints: Markers<Span>

  cutmarkers: Markers<CutMark>

  cuts: Cut[]

  editMark: editMarkT
  editSpan: editSpanT

  linesContainer: PIXI.Container
  lineContainers: PIXI.Container[]
  predictionContainer: PIXI.Container

  constructor(params: ConstructorParams) {
    this.container = params.container
    this.interactionmanager = params.interactionmanager
    this.cuts = params.cuts

    this.repmarkers = markMarkers(params.interactionmanager, (id, pos) =>
      params.editMark({ id, reptime: this.toTime(pos) }),
    )
    this.spanmarkers = spanMarkers(params.interactionmanager, (id, vals) =>
      params.editSpan({
        id,
        values: _.mapValues(vals, v => (v ? this.toTime(v) : v)),
      }),
    )
    this.spanmarkers.container.name = "span markers"

    this.spanhints = spanHints()
    this.spanhints.container.name = "span hints"

    this.cutmarkers = cutMarkers()
    this.cutmarkers.container.name = "cuts"

    this.editMark = params.editMark
    this.editSpan = params.editSpan

    // Build UI
    this.linesContainer = new PIXI.Container()
    this.linesContainer.name = "lines"
    this.lineContainers = []
    this._redrawLines(params.data, params.color)

    const ticks = this._makeTickMarkers(params.data)

    this.predictionContainer = new PIXI.Container()
    this.predictionContainer.name = "prediction"

    this.container.addChild(
      this.linesContainer,
      ticks,
      this.predictionContainer,
      this.repmarkers.container,
      this.spanmarkers.container,
      this.spanhints.container,
      this.cutmarkers.container,
    )
  }

  _redrawLines(sensordata: number[][], color: (idx: number) => string) {
    const graphMask = new PIXI.Graphics()
    graphMask.name = "mask"

    let maskX = 0
    let maskWidth = 0
    this.lineContainers = sensordata.map((data, idx) => {
      const line = drawLine(data, GRAPH_HEIGHT, GRAPH_DATA_PADDING, STROKE_WIDTH, color(idx))
      line.name = `line-${idx}`
      line.y = GRAPH_PADDING_TOP

      maskX = Math.min(maskX, line.x)
      maskWidth = Math.max(maskWidth, line.width)

      line.mask = graphMask

      const container = new PIXI.Container()
      container.name = `line-${idx}`
      container.addChild(line)
      return container
    })

    graphMask.beginFill()
    graphMask.drawRect(maskX, GRAPH_PADDING_TOP, maskWidth, GRAPH_HEIGHT)
    graphMask.endFill()

    this.linesContainer.removeChildren()
    this.linesContainer.addChild(graphMask, ...this.lineContainers)
  }

  _makeTickMarkers(sensordata: number[][]): PIXI.Container {
    const ycoord = GRAPH_HEIGHT + GRAPH_PADDING_TOP + MARKER_SPACE_HEIGHT + 4
    const gfx = new PIXI.Graphics().lineStyle(0.5, TICK_MARKER_COLOR, 0.5)

    const res = new PIXI.Container()
    res.addChild(gfx)

    for (
      let seconds = TIME_MARKER_RESOLUTION;
      seconds < this.toTime(sensordata[0].length);
      seconds += TIME_MARKER_RESOLUTION
    ) {
      const xcoord = this.toCoord(seconds)
      const timetext = new PIXI.Text(formatSeconds(seconds), { fontSize: 12 })
      timetext.anchor.x = 0.5
      timetext.x = xcoord
      timetext.y = ycoord
      res.addChild(timetext)

      gfx.moveTo(xcoord, GRAPH_PADDING_TOP)
      gfx.lineTo(xcoord, ycoord)
    }

    return res
  }

  toCoord(x: number): number {
    const t = removeCuts(x, this.cuts)
    return t * DATA_FREQUENCY
  }

  toTime(x: number): number {
    const t = x / DATA_FREQUENCY
    return addCuts(t, this.cuts)
  }

  updateMarkers(
    spans: { [uuid: string]: Span },
    reps: { [uuid: string]: Mark },
    cutMarks: { [uuid: string]: CutMark },
  ) {
    this.spanmarkers.update(spans)
    this.repmarkers.update(reps)
    this.spanhints.update(spans)
    this.cutmarkers.update(cutMarks)
  }

  updateCursor(pos: number) {
    this.spanmarkers.cursorMove(pos)
    this.repmarkers.cursorMove(pos)
    this.spanhints.cursorMove(pos)
  }

  // set the visibility of lines
  updateLines(lineIndices: number[]) {
    this.lineContainers.forEach((c, idx) => {
      c.visible = lineIndices.includes(idx)
    })
  }

  redrawLines(sensordata: number[][], color: (idx: number) => string) {
    this._redrawLines(sensordata, color)
  }

  updateOverlay(prediction: PredictionElement[], enabled: boolean) {
    this.predictionContainer.removeChildren()
    if (!enabled) {
      return
    }
    const predictionMarker = new PIXI.Graphics().lineStyle(2.0, PREDICTION_MARKER_COLOR)

    prediction.forEach(el => {
      const startX = (el.time_start / 1_000_000) * 25
      const endX = (el.time_end / 1_000_000) * 25

      const top = GRAPH_PADDING_TOP + PREDICTION_MARKER_OFFSET
      const label = new PIXI.Text(el.type, { fontSize: 10 })
      label.x = startX
      label.y = GRAPH_PADDING_TOP + PREDICTION_MARKER_OFFSET - 10
      const lineTop = top
      this.predictionContainer.addChild(label)
      predictionMarker.moveTo(startX, lineTop)
      predictionMarker.lineTo(endX, lineTop)
    })
    this.predictionContainer.addChild(predictionMarker)
  }
}

export default Graph
