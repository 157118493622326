import { createSelector } from "reselect"
import { RootState } from ".."
import _ from "lodash"
import { InflatedExercise, InflatedSpans } from "../types"
import { SensorType } from "../../utils/sensorType"
import { getEquipmentDef } from "../../services/firebase/firestore/types/definitions"

export const allInflatedSpans = createSelector(
  (state: RootState) => state.currentTaggingSession.spans,
  (state: RootState) => state.currentTaggingSession.marks,
  (state: RootState) => state.currentTaggingSession.exercises,
  (state: RootState) => state.definitions.exercises,
  (state: RootState) => state.definitions.equipment,
  (spans, marks, exercises, exerciseDefs, equipmentDefs): InflatedSpans =>
    _.mapValues(spans, (span, id) => {
      const ex = exercises[span.ref]
      return {
        id: id,
        ignoredBy: span.ignoredBy || [],
        exercise: {
          id: span.ref,
          def: exerciseDefs[ex.type] || { id: ex.type, name: ex.type },
          modifiers: ex.modifiers,
          equipment: ex.equipment?.map(eq => ({
            def: getEquipmentDef(equipmentDefs, eq.type),
            measure: eq.measure,
          })),
        },
        begin: span.begin,
        end: span.end,
        marks: span.end
          ? Object.values(marks)
              .map(m => m.time)
              .filter(m => m > span.begin && m < span.end!)
          : [],
      }
    }),
)

export const selectSpanUnderCursor = createSelector(
  (state: RootState) => state.currentTaggingSession.graphCursor,
  allInflatedSpans,
  (cursorPos, ispans) => _.find(ispans, ispan => !!(ispan.end && ispan.begin < cursorPos && ispan.end >= cursorPos)),
)

export const selectOpenSpan = createSelector(allInflatedSpans, ispans =>
  _.find(ispans, ispan => ispan.end === undefined),
)

export const selectExerciseRepCounts = createSelector(
  (state: RootState) => state.currentTaggingSession.spans,
  (state: RootState) => state.currentTaggingSession.marks,
  (spans, marks) =>
    _.reduce(
      spans,
      (acc, span) => {
        const repsInSpan = _.filter(marks, m => m.time > span.begin && m.time < (span.end ?? 0)).length
        acc[span.ref] = _.get(acc, span.ref, 0) + repsInSpan
        return acc
      },
      {} as Record<string, number>,
    ),
)

export const selectInflatedExercises = createSelector(
  (state: RootState) => state.currentTaggingSession.exercises,
  (state: RootState) => state.definitions.exercises,
  (state: RootState) => state.definitions.equipment,
  (exercises, exerciseDefs, equipmentDefs): { [id: string]: InflatedExercise } =>
    _.mapValues(exercises, (ex, id) => ({
      id: id,
      def: exerciseDefs[ex.type] || { id: ex.type, name: ex.type },
      modifiers: ex.modifiers,
      equipment: ex.equipment?.map(eq => ({
        def: getEquipmentDef(equipmentDefs, eq.type),
        measure: eq.measure,
      })),
    })),
)

export const selectGraphClicked = createSelector(
  (state: RootState) => state.currentTaggingSession.graphClickedTime,
  d => d,
)

export const selectvisibleDataSources = createSelector(
  (state: RootState) => state.currentTaggingSession.visibleDataSources,
  d => d,
)

export const selectEnabledGraphAxes = createSelector(
  (state: RootState) => state.currentTaggingSession.enabledGraphAxes,
  d => d,
)

export const selectEnabledPredictionAxes = createSelector(
  (state: RootState) => state.currentTaggingSession.enabledGraphAxes,
  d => d[SensorType.Prediction],
)
