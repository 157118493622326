import * as PIXI from "pixi.js"
import Marker, { SimpleMarker, StaticMarker } from "./marker"
import ExerciseMarker from "./exercisemarker"
import { BeginEnd, CutMark, Mark, Span } from "./utils"

const HANDLE_SIZE = 10

interface MarkersParams<T> {
  create: (id: string, pos: T) => Marker<T>
}

class Markers<T> {
  container: PIXI.Container
  markers: { [uuid: string]: Marker<T> }
  create: (id: string, pos: T) => Marker<T>

  constructor(p: MarkersParams<T>) {
    this.container = new PIXI.Container()
    this.markers = {}

    this.create = p.create
  }

  update(markerValues: { [uuid: string]: T }) {
    Object.entries(markerValues).forEach(([id, pos]) => {
      if (this.markers[id]) {
        this.markers[id].update(pos)
      } else {
        const m = this.create(id, pos)

        this.markers[id] = m
        this.container.addChild(m.displayobject)
      }
    })

    Object.entries(this.markers)
      .filter(([id, m]) => markerValues[id] === undefined)
      .forEach(([id, m]) => {
        this.container.removeChild(m.displayobject)
        delete this.markers[id]
      })
  }

  cursorMove(pos: number) {
    Object.values(this.markers).forEach(m => m.cursorMove(pos))
  }
}

export function markMarkers(
  interactionmanager: PIXI.InteractionManager,
  onDragEnd: (id: string, pos: number) => void,
): Markers<Mark> {
  return new Markers({
    create: (id, m) => {
      const color = PIXI.utils.string2hex(m.color)
      return new SimpleMarker({
        interactionmanager: interactionmanager,
        initpos: m.time,
        toPos: m => m.time,
        create: () =>
          new PIXI.Graphics()
            .beginFill(color)
            .drawCircle(0, 172, HANDLE_SIZE / 2)
            .endFill()
            .lineStyle(1, color)
            .moveTo(0, 172)
            .lineTo(0, 16),
        onDragEnd: pos => onDragEnd(id, pos),
      })
    },
  })
}

export function spanMarkers(
  interactionmanager: PIXI.InteractionManager,
  onDragEnd: (id: string, ex: BeginEnd) => void,
): Markers<Span> {
  return new Markers({
    create: (id, ex) =>
      new ExerciseMarker(
        interactionmanager,
        ex.begin,
        ex.end || ex.begin,
        val => onDragEnd(id, val),
        ex.end === undefined,
        ex.fillColor ? PIXI.utils.string2hex(ex.fillColor) : undefined,
      ),
  })
}

export function cutMarkers(): Markers<CutMark> {
  return new Markers({
    create: (_, cut) =>
      new StaticMarker({
        initpos: cut.pos,
        create: () => {
          const c = new PIXI.Container()
          c.addChild(new PIXI.Graphics().lineStyle(4, 0xff0000).moveTo(0, 172).lineTo(0, 12))
          let text = new PIXI.Text(`${cut.len.toFixed(2)}s`, { fontSize: 16 })
          text.x = 10
          text.y = 10
          c.addChild(text)
          return c
        },
        toPos: cut => cut.pos,
        updateContent: (displayobject: PIXI.DisplayObject, cut: CutMark) => {
          // TODO: cuts are currently static, so there's nothing to update
        },
      }),
  })
}

export function spanHints(): Markers<Span> {
  return new Markers({
    create: (_, ex) =>
      new StaticMarker({
        initpos: ex.begin,
        create: () => {
          const el = new PIXI.Text(ex.label, { fontSize: 10 })
          el.y = 180
          return el
        },
        toPos: ex => ex.begin,
        updateContent: (displayobject: PIXI.DisplayObject, ex: Span) => {
          const el = displayobject as PIXI.Text
          if (el.text !== ex.label) {
            el.text = ex.label
          }
        },
      }),
  })
}

export default Markers
