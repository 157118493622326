import { Button, Theme } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import TextField from "@mui/material/TextField"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { addRepsEvenly } from "../../store/session/reducer"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textfield: {
      borderColor: "#3f51b5",
      margin: theme.spacing(3, 0),
      width: 70,
    },
    buttons: {
      margin: theme.spacing(3, 3),
    },
  }),
)

function AddReps() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [count, setCount] = useState(0)

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    let repCount = Number(e.target.value)
    repCount > 0 ? setCount(repCount) : setCount(0)
  }

  const onClickHandler = (): void => {
    dispatch(addRepsEvenly(count))
  }

  return (
    <>
      <TextField
        id="outlined-required"
        label="Reps"
        type="number"
        size="small"
        variant="outlined"
        className={classes.textfield}
        value={count}
        onChange={onChangeHandler}
        onKeyDown={e => e.stopPropagation()}
      />
      <Button variant="contained" size="small" color="primary" className={classes.buttons} onClick={onClickHandler}>
        Add Reps
      </Button>
    </>
  )
}

export default AddReps
