export type ExerciseDefinitions = Record<string, ExerciseDefinition>
export type ExerciseDefinition = {
  id: string
  parent?: string

  name: string
  short_name?: string
  super_short_name?: string

  measured_as?: string

  equipment?: string[]
  modifiers?: string[]
  tags?: string[]
}

export type EquipmentDefinitions = Record<string, EquipmentDefinition>
export type EquipmentDefinition = {
  id: string
  measure: string
  name: string
  short_name: string
}

export type ExerciseInstances = Record<string, ExerciseInstance>
export type ExerciseInstance = {
  id: string
  exercise: ExerciseDefinition
  equipment?: EquipmentDefinition
}

export const getEquipmentDef = (defs: EquipmentDefinitions, type: string): EquipmentDefinition => {
  return defs[type] ?? { id: type, name: type, measure: "", short_name: type }
}
