import * as PIXI from "pixi.js"
import Markers from "../markers"
import { BeginEnd } from "../utils"
import Marker, { SimpleMarker, StaticMarker } from "../marker"

export interface VerifyRep {
  time: number
  verified: boolean
}

export interface VerifySpan {
  label: string
  begin: number
  end: number
  verified_begin: boolean
  verified_end: boolean
  verified_exercise: boolean
  ignored: boolean
}

const HANDLE_SIZE = 21

export function verifyRepMarkers(
  interactionManager: PIXI.InteractionManager,
  onDragEnd: (id: string, pos: number) => void,
): Markers<VerifyRep> {
  return new Markers({
    create: (id, m) => {
      const draw = (m: VerifyRep, g: PIXI.Graphics) => {
        const color = PIXI.utils.string2hex("#8F9FA9")
        const midY = 110
        const top = 10
        const bottom = 200
        g.clear()
        g.lineStyle(1, color).moveTo(0, bottom).lineTo(0, top)

        if (m.verified) {
          g.beginFill(color)
            .drawCircle(0, midY, HANDLE_SIZE / 2)
            .endFill()
        } else {
          g.beginFill(PIXI.utils.string2hex("#ffffff"))
            .drawCircle(0, midY, HANDLE_SIZE / 2)
            .endFill()
            .lineStyle(2, color)
            .drawCircle(0, midY, HANDLE_SIZE / 2)
        }
        return g
      }

      return new SimpleMarker({
        interactionmanager: interactionManager,
        initpos: m.time,
        toPos: m => m.time,
        onDragEnd: pos => onDragEnd(id, pos),
        updateContent: (displayobject, t) => {
          draw(t, displayobject)
        },
        create: () => draw(m, new PIXI.Graphics()),
      })
    },
  })
}

interface VerifyMark {
  pos: number
  verified: boolean
}

class VerifyExerciseMarker {
  displayobject: PIXI.DisplayObject
  private leftMarker: Marker<VerifyMark>
  private rightMarker: Marker<VerifyMark>

  constructor(
    interactionManager: PIXI.InteractionManager,
    span: VerifySpan,
    updateBounds: (v: { begin?: number; end?: number }) => void,
  ) {
    const draw = (t: VerifyMark, g: PIXI.Graphics) => {
      const gray = PIXI.utils.string2hex("#2F4858")
      const white = PIXI.utils.string2hex("#ffffff")
      const top = -16
      const bottom = 236
      g.clear()
      g.lineStyle(3, gray).moveTo(0, top).lineTo(0, bottom)

      g.beginFill(t.verified ? gray : white)
        .drawCircle(0, top, HANDLE_SIZE / 2)
        .endFill()
      if (!t.verified) {
        g.lineStyle(2, gray).drawCircle(0, top, HANDLE_SIZE / 2)
      }

      return g
    }

    this.leftMarker = new SimpleMarker({
      interactionmanager: interactionManager,
      initpos: span.begin,
      toPos: t => t.pos,
      onDragEnd: pos => updateBounds({ begin: pos }),
      updateContent: (displayobject, t) => {
        draw(t, displayobject)
      },
      create: () => draw({ pos: span.begin, verified: span.verified_begin }, new PIXI.Graphics()),
    })
    this.rightMarker = new SimpleMarker({
      interactionmanager: interactionManager,
      initpos: span.end,
      toPos: t => t.pos,
      onDragEnd: pos => updateBounds({ end: pos }),
      updateContent: (displayobject, t) => {
        draw(t, displayobject)
      },
      create: () => draw({ pos: span.end, verified: span.verified_end }, new PIXI.Graphics()),
    })

    const container = new PIXI.Container()
    container.addChild(this.leftMarker.displayobject, this.rightMarker.displayobject)

    this.displayobject = container
  }

  update(ex: VerifySpan) {
    this.leftMarker.update({ pos: ex.begin, verified: ex.verified_begin })
    this.rightMarker.update({ pos: ex.end, verified: ex.verified_end })
  }

  cursorMove(pos: number) {
    // no-op
  }
}

export function verifySpanMarkers(
  interactionManager: PIXI.InteractionManager,
  onDragEnd: (id: string, ex: BeginEnd) => void,
): Markers<VerifySpan> {
  return new Markers({
    create: (id, ex) => new VerifyExerciseMarker(interactionManager, ex, val => onDragEnd(id, val)),
  })
}

export function verifySpanHints(): Markers<VerifySpan> {
  return new Markers({
    create: (_, ex) => {
      const redraw = (text: PIXI.Text, ex: VerifySpan) => {
        text.style.fill = PIXI.utils.string2hex(ex.verified_exercise ? "#2F4858" : "#ff0000")
        if (ex.ignored) {
          text.style.fill = PIXI.utils.string2hex("#987828")
          text.text = `IGNORED: ${ex.label}`
        } else {
          text.text = ex.label
        }
      }

      return new StaticMarker({
        initpos: ex.begin,
        create: () => {
          const text = new PIXI.Text(ex.label, { fontSize: 18 })
          text.y = 230
          redraw(text, ex)
          return text
        },
        toPos: ex => ex.begin,
        updateContent: (displayobject, t) => {
          redraw(displayobject, t)
        },
      })
    },
  })
}
