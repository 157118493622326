import { SyntheticEvent, useContext, useState } from "react"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import CircularProgress from "@mui/material/CircularProgress"
import PublishIcon from "@mui/icons-material/Publish"
import SaveIcon from "@mui/icons-material/Save"
import DoneIcon from "@mui/icons-material/Done"
import CallSplitIcon from "@mui/icons-material/CallSplit"
import { Theme } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "../../store"
import { setCurrentProposalID, unsetDirtyBit } from "../../store/session/reducer"
import { FirestoreService } from "../../services/firebase"
import { WorkoutExecution } from "../../services/firebase/firestore/types/scribe"
import {
  ScribeExecutionProposalLabelingMetrics,
  ScribeExecutionProposalMetadata,
  ScribeExecutionProposalStatus,
} from "../../services/firebase/firestore/types/scribe-execution-proposal"
import userContext from "../../context/user-context"
import dayjs from "dayjs"
import { inflatedExerciseToExercise, InflatedSpan, InflatedSpans } from "../../store/types"
import { allInflatedSpans } from "../../store/session/selectors"
import { generateStormBlueprint, promoteProposal } from "../../services/backend"
import _ from "lodash"

type OwnProps = {
  scribeID: string
  scribeExecutionID: string
  proposalMetadata?: ScribeExecutionProposalMetadata
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      "& Button": {
        margin: theme.spacing(5, 0),
      },
    },
    ml1: {
      marginLeft: theme.spacing(1),
    },
  }),
)

function buildExecution(ispans: InflatedSpans): WorkoutExecution {
  const getTags = (ispan: InflatedSpan) => {
    let tagType = "rep"
    if ((ispan.exercise.def.tags || []).includes("cadence")) {
      tagType = "cadence"
    }

    return Object.values(ispan.marks)
      .map(t => Math.floor(t * 1000000))
      .map(t => ({
        type: tagType,
        time: t,
      }))
  }

  return Object.values(ispans)
    .map(ispan => {
      const tags = getTags(ispan)
      return {
        time_start: Math.floor(ispan.begin * 1000000),
        time_end: Math.floor(ispan.end! * 1000000),
        exercise: inflatedExerciseToExercise(ispan.exercise),
        volume: tags.filter(t => t.type === "rep").length,
        tags: tags,
        ignored_by: ispan.ignoredBy,
        // Legacy
        type: ispan.exercise.def.id,
        reps: tags.filter(t => t.type === "rep").map(t => t.time),
      }
    })
    .sort((a, b) => a.time_start - b.time_start)
}

function SaveScribeButton({ scribeID, scribeExecutionID, proposalMetadata }: OwnProps) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [isInFlight, setIsInFlight] = useState(false)

  const ispans = useSelector(allInflatedSpans)
  const splits = useSelector((state: RootState) => {
    return _.filter(state.currentTaggingSession.marks, m => m.type === "split")
      .map(m => Math.floor(m.time * 1000000))
      .sort((a, b) => a - b)
  })
  const isSessionDirty = useSelector((state: RootState) => state.currentTaggingSession.isDirty)
  const currentProposalID = useSelector((state: RootState) => state.currentTaggingSession.currentProposalID)
  const activeDuration = useSelector((state: RootState) => state.currentTaggingSession.activeDuration)
  const userActivityStarted = useSelector((state: RootState) => state.currentTaggingSession.userActivityStarted)
  const userActionCount = useSelector((state: RootState) => state.currentTaggingSession.userActionCount)
  const user = useContext(userContext)

  const getLabelingMetrics = (): ScribeExecutionProposalLabelingMetrics => {
    if (userActivityStarted === null) {
      return {
        active_time_us: activeDuration * 1000,
        action_count: userActionCount,
      }
    }

    return {
      active_time_us: (activeDuration + (dayjs().valueOf() - userActivityStarted)) * 1000,
      action_count: userActionCount,
    }
  }
  const onUpdateProposalClick = (event: SyntheticEvent) => {
    setIsInFlight(true)

    const exec = buildExecution(ispans)
    if (!currentProposalID) {
      console.error("Can't update proposal without a proposal ID")
      return
    }

    generateStormBlueprint(exec)
      .then(bp => {
        FirestoreService.updateProposal(scribeID, currentProposalID, exec, bp, splits, getLabelingMetrics()).then(() =>
          dispatch(unsetDirtyBit()),
        )
      })
      .finally(() => setIsInFlight(false))
  }

  const onSaveNewProposalClick = (event: SyntheticEvent) => {
    setIsInFlight(true)

    const exec = buildExecution(ispans)
    if (!user?.uid) {
      console.error("Can't find the user uid")
      return
    }

    generateStormBlueprint(exec)
      .then(bp => {
        FirestoreService.createProposal(scribeID, exec, bp, splits, user?.uid, getLabelingMetrics()).then(doc => {
          dispatch(setCurrentProposalID(doc.id))
          dispatch(unsetDirtyBit())
        })
      })
      .finally(() => setIsInFlight(false))
  }

  const onMarkAsComplete = (event: SyntheticEvent) => {
    setIsInFlight(true)

    if (!currentProposalID) {
      console.error("Can't mark proposal as complete without a proposal ID")
      return
    }

    FirestoreService.setProposalStatus(scribeID, currentProposalID, ScribeExecutionProposalStatus.Complete).then(() => {
      setIsInFlight(false)
    })
  }

  const onPromoteClick = async (event: SyntheticEvent) => {
    if (!currentProposalID) {
      return
    }
    setIsInFlight(true)

    promoteProposal(currentProposalID, scribeID)
      .then(() => FirestoreService.setTrainingProposal(scribeID, currentProposalID))
      .catch(err => console.log("Fetch Error :-S", err))
      .finally(() => setIsInFlight(false))
  }

  const onSetTrainingClick = async (event: SyntheticEvent) => {
    if (!currentProposalID) {
      return
    }
    setIsInFlight(true)
    FirestoreService.setTrainingProposal(scribeID, currentProposalID)
      .catch(err => console.log("Failed to set training proposal", err))
      .finally(() => setIsInFlight(false))
  }

  const isComplete = proposalMetadata?.status === ScribeExecutionProposalStatus.Complete || !proposalMetadata
  const isCurrentlyActiveProposal = scribeExecutionID === currentProposalID

  return (
    <Grid container direction="row" justifyContent="space-between" className={classes.container}>
      <Grid item>
        <Box display="flex" alignItems="center">
          {!isComplete && (
            <Button
              variant="contained"
              color="primary"
              onClick={onUpdateProposalClick}
              disabled={isInFlight || !isSessionDirty || isComplete}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          )}

          {isComplete && (
            <Button
              variant="contained"
              color="primary"
              onClick={onSaveNewProposalClick}
              disabled={isInFlight || !isSessionDirty}
              startIcon={<CallSplitIcon />}
            >
              Save as new proposal
            </Button>
          )}
          {isInFlight && <CircularProgress size={24} className={classes.ml1} />}
        </Box>
      </Grid>
      {currentProposalID && (
        <>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onMarkAsComplete}
                  disabled={isInFlight || isSessionDirty || isComplete}
                  startIcon={<DoneIcon />}
                >
                  Mark as complete
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onPromoteClick}
                  disabled={isInFlight || isSessionDirty || !isComplete || isCurrentlyActiveProposal}
                  startIcon={<PublishIcon />}
                >
                  Promote proposal
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={onSetTrainingClick} disabled={isInFlight}>
              Set as Training Proposal
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default SaveScribeButton
