import firebase from "firebase/compat/app"
import { marshalExecution, Scribe, scribeFirestoreConverter, WorkoutExecution } from "./types/scribe"
import { EquipmentDefinitions, ExerciseDefinitions } from "./types"
import { User, userFirestoreConverter } from "./types/user"
import {
  ScribeExecutionProposal,
  ScribeExecutionProposalStatus,
  ScribeExecutionProposalLabelingMetrics,
  scribeExecutionProposalFirestoreConverter,
} from "./types/scribe-execution-proposal"
import { BlueprintNew } from "./types/blueprint"
import { commentFirestoreConverter, Comment } from "./types/comments"
import { ScribeTag } from "../../../utils/tags"
import { taggingIndexDocFirestoreConverter, taggingIndexScribeDocFirestoreConverter } from "./types/tagging-index"

class Firestore {
  private _db: firebase.firestore.Firestore

  constructor() {
    this._db = firebase.firestore()
  }

  db() {
    return this._db
  }

  deleteProposal(scribeID: string, proposalID: string): Promise<void> {
    return this._db.doc(`scribes/${scribeID}/execution_proposals/${proposalID}`).delete()
  }

  setProposalStatus(scribeID: string, proposalID: string, status: ScribeExecutionProposalStatus): Promise<void> {
    return this._db.doc(`scribes/${scribeID}/execution_proposals/${proposalID}`).update({
      "metadata.status": status,
    })
  }

  updateProposal(
    scribeID: string,
    proposalID: string,
    execution: WorkoutExecution,
    blueprint: BlueprintNew,
    splits: number[],
    labelingMetrics: ScribeExecutionProposalLabelingMetrics,
    model?: string,
  ): Promise<void> {
    const update = {
      section_splits: splits,
      execution: marshalExecution(execution),
      blueprint: blueprint,
      "metadata.updated_at": firebase.firestore.Timestamp.now(),
      "metadata.labeling_metrics": labelingMetrics,
      ...(model && { "metadata.model": model, "metadata.automatic": true }),
    }

    return this._db.doc(`scribes/${scribeID}/execution_proposals/${proposalID}`).update(update)
  }

  getProposal(scribeID: string, proposalID: string): Promise<ScribeExecutionProposal | undefined> {
    return this._db
      .collection("scribes")
      .doc(scribeID)
      .collection("execution_proposals")
      .doc(proposalID)
      .withConverter(scribeExecutionProposalFirestoreConverter)
      .get()
      .then(snap => snap.data())
  }

  getScribe(scribeID: string): Promise<Scribe | undefined> {
    return this._db
      .doc(`scribes/${scribeID}`)
      .withConverter(scribeFirestoreConverter)
      .get()
      .then(snap => snap.data())
  }

  updateProposalBlueprint(scribeID: string, proposalID: string, blueprint: BlueprintNew): Promise<void> {
    return this._db.doc(`scribes/${scribeID}/execution_proposals/${proposalID}`).update({
      blueprint: blueprint,
      manual_blueprint: true,
    })
  }

  createProposalWithId(
    scribeID: string,
    proposalID: string | undefined,
    execution: WorkoutExecution,
    blueprint: BlueprintNew,
    splits: number[],
    user: string,
    labelingMetrics: ScribeExecutionProposalLabelingMetrics,
    model?: string,
  ) {
    const now = firebase.firestore.Timestamp.now()
    const prop: ScribeExecutionProposal = {
      execution: execution,
      blueprint: blueprint,
      section_splits: splits,
      metadata: {
        status: ScribeExecutionProposalStatus.Draft,

        created_at: now,
        updated_at: now,

        automatic: !!model,
        user_id: user,

        labeling_metrics: labelingMetrics,
        ...(model && { model: model }),
      },
    }
    if (proposalID) {
      return this._db
        .collection(`scribes/${scribeID}/execution_proposals`)
        .doc(proposalID)
        .withConverter(scribeExecutionProposalFirestoreConverter)
        .set(prop)
        .then(() => {
          return this._db.collection(`scribes/${scribeID}/execution_proposals`).doc(proposalID)
        })
    } else {
      return this._db
        .collection(`scribes/${scribeID}/execution_proposals`)
        .withConverter(scribeExecutionProposalFirestoreConverter)
        .add(prop)
    }
  }

  createProposal(
    scribeID: string,
    execution: WorkoutExecution,
    blueprint: BlueprintNew,
    splits: number[],
    user: string,
    labelingMetrics: ScribeExecutionProposalLabelingMetrics,
  ) {
    return this.createProposalWithId(scribeID, undefined, execution, blueprint, splits, user, labelingMetrics)
  }

  /**
   * Toggle a tag on a given scribe.
   * @param scribeID The ID of the scribe to update.
   * @param tag The tag to toggle.
   */
  toggleTag(scribeID: string, tag: ScribeTag): Promise<void> {
    const scribeRef = this._db.doc(`scribes/${scribeID}`)
    const tagsFieldPath = new firebase.firestore.FieldPath("tags")

    return this._db.runTransaction(async transaction => {
      const scribe = await transaction.get(scribeRef)
      const tags = (scribe.get(tagsFieldPath) as string[]) || []

      if (tags.includes(tag.valueOf())) {
        console.log(`Removing tag: ${tag}`)
        const newTags = tags.filter(v => v !== tag.valueOf())
        transaction.update(scribeRef, tagsFieldPath, newTags)
      } else {
        console.log(`Adding tag: ${tag}`)
        const newTags = [...tags, tag.valueOf()]
        transaction.update(scribeRef, tagsFieldPath, newTags)
      }
    })
  }

  /**
   * Sets a tag on a given scribe.
   * @param scribeID The ID of the scribe to update.
   * @param tag The tag to set.
   */
  setTag(scribeID: string, tag: ScribeTag): Promise<void> {
    const scribeRef = this._db.doc(`scribes/${scribeID}`)
    const tagsFieldPath = new firebase.firestore.FieldPath("tags")

    return this._db.runTransaction(async transaction => {
      const scribe = await transaction.get(scribeRef)
      const tags = (scribe.get(tagsFieldPath) as string[]) || []
      if (tags.includes(tag.valueOf())) {
        return
      }

      const newTags = [...tags, tag.valueOf()]
      transaction.update(scribeRef, tagsFieldPath, newTags)
    })
  }

  /**
   * Removes a tag from a given scribe.
   * @param scribeID The ID of the scribe to update.
   * @param tag The tag to remove.
   */
  unsetTag(scribeID: string, tag: ScribeTag): Promise<void> {
    const scribeRef = this._db.doc(`scribes/${scribeID}`)
    const tagsFieldPath = new firebase.firestore.FieldPath("tags")

    return this._db.runTransaction(async transaction => {
      const scribe = await transaction.get(scribeRef)
      const tags = (scribe.get(tagsFieldPath) as string[]) || []
      if (!tags.includes(tag.valueOf())) {
        return
      }

      const newTags = tags.filter(v => v !== tag.valueOf())
      transaction.update(scribeRef, tagsFieldPath, newTags)
    })
  }

  /**
   * Sets the is_description_requested field to true on the give scribe.
   * @param scribeID The ID of the scribe to update.
   */
  requestDescription(scribeID: string): Promise<void> {
    return this._db.doc(`scribes/${scribeID}`).update("is_description_requested", true)
  }

  /**
   * Updates the user_description field with the given text.
   * @param scribeID The ID of the scribe to update.
   * @param newTitle The new workout title to write to Firestore.
   * @param newDescription The new workout description to write to Firestore.
   */
  setUserDescription(scribeID: string, newTitle: string, newDescription: string): Promise<void> {
    let updates: { [fieldPath: string]: any } = {}

    if (newTitle !== "") {
      updates["user_title"] = newTitle
    }

    updates["user_description"] = newDescription
    return this._db.doc(`scribes/${scribeID}`).update(updates)
  }

  setTags(scribeID: string, newTags: string[]): Promise<void> {
    return this._db.doc(`scribes/${scribeID}`).update("tags", newTags)
  }

  setIsTest(scribeID: string, isTest: boolean): Promise<void> {
    return this._db.doc(`scribes/${scribeID}`).update("is_test", isTest)
  }

  setTrainingProposal(scribeID: string, proposalID: string | null): Promise<void> {
    return this._db
      .doc(`scribes/${scribeID}`)
      .update("training_proposal_id", proposalID ? proposalID : firebase.firestore.FieldValue.delete())
  }

  /**
   * Listens to updates for a specific scribe. The returned function must be called to
   * close the subscription when updates are no longer required.
   * @param scribeID The ID of the scribe to subscribe to.
   * @param onUpdate The function to run for updates to the scribe.
   */
  attachScribeListener(scribeID: string, onUpdate: (snapshot: any) => void): () => void {
    return this._db.collection("scribes").withConverter(scribeFirestoreConverter).doc(scribeID).onSnapshot(onUpdate)
  }

  fetchExerciseIndex() {
    return this._db
      .collection("tagging_index")
      .withConverter(taggingIndexDocFirestoreConverter)
      .get()
      .then(snap => {
        return snap.docs.map(doc => {
          return doc.data()
        })
      })
  }

  fetchExercises(type: string, cls: string) {
    return this._db
      .collection("tagging_index")
      .doc(type)
      .collection(cls)
      .withConverter(taggingIndexScribeDocFirestoreConverter)
      .get()
      .then(snap => snap.docs.map(d => d.data()))
  }

  fetchUsers(): Promise<User[]> {
    return this._db
      .collection("users")
      .withConverter(userFirestoreConverter)
      .get()
      .then(snap => snap.docs.map(d => d.data()))
  }

  /**
   * Fetches exercise and equipment defintions from Firestore.
   */
  fetchDefinitions(): [Promise<ExerciseDefinitions>, Promise<EquipmentDefinitions>] {
    const exercises = this._db
      .collection("definitions")
      .doc("exercises")
      .get()
      .then(
        (snapshot): ExerciseDefinitions =>
          (snapshot.get("values") || []).reduce((acc: ExerciseDefinitions, d: any) => {
            acc[d["id"]] = {
              id: d["id"],
              parent: d["parent"],
              name: d["name"],
              short_name: d["short_name"],
              super_short_name: d["super_short_name"],
              measured_as: d["measured_as"],
              equipment: d["equipment"],
              tags: d["tags"],
            }
            return acc
          }, {}),
      )

    const equipment = this._db
      .collection("definitions")
      .doc("equipment")
      .get()
      .then(
        (snapshot): EquipmentDefinitions =>
          (snapshot.get("values") || []).reduce((acc: EquipmentDefinitions, d: any) => {
            acc[d["id"]] = {
              id: d["id"],
              measure: d["parent"],
              name: d["name"],
              short_name: d["short_name"],
            }
            return acc
          }, {}),
      )

    return [exercises, equipment]
  }

  addScribeComment(scribeID: string, comment: Comment): Promise<string> {
    if (!comment.timestamp) {
      comment.timestamp = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp
    }
    return this._db
      .collection(`scribes/${scribeID}/comments`)
      .withConverter(commentFirestoreConverter)
      .add(comment)
      .then(docRef => docRef.id)
  }

  deleteScribeComment(scribeID: string, commentID: string): Promise<void> {
    return this._db.collection(`scribes/${scribeID}/comments`).doc(commentID).delete()
  }

  addUserComment(userID: string, comment: Comment): Promise<string> {
    if (!comment.timestamp) {
      comment.timestamp = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp
    }
    return this._db
      .collection(`users/${userID}/comments`)
      .withConverter(commentFirestoreConverter)
      .add(comment)
      .then(docRef => docRef.id)
  }

  deleteUserComment(userID: string, commentID: string): Promise<void> {
    return this._db.collection(`users/${userID}/comments`).doc(commentID).delete()
  }
}

export default Firestore
