import { ScribeColumn } from "../ScribeList"
import { TableCell, Tooltip } from "@mui/material"
import DoneIcon from "@mui/icons-material/Done"
import NotInterestedIcon from "@mui/icons-material/NotInterested"
import FileCopyIcon from "@mui/icons-material/FileCopy"
import FeedbackIndicator from "./FeedbackIndicator"
import DescriptionIndicator from "./DescriptionIndicator"
import { Scribe as ScribeT } from "../../../services/firebase/firestore/types"

export const MetaColumn: ScribeColumn = {
  header: <TableCell>Meta</TableCell>,
  cell(scribe: ScribeT) {
    let user_description = scribe.user_description
    if (scribe.user_edited_blueprint) {
      user_description = scribe.proposal_description || user_description
    }

    const isClone = !!scribe.clone_of

    return (
      <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
        {scribe.execution && (
          <Tooltip arrow title="Has execution">
            <DoneIcon />
          </Tooltip>
        )}
        <DescriptionIndicator
          user_description={user_description}
          user_description_image_ref={scribe.user_description_image_ref}
        />
        {scribe.is_test && (
          <Tooltip arrow title="Is Test">
            <NotInterestedIcon />
          </Tooltip>
        )}
        {isClone && (
          <Tooltip arrow title="Is Clone">
            <FileCopyIcon />
          </Tooltip>
        )}
        <FeedbackIndicator feedback={scribe.feedback} />
      </TableCell>
    )
  },
}
