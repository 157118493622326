import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  selectEnabledPredictionAxes,
  selectGraphClicked,
  selectvisibleDataSources,
} from "../../store/session/selectors"
import { PredictionVisualization } from "../../services/firebase/storage/types/prediction-visualization"
import Box from "@mui/material/Box"
import { defaultColors } from "../../pixi/lines"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from "@mui/material/Typography"
import { setEnabledGraphAxesForType } from "../../store/session/reducer"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import { predictionsAtTime } from "../../utils/predictionVisualization"
import _ from "lodash"
import { SensorType } from "../../utils/sensorType"

interface OwnProps {
  predictionVisualization?: PredictionVisualization
}

function PredictionLegend({ predictionVisualization }: OwnProps) {
  const highlight = useSelector(selectGraphClicked)
  const dispatch = useDispatch()

  const types = useMemo(
    () => (predictionVisualization ? Object.keys(predictionVisualization.channels) : []),
    [predictionVisualization],
  )

  const enabledAxes = useSelector(selectEnabledPredictionAxes)
  const showPrediction = useSelector(selectvisibleDataSources).includes(SensorType.Prediction)

  const predictionAtHighlight = () => {
    if (!highlight || !predictionVisualization) {
      return {}
    }
    const time = highlight * 1_000_000
    return predictionsAtTime(predictionVisualization, time)
  }

  const toggleAll = useCallback(() => {
    const newTypes = types.length / 2 < enabledAxes.length ? [] : types
    const axes = newTypes.map((name, idx) => idx)
    dispatch(setEnabledGraphAxesForType({ sensorType: SensorType.Prediction, axes: axes }))
  }, [dispatch, types, enabledAxes])

  const highlightMap = predictionAtHighlight()

  if (!showPrediction || !predictionVisualization) {
    return null
  }
  const sorted = new Array(...types)

  sorted.sort((a, b) => {
    const va = highlightMap[a]
    const vb = highlightMap[b]
    return vb - va
  })

  return (
    <Box display="flex" flexWrap="wrap">
      <Button onClick={toggleAll}>Toggle All</Button>
      <Grid container spacing={1}>
        {sorted.map(k => {
          const idx = types.indexOf(k)
          return (
            <Grid item xs={3} key={k}>
              <Item name={k} index={idx} color={defaultColors(idx)} value={highlightMap[k]} />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

interface ItemProps {
  name: string
  index: number
  color: string
  value: number
}

function Item({ name, index, color, value }: ItemProps) {
  const dispatch = useDispatch()
  const enabledAxes = useSelector(selectEnabledPredictionAxes)

  const toggle = useCallback(() => {
    const newAxes = _.xor(enabledAxes, [index])
    dispatch(setEnabledGraphAxesForType({ sensorType: SensorType.Prediction, axes: newAxes }))
  }, [index, enabledAxes, dispatch])

  const checked = enabledAxes.includes(index)

  return (
    <Box p={0}>
      <FormControlLabel
        control={
          <Checkbox
            color="default"
            checked={checked}
            style={{ color: color, padding: 0 }}
            size="small"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onChange={toggle}
          />
        }
        label={
          <Typography noWrap variant="body1" style={{ color: color }}>
            {name}: {Math.round(value * 100)}%
          </Typography>
        }
      />
    </Box>
  )
}

export default PredictionLegend
