import firebase from "firebase/compat/app"
import pako from "pako"
import { ProcessedSensordataFile } from "./types/processed-sensors"
import { PredictionVisualization } from "./types/prediction-visualization"

class Storage {
  async fetchProcessedSensorFile(scribeID: string) {
    const url: string = await firebase.storage().ref().child(`sensors/processed/${scribeID}.json.gz`).getDownloadURL()

    const resp = await fetch(url)
    const blob = await resp.blob()
    // TypeScipt does not allow Blob.arrayBuffer() because the API is only in working draft status.
    // https://github.com/microsoft/TypeScript/issues/34652
    // @ts-ignore
    const ab = await blob.arrayBuffer()
    const uintArr = new Uint8Array(ab)
    const pakoOut = pako.inflate(uintArr)
    return JSON.parse(new TextDecoder("utf-8").decode(pakoOut)) as ProcessedSensordataFile
  }

  fetchVideoURL(scribeID: string) {
    return firebase.storage().ref().child(`video/raw/${scribeID}.mov`).getDownloadURL()
  }

  fetchDescriptionImageURL(ref: string) {
    return firebase.storage().ref().child(ref).getDownloadURL()
  }
  async fetchPredictionVisualizationFile(path: string) {
    const url: string = await firebase.storage().ref().child(path).getDownloadURL()
    const resp = await fetch(url)
    const blob = await resp.blob()
    const ab = await blob.arrayBuffer()
    const uintArr = new Uint8Array(ab)
    const pakoOut = pako.inflate(uintArr)
    return JSON.parse(new TextDecoder("utf-8").decode(pakoOut)) as PredictionVisualization
  }
}

export default Storage
