import { IconButton, Tooltip, Typography } from "@mui/material"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { copyPartFromOverlay } from "../../../store/session/reducer"
import { useHotkeys } from "react-hotkeys-hook"

function PartCopier() {
  const dispatch = useDispatch()

  const copy = useCallback(() => {
    dispatch(copyPartFromOverlay())
  }, [dispatch])

  useHotkeys("z", copy, { description: "Copy from overlay to proposal" }, [copy])

  return (
    <>
      <Typography component="span">Copy:</Typography>
      <Tooltip title="Copy from overlay to proposal" arrow>
        <IconButton size="small" onClick={copy}>
          <ArrowDownwardIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default PartCopier
