import { ScribeColumn } from "../ScribeList"
import { TableCell } from "@mui/material"
import ScribeDuration from "../../scribes/ScribeDuration"

export const DurationColumn: ScribeColumn = {
  header: <TableCell align="right">Dur</TableCell>,
  cell(s) {
    return (
      <TableCell align="right">
        <ScribeDuration scribe={s} />
      </TableCell>
    )
  },
}
