import { combineReducers, configureStore, Middleware } from "@reduxjs/toolkit"
import { default as taggingSessionReducer } from "./session/reducer"

import definitionsReducer from "./definitions/reducer"
import usersReducer from "./users-reducer"
import exerciseDialogReducer from "./exercise-dialog/reducer"
import { verifySessionSlice } from "./verify/reducer"
import { userSettingsSlice } from "./user-settings/reducer"
import { listenerMiddleware, startAppListening } from "./listener"

const timing: Middleware<{}, RootState> = store => next => action => {
  performance.mark(`${action.type}_start`)
  let result = next(action)
  performance.mark(`${action.type}_end`)
  performance.measure(`${action.type}`, `${action.type}_start`, `${action.type}_end`)
  return result
}

const rootReducer = combineReducers({
  currentTaggingSession: taggingSessionReducer,
  verifySession: verifySessionSlice.reducer,
  userSettings: userSettingsSlice.reducer,
  definitions: definitionsReducer,
  users: usersReducer,
  exerciseDialog: exerciseDialogReducer,
})

export type RootState = ReturnType<typeof rootReducer>

let extraMiddleware = [timing]
if (process.env.NODE_ENV === "production") {
  extraMiddleware = []
}

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: getDefault =>
    getDefault({
      immutableCheck: false,
      serializableCheck: {
        ignoredPaths: ["users"],
        ignoredActions: ["users/setUsers"],
      },
    })
      .prepend(listenerMiddleware.middleware)
      .concat(extraMiddleware),
})

export type AppDispatch = typeof store.dispatch
export default store
