import { Autocomplete, TextField } from "@mui/material"
import { useSelector } from "react-redux"
import { selectExerciseDefs } from "../../store/definitions/selectors"

interface OwnProps {
  value: string | null
  onChange: (newExercise: string | null) => void
}

function ScribeExerciseInput({ value, onChange }: OwnProps) {
  const exerciseDefs = useSelector(selectExerciseDefs)

  const handleOnChange = (e: any, newValue: string | null) => {
    if (newValue) {
      newValue = newValue.toUpperCase()
    }
    onChange(newValue)
  }

  return (
    <Autocomplete
      freeSolo
      value={value}
      renderInput={params => <TextField {...params} label="Exercise Type" />}
      onChange={handleOnChange}
      options={Object.keys(exerciseDefs)}
    />
  )
}

export default ScribeExerciseInput
