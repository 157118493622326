import { Autocomplete, Box, Chip, TextField } from "@mui/material"
import { useCallback, useMemo } from "react"
import { KnownTags } from "../../utils/tags"

interface OwnProps {
  value: string | null
  onChange: (newTag: string | null) => void
}

function ScribeTagInput({ value, onChange }: OwnProps) {
  const options = useMemo(() => {
    let all = new Set(Object.keys(KnownTags))
    if (value) {
      all.add(value)
    }
    return Array.from(all)
  }, [value])

  const handleOnChange = useCallback(
    (e: any, newValue: string | null) => {
      onChange(newValue)
    },
    [onChange],
  )

  return (
    <Autocomplete
      freeSolo
      renderInput={params => {
        return <TextField {...params} label="Tag" />
      }}
      value={value}
      onChange={handleOnChange}
      options={options}
      getOptionLabel={option => KnownTags[option]?.title || option}
      renderOption={(props, option, state) => {
        const known = KnownTags[option]
        const label = known?.title || option
        const color = known?.color
        return (
          <li {...props}>
            <Chip label={label} size="small" style={{ backgroundColor: color }} />
          </li>
        )
      }}
    />
  )
}

export default ScribeTagInput
