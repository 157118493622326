import { Autocomplete, Box, TextField } from "@mui/material"
import ScribeList from "../../../components/scribelist/ScribeList"
import { useCallback } from "react"
import firebase from "firebase/compat/app"
import { Scribe } from "../../../services/firebase/firestore/types"
import { scribeFirestoreConverter } from "../../../services/firebase/firestore/types/scribe"
import { FirestoreService } from "../../../services/firebase"
import { useSelector } from "react-redux"
import { RootState } from "../../../store"
import { useSearchParams } from "react-router-dom"
import { DefaultColumns } from "./index"

interface UserInputProps {
  value: string | null
  onChange: (newUser: string | null) => void
}

function UserInput({ value, onChange }: UserInputProps) {
  const users = useSelector((state: RootState) => {
    return state.users.users
  })

  const getLabel = (userID: string) => {
    if (!(userID in users)) {
      return userID
    }
    const user = users[userID]
    return user.name ? `${user.name} (${userID})` : userID
  }

  const handleOnChange = (e: any, newValue: string | null) => {
    onChange(newValue)
  }

  return (
    <Autocomplete
      value={value}
      onChange={handleOnChange}
      renderInput={params => <TextField {...params} label="User" />}
      getOptionLabel={getLabel}
      options={Object.keys(users)}
    />
  )
}

function ScribesByUser() {
  const [searchParams, setSearchParams] = useSearchParams()
  const userID = searchParams.get("userid")

  const makeQuery = useCallback(() => {
    let q: firebase.firestore.Query<Scribe> = FirestoreService.db()
      .collection("scribes")
      .withConverter(scribeFirestoreConverter)
    if (userID) {
      q = q.where("user_id", "==", userID)
    }
    q = q.orderBy("started_at", "desc")
    return q
  }, [userID])

  const changeUserID = (newUser: string | null) => {
    setSearchParams(newUser ? { userid: newUser } : {})
  }

  return (
    <Box>
      <UserInput value={userID} onChange={changeUserID} />
      <ScribeList query={makeQuery()} columns={DefaultColumns} />
    </Box>
  )
}

export default ScribesByUser
