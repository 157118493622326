import firebase from "firebase/compat/app"
import { BlueprintNew } from "../firebase/firestore/types/blueprint"
import { marshalExecution, WorkoutExecution } from "../firebase/firestore/types/scribe"

async function sendRequest(url: string, req: any): Promise<Response> {
  const token = await firebase.auth().currentUser?.getIdToken()
  if (!token) {
    throw new Error("Failed to fetch Firebase token")
  }
  if (!req.headers) {
    req.headers = {}
  }
  req.headers["Authorization"] = `Bearer ${token}`

  const response = await fetch(url, req)
  if (!response.ok) {
    const text = await response.text()
    throw new Error(`An error occured: ${response.status}: ~${text}`)
  }
  return response
}

export async function reprocessScribe(scribe: string) {
  const url = "/api/v1/scribe/reprocess"
  const request = {
    scribeId: scribe,
  }
  const body = JSON.stringify(request)
  const response = await sendRequest(url, {
    method: "POST",
    body: body,
  })
  await response.json()
}

export async function matchScribe(scribe: string) {
  const url = "/api/v1/scribe/match-execution-structure"
  const request = {
    scribeId: scribe,
  }
  const body = JSON.stringify(request)
  const response = await sendRequest(url, {
    method: "POST",
    body: body,
  })
  await response.json()
}

export async function indexScribe(scribe: string) {
  const url = "/api/v1/scribe/index"
  const request = {
    scribeId: scribe,
  }
  const body = JSON.stringify(request)
  const response = await sendRequest(url, {
    method: "POST",
    body: body,
  })
  await response.json()
}

export async function triggerPrediction(model: string, scribe: string, version: number) {
  const url = "/api/v1/scribe/predict"
  const request = {
    scribeId: scribe,
    model: model,
    version: version,
  }

  const body = JSON.stringify(request)
  const response = await sendRequest(url, {
    method: "POST",
    body: body,
  })
  const resp = await response.json()
  return resp["jobId"]
}

export async function promoteProposal(proposal: string, scribe: string) {
  const url = `/promote?scribe_id=${scribe}&proposal_id=${proposal}`
  await sendRequest(url, {
    method: "POST",
  })
}

export async function generateStormBlueprint(exec: WorkoutExecution): Promise<BlueprintNew> {
  const url = "/stormapi/v1/scribe/blueprint"
  const response = await sendRequest(url, {
    method: "POST",
    body: JSON.stringify({ execution: marshalExecution(exec) }),
    headers: {
      "Content-Type": "application/json",
    },
  })
  const respJson = await response.json()
  if ("blueprint" in respJson) {
    return respJson["blueprint"]
  }

  throw new Error("[Storm] Failed to create blueprint from execution")
}

export async function requestRepCount(
  id: string,
  sensordata: {
    [p: string]: number[][]
  },
  fallback: boolean,
): Promise<number[]> {
  const req = createRepCountRequest(id, sensordata, fallback)
  const url = "/stormapi/v1/scribe/repcount"
  const response = await sendRequest(url, {
    method: "POST",
    body: JSON.stringify(req),
    headers: {
      "Content-Type": "application/json",
    },
  })
  const resp = await response.json()
  return resp["repsUs"]
}

interface RepCountRequest {
  exerciseType: string
  sensors: { [sensorType: number]: { data: { x: number; y: number; z: number }[] } }
  fallback?: boolean
}

function convertRowToColumn(data: number[][]): number[][] {
  let [row] = data
  return row.map((_, column) => data.map(row => row[column]))
}

export function createRepCountRequest(
  id: string,
  sensordata: {
    [p: number]: number[][]
  },
  fallback: boolean,
): RepCountRequest {
  // sanity check
  Object.values(sensordata).forEach(data => {
    let len = data[0].length
    data.forEach(v => {
      if (v.length !== len) {
        throw new Error("slices must be equal length")
      }
    })
  })

  let req: RepCountRequest = {
    exerciseType: id,
    sensors: {},
    fallback: fallback,
  }
  Object.entries(sensordata).forEach(([key, data]) => {
    req.sensors[Number(key)] = {
      data: convertRowToColumn(data).map(v => {
        return { x: v[0], y: v[1], z: v[2] }
      }),
    }
  })

  return req
}
