import { createAsyncThunk, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit"
import { PartData, PartInfo, partKey } from "../types/verify"
import { RootState } from "../index"
import { loadPart, VERIFY_PADDING } from "../../pages/verify/data"
import { ReduxStoreExercises, ReduxStoreMarks } from "../session/reducer"
import { BeginEnd } from "../../pixi/utils"
import _ from "lodash"
import { WorkoutExecutionPartValidation } from "../../services/firebase/firestore/types/scribe"
import { v5 as uuidv5 } from "uuid"
import { Exercise } from "../types"
import { State } from "pixi.js"

export interface VerifySpan {
  ref: string // references the exercise ID
  begin: number
  end: number
  ignored: boolean
  verified_begin: boolean
  verified_end: boolean
  verified_exercise: boolean
}

export type VerifyReduxStoreSpans = { [uuid: string]: VerifySpan }

export interface InflatedPartData extends PartData {
  spans: VerifyReduxStoreSpans
  marks: ReduxStoreMarks // TODO: change
  validation: WorkoutExecutionPartValidation
  ignored: boolean
  dirty: boolean
}

export function verifyPartCanSave(p: InflatedPartData): boolean {
  return p.validation.span || p.ignored
}

interface ExerciseKeyPair {
  key: string
  exercise: Exercise
}

interface VerifySessionState {
  parts: InflatedPartData[]
  dirty: InflatedPartData[]
  ephemeralExercises: ReduxStoreExercises
  inUseExercises: ReduxStoreExercises
  mainExercise?: ExerciseKeyPair
  inflight: string[]
  cursorPartIdx: number
  cursorOffset: number // time under the cursor from start the current sensor segment
  cursorPartOffset: number // time under the cursor in the scribe owning the current part
}

const initialState: VerifySessionState = {
  parts: [],
  dirty: [],
  ephemeralExercises: {},
  inUseExercises: {},
  inflight: [],
  cursorPartIdx: -1,
  cursorOffset: 0,
  cursorPartOffset: 0,
}

// === Action payloads ===

interface ChangeSpanPayload {
  info: PartInfo
  change: BeginEnd
}

interface SetSpanTypePayload {
  ref: string
}

interface ChangeMarkPayload {
  info: PartInfo
  id: string
  time: number
}

interface SetCursorPayload {
  info: PartInfo
  offset: number
}

interface SetEphemeralExercisesPayload {
  mainExercise: ExerciseKeyPair
  exercises: Record<string, Exercise>
}

interface ToggleValidationPayload {
  info: PartInfo
  field: "span" | "exercise" | "rep_count" | "rep_times"
}

interface SetInUseExercisePayload {
  id: string
  exercise: Exercise
}

export const fetchPart = createAsyncThunk(
  "verifySession/fetchPart",
  async (info: PartInfo) => {
    return await loadPart(info.scribeID, info.proposalID, info.partIdx)
  },
  {
    condition: (arg, api) => {
      const state = api.getState() as RootState
      const key = partKey(arg)
      const info = state.verifySession.inflight.indexOf(key)
      return info === -1
    },
  },
)

function findPart(state: VerifySessionState, info: PartInfo): number {
  //console.time("find part")
  const p = state.parts.findIndex(v => _.isEqual(v.info, info))
  //console.timeEnd("find part")
  return p
}

const UUID_NAMESPACE = "915210b6-90e8-45d7-96d5-2c47e9191bbc"

const update_dirty = (state: Draft<VerifySessionState>) => {
  state.dirty = state.parts.filter(v => v.dirty)
}

export const verifySessionSlice = createSlice({
  name: "verifySession",
  initialState,
  reducers: {
    reset: state => {
      state.parts = []
      state.cursorPartIdx = -1
    },
    addRep: (state, action: PayloadAction<string>) => {
      if (state.cursorPartIdx < 0) {
        return
      }
      const partIdx = state.cursorPartIdx
      state.parts[partIdx].marks[action.payload] = {
        type: "rep",
        time: state.cursorPartOffset,
      }
      state.parts[partIdx].dirty = true
      update_dirty(state)
    },
    verifyReps: state => {
      if (state.cursorPartIdx < 0) {
        return
      }
      const partIdx = state.cursorPartIdx
      const part = state.parts[partIdx]
      part.validation.rep_count = true
      part.dirty = true

      for (const m of Object.values(part.marks)) {
        m.verified = true
      }
      part.validation.rep_times = true

      state.parts[partIdx] = part
      update_dirty(state)
    },
    setRepCount: (state, action: PayloadAction<number>) => {
      if (state.cursorPartIdx < 0) {
        return
      }
      const partIdx = state.cursorPartIdx

      const repCount = action.payload
      const part = state.parts[partIdx]
      part.dirty = true

      Object.entries(part.marks).forEach(([id, mark]) => {
        delete part.marks[id]
      })

      // TODO: handle split parts
      const { begin, end } = part.spans["main"]

      const repDist = (end! - begin) / repCount

      for (let idx = 0.5; idx <= repCount; idx++) {
        part.marks[uuidv5(`rep_${idx}`, UUID_NAMESPACE)] = {
          type: "rep",
          time: begin + repDist * idx,
        }
      }

      part.validation.rep_count = true
      state.parts[partIdx] = part
      update_dirty(state)
    },
    removeRep: state => {
      if (state.cursorPartIdx < 0) {
        return
      }
      const partIdx = state.cursorPartIdx
      const part = state.parts[partIdx]
      const allReps = Object.entries(part.marks)

      const closestRep = allReps.reduce((closest, rep) => {
        const currentRepDist = Math.abs(rep[1].time - state.cursorPartOffset)
        const closestRepDist = Math.abs(closest[1].time - state.cursorPartOffset)
        return currentRepDist < closestRepDist ? rep : closest
      })

      const closestDist = Math.abs(closestRep[1].time - state.cursorPartOffset)
      if (closestDist < 1.2) {
        console.log("delete", closestRep)
        delete part.marks[closestRep[0]]
        state.parts[partIdx] = part
        state.parts[partIdx].dirty = true
      }
      update_dirty(state)
    },
    setCursor: (state, action: PayloadAction<SetCursorPayload>) => {
      const idx = findPart(state, action.payload.info)
      if (idx === -1) {
        console.warn("not found")
        return
      }

      state.cursorPartIdx = idx

      //console.log("set cursor", state.cursorPartIdx, action.payload.offset)

      const part = state.parts[state.cursorPartIdx]

      state.cursorOffset = action.payload.offset
      state.cursorPartOffset = action.payload.offset + part.offset / 1_000_000
    },
    jumpToPart: (state, action: PayloadAction<PartInfo>) => {
      /*
      to jump to the middle use this

      const partIdx = findPart(state, action.payload)
      const part = state.parts[partIdx]
      const offset = part.length / DATA_FREQUENCY / 2
       */

      verifySessionSlice.caseReducers.setCursor(state, {
        type: "setCursor",
        payload: {
          info: action.payload,
          offset: VERIFY_PADDING + 0.1,
        },
      })
    },
    clearDirty: (state, action: PayloadAction<InflatedPartData>) => {
      const partIdx = findPart(state, action.payload.info)
      const part = state.parts[partIdx]
      part.dirty = false
      state.parts[partIdx] = part
      update_dirty(state)
    },
    setSpanType: (state, action: PayloadAction<SetSpanTypePayload>) => {
      if (state.cursorPartIdx < 0) {
        return
      }
      const partIdx = state.cursorPartIdx
      console.log("set span type", action.payload)
      const part = state.parts[partIdx]

      part.spans["main"].ref = action.payload.ref
      part.spans["main"].verified_exercise = true

      part.validation.exercise = true

      part.dirty = true
      state.parts[partIdx] = part
      update_dirty(state)

      console.log("set type", part)
    },
    verifySpan: (state, action: PayloadAction<PartInfo>) => {
      const partIdx = findPart(state, action.payload)
      const part = state.parts[partIdx]
      const id = "main"

      part.dirty = true

      // TODO: do we need to keep both span.verified_begin and part.validation.begin?

      part.spans[id].verified_begin = true
      part.spans[id].verified_end = true
      part.validation.span = true

      state.parts[partIdx] = part
      update_dirty(state)
    },
    changeSpan: (state, action: PayloadAction<ChangeSpanPayload>) => {
      const partIdx = findPart(state, action.payload.info)
      const part = state.parts[partIdx]

      const id = "main"

      const change = action.payload.change

      part.dirty = true

      if (change.begin) {
        part.spans[id].begin = change.begin
        part.spans[id].verified_begin = true
      }

      if (change.end) {
        part.spans[id].verified_end = true
        part.spans[id].end = change.end
      }

      part.validation.span = part.spans[id].verified_end && part.spans[id].verified_begin

      state.parts[partIdx] = part
      update_dirty(state)
    },
    toggleIgnore: (state, action: PayloadAction<PartInfo>) => {
      const partIdx = findPart(state, action.payload)
      const part = state.parts[partIdx]
      // TODO: handle multiple spans
      part.spans["main"].ignored = !part.spans["main"].ignored
      part.ignored = !part.ignored
      part.dirty = true
      state.parts[partIdx] = part
      update_dirty(state)
    },
    changeMark: (state, action: PayloadAction<ChangeMarkPayload>) => {
      const partIdx = state.parts.findIndex(v => _.isEqual(v.info, action.payload.info))
      const part = state.parts[partIdx]

      part.dirty = true

      part.marks[action.payload.id].time = action.payload.time
      part.marks[action.payload.id].verified = true

      if (Object.values(part.marks).every(m => m.verified)) {
        part.validation.rep_times = true
      }

      state.parts[partIdx] = part
      update_dirty(state)
    },
    setInUseExercise: (state, action: PayloadAction<SetInUseExercisePayload>) => {
      state.inUseExercises[action.payload.id] = action.payload.exercise
    },
    setEphemeralExercises: (state, action: PayloadAction<SetEphemeralExercisesPayload>) => {
      state.mainExercise = action.payload.mainExercise
      state.ephemeralExercises = {}
      for (const [id, e] of Object.entries(action.payload.exercises)) {
        state.ephemeralExercises[id] = e
      }
    },
    toggleValidation: (state, action: PayloadAction<ToggleValidationPayload>) => {
      const partIdx = findPart(state, action.payload.info)
      const part = state.parts[partIdx]
      part.dirty = true

      const spanId = "main"

      switch (action.payload.field) {
        case "span":
          if (part.validation.span) {
            part.spans[spanId].verified_begin = false
            part.spans[spanId].verified_end = false
            part.validation.span = false
          } else {
            part.spans[spanId].verified_begin = true
            part.spans[spanId].verified_end = true
            part.validation.span = true
          }
          break
        case "exercise":
          if (part.validation.exercise) {
            part.spans[spanId].verified_exercise = false
            part.validation.exercise = false
          } else {
            part.spans[spanId].verified_exercise = true
            part.validation.exercise = true
          }
          break
        case "rep_count":
          part.validation.rep_count = !part.validation.rep_count
          break
        case "rep_times":
          if (part.validation.rep_times) {
            for (const m of Object.values(part.marks)) {
              m.verified = false
            }
            part.validation.rep_times = false
          } else {
            for (const m of Object.values(part.marks)) {
              m.verified = true
            }
            part.validation.rep_times = true
          }
          break
        default:
          console.error("unknown field", action.payload.field)
      }

      state.parts[partIdx] = part
      update_dirty(state)
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPart.pending, (state, action) => {
      const info = action.meta.arg
      const key = partKey(info)
      state.inflight.push(key)
    })

    builder.addCase(fetchPart.fulfilled, (state, action) => {
      const key = partKey(action.meta.arg)
      state.inflight.splice(state.inflight.indexOf(key), 1)
      const data = action.payload

      const marks: ReduxStoreMarks = {}

      if (data.part.tags) {
        for (const tag of data.part.tags) {
          if (tag.type !== "rep") {
            continue
          }
          marks[`rep_${tag.time}`] = {
            type: "rep",
            time: tag.time / 1_000_000,
          }
        }
      }

      // inflate part
      const inflatedPartData: InflatedPartData = {
        ...data,
        dirty: false,
        marks,
        ignored: false,
        validation: data.part.validation ?? {
          span: false,
          exercise: false,
          rep_count: false,
          rep_times: false,
        },
        spans: {
          main: {
            ref: key,
            begin: data.part.time_start / 1_000_000,
            end: data.part.time_end / 1_000_000, //(data.part.time_end - data.part.time_start) / 1_000_000,
            verified_begin: false,
            verified_end: false,
            verified_exercise: false,
            ignored: false,
          },
        },
      }
      state.inUseExercises[key] = inflatedPartData.part.exercise
      state.parts.push(inflatedPartData)
      if (state.parts.length === 1) {
        verifySessionSlice.caseReducers.jumpToPart(state, {
          type: "jumpToPart",
          payload: inflatedPartData.info,
        })
      }
    })
    builder.addCase(fetchPart.rejected, (state, action) => {
      const key = partKey(action.meta.arg)
      console.error("failed", action.error)
      state.inflight.splice(state.inflight.indexOf(key), 1)
    })
  },
})

export const {
  reset,
  setRepCount,
  verifyReps,
  verifySpan,
  setSpanType,
  changeSpan,
  changeMark,
  clearDirty,
  setCursor,
  jumpToPart,
  addRep,
  toggleIgnore,
  removeRep,
  setInUseExercise,
  setEphemeralExercises,
  toggleValidation,
} = verifySessionSlice.actions
