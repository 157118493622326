import { useHotkeys } from "react-hotkeys-hook"
import { Tooltip } from "@mui/material"

const EXTRA_HOTKEYS = "qwerty"

export function hotKeyForIndex(idx: number): string | undefined {
  if (idx < 9) {
    return `${idx + 1}`
  } else if (idx < 9 + EXTRA_HOTKEYS.length) {
    return EXTRA_HOTKEYS[idx - 9]
  }
}

interface HotKeyProp {
  hotKey?: string
  description: string
  action: () => void
  children: any
}

function HotKey({ hotKey, action, description, children }: HotKeyProp) {
  useHotkeys(hotKey ?? [], action, { description: description }, [action, description])

  return (
    <Tooltip title={`[${hotKey}] ${description}`}>
      <div onClick={action}>{children}</div>
    </Tooltip>
  )
}

export default HotKey
