import { memo, useCallback } from "react"
import { Theme, Typography } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import Box from "@mui/material/Box"
import { changeHoverExerciseToNextExerciseType, removeHoverExercise, setGraphCursor } from "../../store/session/reducer"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
import { formatSeconds } from "../../utils/format"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { allInflatedSpans } from "../../store/session/selectors"
import { inflatedExerciseEquals, InflatedSpan, Mark } from "../../store/types"
import { RootState } from "../../store"
import { useHotkeys } from "react-hotkeys-hook"

interface WorkoutElement {
  label_primary: string
  label_secondary: string
  sets: number
  t0: number
  t1: number
  marks: number
}

type InflatedSpanGroup = InflatedSpan[]

const split2inflated = (split: Mark): InflatedSpan => {
  const timeID = "split-" + String(split.time)
  return {
    id: timeID,
    exercise: {
      id: timeID,
      def: {
        id: timeID, // We give each split a unique id to avoid merging them
        name: "Split",
      },
    },
    ignoredBy: [],
    begin: split.time,
    end: split.time,
    marks: [],
  }
}

const inflatedAndSplits = createSelector(
  allInflatedSpans,
  (state: RootState) => Object.values(state.currentTaggingSession.marks).filter(m => m.type === "split"),
  (ispans, marks) =>
    Object.values(ispans)
      .concat(marks.map(split2inflated))
      .sort((s1, s2) => s1.begin - s2.begin),
)

const remodelExercises = createSelector(inflatedAndSplits, ispans =>
  Object.values(ispans)
    .filter(s => s.end !== undefined)
    .sort((s1, s2) => s1.begin - s2.begin)
    .reduce((res: InflatedSpanGroup[], ispan) => {
      const curEx = res.length === 0 ? undefined : res[res.length - 1][0].exercise
      if (inflatedExerciseEquals(ispan.exercise, curEx)) {
        res[res.length - 1].push(ispan)
      } else {
        res.push([ispan])
      }

      return res
    }, [])
    .map(group => {
      return {
        label_primary: group[0].exercise.def.name,
        label_secondary: "",
        sets: group.length,
        t0: group[0].begin,
        t1: group[group.length - 1].end!,
        marks: group.reduce((res, ispan) => res + Object.values(ispan.marks).length, 0),
      } as WorkoutElement
    }),
)

const useWorkoutTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      borderRadius: 4,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: theme.palette.grey[200],
      },
    },
  }),
)

function WorkoutTable() {
  const classes = useWorkoutTableStyles()
  const dispatch = useDispatch()

  const ispanGroups: WorkoutElement[] = useSelector(remodelExercises)

  const actionChange = useCallback(() => {
    dispatch(changeHoverExerciseToNextExerciseType())
  }, [dispatch])
  const actionDelete = useCallback(() => {
    dispatch(removeHoverExercise())
  }, [dispatch])

  useHotkeys("c", actionChange, { description: "change exercise" }, [actionChange])
  useHotkeys("d", actionDelete, { description: "delete exercise" }, [actionDelete])

  const jumpDelta = 0.001

  return (
    <>
      {ispanGroups.map((el, i, source) => (
        <Box
          key={i}
          className={classes.row}
          p={1}
          mt={i > 0 && el.t0 - source[i - 1].t1 > 50 ? 5 : 1}
          onClick={() => dispatch(setGraphCursor(el.t0 + jumpDelta))}
        >
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="body1">
                {el.marks > 0 && `${el.marks} `}
                {el.label_primary}
                {el.marks > 1 && "s"}
              </Typography>
            </Box>
            <Typography variant="body1" color="textSecondary">
              {el.sets === 1 ? `unbroken` : `${el.sets} sets`}
            </Typography>
          </Box>
          <Typography variant="body2" component="div" color="textSecondary" style={{ lineHeight: 1 }}>
            <Box display="flex">
              <Box display="flex" flexGrow={1} alignItems="center" alignContent="center">
                {formatSeconds(el.t0)}
                <ArrowForwardIcon fontSize="inherit" style={{ marginLeft: 8, marginRight: 8 }} />
                <Box
                  onClick={e => {
                    dispatch(setGraphCursor(el.t1 - jumpDelta))
                    e.stopPropagation()
                  }}
                >
                  {formatSeconds(el.t1)}
                </Box>
              </Box>
              <Box>{formatSeconds(el.t1 - el.t0)}</Box>
            </Box>
          </Typography>
        </Box>
      ))}
    </>
  )
}

export default memo(WorkoutTable)
