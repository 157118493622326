import { useEffect, useRef, useState } from "react"
import { FirestoreService } from "../services/firebase"
import { Scribe, ScribeExecutionProposal } from "../services/firebase/firestore/types"
import { scribeFirestoreConverter } from "../services/firebase/firestore/types/scribe"
import { scribeExecutionProposalFirestoreConverter } from "../services/firebase/firestore/types/scribe-execution-proposal"
import { Comment, commentFirestoreConverter } from "../services/firebase/firestore/types/comments"
import { ScribeAuditLog, scribeAuditLogFirestoreConverter } from "../services/firebase/firestore/types/scribe-auditlog"
import firebase from "firebase/compat"

export interface PaginationOptions {
  limit?: number
}

export function usePaginatedScribesListener(query: firebase.firestore.Query<Scribe>, options: PaginationOptions) {
  const { limit = 100 } = options
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const pageRefs = useRef<any[]>([])
  const [scribes, setScribes] = useState<Scribe[]>([])
  const queryRef = useRef<firebase.firestore.Query<Scribe> | undefined>()
  const lastQuery = useRef<firebase.firestore.Query<Scribe> | undefined>()
  const [error, setError] = useState<firebase.firestore.FirestoreError | undefined>()
  const [searchQuery, setSearchQuery] = useState<firebase.firestore.Query<Scribe> | undefined>()

  useEffect(() => {
    // Handle changes to the base query (i.e. change to filtering etc)
    // This will reset everything
    if (queryRef?.current && query.isEqual(queryRef.current)) {
      // no change
      return
    }
    console.log("invalidate")
    queryRef.current = query
    pageRefs.current = []
    lastQuery.current = undefined
    setScribes([])
    setSearchQuery(query.limit(limit))
    setPage(0)
  }, [query, setPage, limit, setScribes, setSearchQuery])

  useEffect(() => {
    // Handle changes to the page / limit setting
    // replaces the current results
    const q = queryRef.current
    if (!q) {
      return
    }
    const cursor = pageRefs.current[page - 1]

    if (cursor) {
      console.log("search with cursor")
      setSearchQuery(q.limit(limit).startAfter(cursor))
    } else {
      console.log("search without cursor")
      setSearchQuery(q.limit(limit))
    }
  }, [page, setSearchQuery, limit])

  useEffect(() => {
    // Perform the query when it changes
    if (!searchQuery) {
      return
    }
    if (lastQuery.current && searchQuery.isEqual(lastQuery.current)) {
      console.log("no change, should skip")
      // TODO: figure out why returning early here doesn't always work
      //return
    }
    lastQuery.current = searchQuery
    setLoading(true)
    return searchQuery.onSnapshot(
      val => {
        pageRefs.current[page] = val.docs[val.docs.length - 1]
        setScribes(val.docs.map(d => d.data()))
        setLoading(false)
      },
      error => {
        console.log(error)
        setLoading(false)
        setError(error)
      },
    )
  }, [searchQuery, page, setLoading, setScribes, setError])

  const rowsEstimate = page * limit + scribes.length + 1

  return {
    scribes,
    loading,
    rowsEstimate,
    page,
    setPage,
    error,
  }
}

export function useAllScribesListener() {
  const [queryResult, setQueryResult] = useState<Scribe[] | undefined>()
  useEffect(() => {
    return FirestoreService.db()
      .collection("scribes")
      .withConverter(scribeFirestoreConverter)
      .onSnapshot(val => {
        setQueryResult(val.docs.map(d => d.data()))
      })
  }, [])

  return queryResult
}

export function useQueuedScribesListener(limit = 0, lastDoc = undefined) {
  const [queryResult, setQueryResult] = useState<Scribe[] | undefined>()
  useEffect(() => {
    console.log("Making a Firestore query")
    let q = FirestoreService.db().collection("scribes").where("tags", "array-contains", "needs_operator_attention")

    if (limit > 0) {
      q = q.limit(limit)
    }

    if (lastDoc) {
      q = q.startAfter(lastDoc)
    }

    return q.withConverter(scribeFirestoreConverter).onSnapshot(val => {
      console.log("Got a Firestore query result")
      setQueryResult(val.docs.map(d => d.data()))
    })
  }, [limit, lastDoc])
  return queryResult
}

export function useScribeListener(scribeID: string) {
  const [queryResult, setQueryResult] = useState<Scribe | undefined>()
  useEffect(() => {
    return FirestoreService.db()
      .collection("scribes")
      .doc(scribeID)
      .withConverter(scribeFirestoreConverter)
      .onSnapshot(val => {
        setQueryResult(val.data())
      })
  }, [scribeID])

  return queryResult
}

export function useScribeExecutionProposalsListener(scribeID: string) {
  const [queryResult, setQueryResult] = useState<ScribeExecutionProposal[] | undefined>()
  useEffect(() => {
    return FirestoreService.db()
      .collection("scribes")
      .doc(scribeID)
      .collection("execution_proposals")
      .withConverter(scribeExecutionProposalFirestoreConverter)
      .onSnapshot(val => {
        setQueryResult(
          val.docs
            .map(d => d.data())
            .sort((a, b) => {
              const aup = a.metadata.updated_at
              const bup = b.metadata.updated_at
              if (aup === bup) {
                return 0
              }
              return a.metadata.updated_at < b.metadata.updated_at ? 1 : -1
            }),
        )
      })
  }, [scribeID])

  return queryResult
}

function compareCommentsByDate(a: Comment, b: Comment): number {
  const at = a.timestamp
  const bt = b.timestamp
  if (at === bt || !at || !bt) {
    return 0
  }
  return at > bt ? 1 : -1
}

export function useScribeCommentsListener(scribeID: string) {
  const [queryResult, setQueryResult] = useState<Comment[]>([])
  useEffect(() => {
    return FirestoreService.db()
      .collection("scribes")
      .doc(scribeID)
      .collection("comments")
      .withConverter(commentFirestoreConverter)
      .onSnapshot(val => {
        setQueryResult(val.docs.map(d => d.data()).sort(compareCommentsByDate))
      })
  }, [scribeID])

  return queryResult
}

export function useScribeAuditLogListener(scribeID: string) {
  const [queryResult, setQueryResult] = useState<ScribeAuditLog[]>([])
  useEffect(() => {
    return FirestoreService.db()
      .collection("scribes")
      .doc(scribeID)
      .collection("auditlog")
      .withConverter(scribeAuditLogFirestoreConverter)
      .onSnapshot(val => {
        setQueryResult(val.docs.map(d => d.data()).sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1)))
      })
  }, [scribeID])

  return queryResult
}

export function useUserCommentsListener(userID: string) {
  const [queryResult, setQueryResult] = useState<Comment[]>([])
  useEffect(() => {
    return FirestoreService.db()
      .collection("users")
      .doc(userID)
      .collection("comments")
      .withConverter(commentFirestoreConverter)
      .onSnapshot(val => {
        setQueryResult(val.docs.map(d => d.data()).sort(compareCommentsByDate))
      })
  }, [userID])

  return queryResult
}
