import * as PIXI from "pixi.js"

interface Marker<T> {
  /**
   * The display object that renders the marker.
   */
  readonly displayobject: PIXI.DisplayObject
  /**
   * Call when input data changes to update the marker.
   */
  update: (data: T) => void
  /**
   * Call when the graph cursor moves (not the mouse cursor).
   */
  cursorMove: (pos: number) => void
}

export interface SimpleMarkerParams<T, D extends PIXI.DisplayObject> {
  interactionmanager: PIXI.InteractionManager
  initpos: number
  create: () => D
  toPos: (t: T) => number

  updateContent?: (displayobject: D, t: T) => void

  onDragEnd?: (pos: number) => void
  onDragStart?: (pos: number) => void
  onDragMove?: (pos: number) => void
}

export class SimpleMarker<T, D extends PIXI.DisplayObject> {
  interactionmanager: PIXI.InteractionManager
  toPos: (t: T) => number
  private updateContent: ((displayobject: D, t: T) => void) | undefined

  displayobject: D
  onDragEnd?: (pos: number) => void
  onDragStart?: (pos: number) => void

  onDragMove?: (pos: number) => void
  isDragging: boolean = false
  dragMousePosStart: number = 0
  dragObjectPosStart: number = 0

  constructor(p: SimpleMarkerParams<T, D>) {
    this.interactionmanager = p.interactionmanager
    this.toPos = p.toPos
    this.updateContent = p.updateContent

    this.onDragStart = p.onDragStart
    this.onDragEnd = p.onDragEnd
    this.onDragMove = p.onDragMove

    const onDragStartLocal = this._onDragStart.bind(this)
    const onDragMoveLocal = this._onDragMove.bind(this)
    const onDragEndLocal = this._onDragEnd.bind(this)

    const container = p
      .create()
      .on("mousedown", onDragStartLocal)
      .on("mouseup", onDragEndLocal)
      .on("mouseupoutside", onDragEndLocal)
      .on("mousemove", onDragMoveLocal)

    container.interactive = true
    container.x = p.initpos

    this.displayobject = container
  }

  _onDragStart(e: PIXI.InteractionEvent) {
    this.isDragging = true
    this.dragMousePosStart = this.interactionmanager.mouse.global.x
    this.dragObjectPosStart = e.currentTarget.x
    e.stopPropagation()

    if (this.onDragStart) {
      this.onDragStart(this.displayobject.x)
    }
  }

  _onDragEnd(e: PIXI.InteractionEvent) {
    this.isDragging = false
    if (this.onDragEnd) {
      this.onDragEnd(this.displayobject.x)
    }
  }

  _onDragMove(e: PIXI.InteractionEvent) {
    if (this.isDragging) {
      const mousex: number = this.interactionmanager.mouse.global.x
      this.displayobject.x = this.dragObjectPosStart + (mousex - this.dragMousePosStart)
      e.stopPropagation()

      if (this.onDragMove) {
        this.onDragMove(this.displayobject.x)
      }
    }
  }

  update(t: T) {
    if (this.displayobject.x !== this.toPos(t)) {
      this.displayobject.x = this.toPos(t)
    }

    if (this.updateContent) {
      this.updateContent(this.displayobject, t)
    }
  }

  cursorMove(pos: number) {
    // Do nothing.
  }
}

interface StaticMarkerParams<T, D extends PIXI.DisplayObject> {
  initpos: number
  create: () => D
  toPos: (t: T) => number
  updateContent: (displayobject: D, t: T) => void
}

export class StaticMarker<T, D extends PIXI.DisplayObject> {
  displayobject: D
  toPos: (t: T) => number
  updateContent: (displayobject: D, t: T) => void

  constructor(p: StaticMarkerParams<T, D>) {
    this.toPos = p.toPos
    this.updateContent = p.updateContent
    const container = p.create()
    container.x = p.initpos
    this.displayobject = container
  }

  update(t: T) {
    if (this.displayobject.x !== this.toPos(t)) {
      this.displayobject.x = this.toPos(t)
    }
    this.updateContent(this.displayobject, t)
  }

  cursorMove(pos: number) {
    // Do nothing.
  }
}

export default Marker
