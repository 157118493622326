import {
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@mui/material"
import { useHotkeys, useHotkeysContext } from "react-hotkeys-hook"
import { useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import createStyles from "@mui/styles/createStyles"
import HelpIcon from "@mui/icons-material/Help"
import { Hotkey } from "react-hotkeys-hook/dist/types"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    help: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    helpPaper: {
      padding: 10,
    },
  }),
)

function keyName(h: Hotkey): string {
  let key = h.keys?.join("+") ?? "unknown"

  if (h.shift) {
    key = "shift+" + key
  }
  if (h.alt) {
    key = "alt+" + key
  }
  if (h.ctrl) {
    key = "ctrl+" + key
  }
  if (h.meta) {
    key = "meta+" + key
  }
  if (h.mod) {
    key = "mod+" + key
  }

  return key
}

function HelpDialog() {
  const classes = useStyles()

  const [showHelp, setShowHelp] = useState(false)

  const toggleHelp = () => {
    setShowHelp(!showHelp)
  }

  useHotkeys("shift+Slash", toggleHelp, { description: "show help dialog" }, [showHelp])

  return (
    <>
      <IconButton onClick={() => setShowHelp(true)} size="large">
        <HelpIcon />
      </IconButton>
      <Modal open={showHelp} onClose={() => setShowHelp(false)} className={classes.help}>
        <Paper className={classes.helpPaper}>
          <HelpDialogContents />
        </Paper>
      </Modal>
    </>
  )
}

function HelpDialogContents() {
  const { hotkeys } = useHotkeysContext()

  return (
    <div>
      <Typography variant="h1">Shortcuts</Typography>
      <TableContainer component={Paper} style={{ maxHeight: "440px" }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Keys</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hotkeys.map(h => (
              <TableRow key={keyName(h)}>
                <TableCell>{keyName(h)}</TableCell>
                <TableCell>{h.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default HelpDialog
