import { Box } from "@mui/material"
import { Scribe, scribeFirestoreConverter } from "../../../services/firebase/firestore/types/scribe"
import { FirestoreService } from "../../../services/firebase"
import ScribeList from "../../../components/scribelist/ScribeList"
import { useCallback, useEffect, useRef } from "react"
import ScribeTagInput from "../../../components/fields/ScribeTagInput"
import firebase from "firebase/compat/app"

import { useSearchParams } from "react-router-dom"
import { DefaultColumns } from "./index"
import ScribeExerciseInput from "../../../components/fields/ScribeExerciseInput"

function ScribesByExercise() {
  const [searchParams, setSearchParams] = useSearchParams()

  const makeQuery = useCallback(() => {
    let q: firebase.firestore.Query<Scribe> = FirestoreService.db()
      .collection("scribes")
      .withConverter(scribeFirestoreConverter)
    const exercise = searchParams.get("exercise")
    if (exercise) {
      q = q.where("exercise_types", "array-contains", exercise)
    }
    q = q.orderBy("started_at", "desc")
    if (!exercise) {
      q = q.orderBy("execution_proposal_id")
    }
    return q
  }, [searchParams])

  const changeExercise = useCallback(
    (newExercise: string | null) => {
      setSearchParams(newExercise ? { exercise: newExercise } : {})
    },
    [setSearchParams],
  )

  return (
    <Box>
      <Box sx={{ p: 1, mb: 2 }}>
        <ScribeExerciseInput value={searchParams.get("exercise")} onChange={changeExercise} />
      </Box>
      <ScribeList query={makeQuery()} columns={DefaultColumns} />
    </Box>
  )
}

export default ScribesByExercise
