import { useSelector } from "react-redux"
import { RootState } from "../store"
import emojiFlags from "emoji-flags"
import { Tooltip } from "@mui/material"

interface OwnProps {
  userID?: string
}

function UserFlag({ userID }: OwnProps) {
  let nationality = useSelector((state: RootState) => userID && state.users.users[userID]?.nationality)

  // The list used by the app and the list in emoji-flags have a few differences, this is just the ones found so far
  switch (nationality || "") {
    case "united states of america":
      nationality = "united states"
      break
    case "czechia":
      nationality = "czech republic"
      break
  }

  const country = emojiFlags.data.find(d => d.name.toLowerCase() === nationality)
  const name = country?.name || nationality || "Unknown"
  const emoji = country?.emoji || "🏳"

  return (
    <Tooltip arrow title={name}>
      <span>{emoji}</span>
    </Tooltip>
  )
}

export default UserFlag
