import firebase from "firebase/compat/app"

export const scribeAuditLogFirestoreConverter = {
  toFirestore(l: ScribeAuditLog): firebase.firestore.DocumentData {
    throw Error("Not supported")
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): ScribeAuditLog {
    const data = snapshot.data(options)
    return { ...data, id: snapshot.id } as ScribeAuditLog
  },
}

export interface ScribeAuditLog {
  id: string
  field: string
  value: string
  timestamp: firebase.firestore.Timestamp
}
