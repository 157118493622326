import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { selectExerciseDialogIsOpen } from "../../store/exercise-dialog/selectors"
import ExerciseSelectorDialog from "../scribe/ExerciseSelectorDialog"
import { setOpenState } from "../../store/exercise-dialog/reducer"
import Button from "@mui/material/Button"
import { DeleteExerciseType } from "../scribe/SidebarExercises"
import { ReactNode, useCallback } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { setInUseExercise, setSpanType } from "../../store/verify/reducer"
import HotKey, { hotKeyForIndex } from "../../components/HotKey"
import HelpDialog from "../scribe/HelpDialog"
import { selectEphemeralExercises, selectMainExercise } from "../../store/verify/selectors"
import { selectInflatedExercises } from "../../store/session/selectors"
import { inflatedEquipmentListToString, InflatedExercise, inflatedExerciseToExercise } from "../../store/types"

const useStyles = makeStyles(theme => ({
  hotkey: {
    borderColor: "#3f51b5",
    borderWidth: 2,
    borderRadius: 15,
    borderStyle: "solid",
    width: 30,
    height: 30,
    textAlign: "center",
    lineHeight: "26px",
  },
}))

interface ExerciseSelectorProps {
  exerciseID: string
  exercise: InflatedExercise
  isEphemeral: boolean
  idx: number
  children: ReactNode
}

function ChangeExercise({ exerciseID, exercise, isEphemeral, idx, children }: ExerciseSelectorProps) {
  const exerciseName = exercise.def.name
  const hotKey = hotKeyForIndex(idx)
  const dispatch = useAppDispatch()

  const onClick = useCallback(() => {
    if (isEphemeral) {
      // save it to used exercises
      dispatch(
        setInUseExercise({
          id: exerciseID,
          exercise: inflatedExerciseToExercise(exercise),
        }),
      )
    }
    dispatch(setSpanType({ ref: exerciseID }))
  }, [exerciseID, exercise, isEphemeral, dispatch])

  return (
    <HotKey hotKey={hotKey} description={`change exercise to ${exerciseName}`} action={onClick}>
      {children}
    </HotKey>
  )
}

interface ExerciseInfoProps {
  ex: InflatedExercise
  hotkey?: string
}

function ExerciseInfo({ ex, hotkey }: ExerciseInfoProps) {
  const classes = useStyles()

  return (
    <Box display="flex" alignItems="center" mt={2}>
      <Typography variant="body1" color="primary" className={classes.hotkey}>
        {hotkey}
      </Typography>
      <Box flexGrow={1} ml={1}>
        <Typography style={{ fontWeight: 500 }}>
          {ex.modifiers && `${ex.modifiers?.join(" ").toLowerCase()} `}
          {ex.def.name.toLowerCase()}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" ml={1}>
        <Typography color="textSecondary" style={{ fontWeight: 300 }}>
          {ex.equipment && inflatedEquipmentListToString(ex.equipment)}
        </Typography>
      </Box>
    </Box>
  )
}

function VerifyExerciseSelector() {
  const dispatch = useAppDispatch()

  const showExercisesDialog = useAppSelector(selectExerciseDialogIsOpen)
  const userExercises = useAppSelector(selectInflatedExercises)
  const mainExercise = useAppSelector(selectMainExercise)
  const dynamicExercises = useAppSelector(selectEphemeralExercises)
  const dynamicOffset = Object.entries(userExercises).length

  return (
    <Box>
      <Typography variant="h4">Exercises</Typography>
      <Button onClick={() => dispatch(setOpenState(true))}>Add exercise</Button>
      <Box>
        {mainExercise && (
          <Box>
            <Box mt={2}>
              <Typography variant="body1">Main Exercise</Typography>
            </Box>
            <ChangeExercise exerciseID={mainExercise.key} exercise={mainExercise.exercise} isEphemeral={false} idx={0}>
              <ExerciseInfo ex={mainExercise.exercise} hotkey={hotKeyForIndex(0)} />
            </ChangeExercise>
          </Box>
        )}
        {Object.keys(userExercises).length > 0 && (
          <Box>
            <Box mt={2}>
              <Typography variant="body1">Session</Typography>
            </Box>
            {Object.entries(userExercises).map(([id, ex], idx) => (
              <Box display="flex" key={id} alignItems="center">
                <ChangeExercise exerciseID={id} isEphemeral={false} exercise={ex} idx={idx + 1}>
                  <ExerciseInfo ex={ex} hotkey={hotKeyForIndex(idx + 1)} />
                </ChangeExercise>
                {id !== "main" && <DeleteExerciseType exercise={ex} />}
              </Box>
            ))}
          </Box>
        )}
        <Box mt={2}>
          <Typography variant="body1">Alts</Typography>
        </Box>
        {Object.entries(dynamicExercises)
          .filter(([id, ex]) => !(id in userExercises))
          .map(([id, ex], idx) => (
            <Box display="flex" key={id} alignItems="center">
              <ChangeExercise exerciseID={id} isEphemeral={true} exercise={ex} idx={idx + 1 + dynamicOffset}>
                <ExerciseInfo ex={ex} hotkey={hotKeyForIndex(idx + 1 + dynamicOffset)} />
              </ChangeExercise>
            </Box>
          ))}
      </Box>
      <ExerciseSelectorDialog isOpen={showExercisesDialog} onClose={() => dispatch(setOpenState(false))} />
    </Box>
  )
}

export default VerifyExerciseSelector
