import React, { useCallback, useState } from "react"
import { Fab, Grid, Theme, Tooltip, Typography } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import Box from "@mui/material/Box"
import { useDispatch, useSelector } from "react-redux"
import { selectExerciseRepCounts, selectInflatedExercises, selectOpenSpan } from "../../store/session/selectors"
import { inflatedEquipmentListToString, InflatedExercise } from "../../store/types"
import { v4 as uuidv4 } from "uuid"
import {
  addRep as reduxAddRep,
  endSpan,
  ignoreHoverExercise,
  insertSplit,
  removeExerciseType,
  removeRep as reduxRemoveRep,
  startSpan,
} from "../../store/session/reducer"
import DeleteIcon from "@mui/icons-material/Delete"
import AddReps from "./AddReps"
import HotKey, { hotKeyForIndex } from "../../components/HotKey"
import { useHotkeys } from "react-hotkeys-hook"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hotkey: {
      borderColor: "#3f51b5",
      borderWidth: 2,
      borderRadius: 15,
      borderStyle: "solid",
      width: 30,
      height: 30,
      textAlign: "center",
      lineHeight: "26px",
    },
    activeHotkey: {
      backgroundColor: "#3f51b5",
      color: "#e0e0e0",
      borderColor: "#3f51b5",
      borderWidth: 2,
      borderRadius: 15,
      borderStyle: "solid",
      width: 30,
      height: 30,
      textAlign: "center",
      lineHeight: "26px",
    },
    row: {
      borderRadius: 4,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: theme.palette.grey[200],
      },
    },
  }),
)

interface DeleteExerciseTypeProps {
  exercise: InflatedExercise
}

// A component that remove an exercise and all spans of its type
export function DeleteExerciseType({ exercise }: DeleteExerciseTypeProps) {
  const dispatch = useDispatch()

  const deleteEx = useCallback(() => {
    dispatch(removeExerciseType(exercise.id))
  }, [dispatch, exercise])

  return (
    <Box ml="auto" alignItems="center">
      <Tooltip title="Remove all exercises of this type" placement="top">
        <Fab
          size="small"
          style={{
            maxWidth: "24px",
            maxHeight: "24px",
            minWidth: "24px",
            minHeight: "24px",
            marginLeft: "4px",
          }}
          onClick={deleteEx}
        >
          <DeleteIcon style={{ width: 14, height: 14 }} />
        </Fab>
      </Tooltip>
    </Box>
  )
}

function SidebarExercises() {
  const classes = useStyles()

  const exercises = useSelector(selectInflatedExercises)
  const openSpan = useSelector(selectOpenSpan)
  const exerciseReps = useSelector(selectExerciseRepCounts)

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <AddRep />
        </Grid>
        <Grid item xs={4}>
          <DeleteRep />
        </Grid>
        <Grid item xs={4}>
          <InsertSplit />
        </Grid>
        <Grid item xs={6}>
          <IgnoreSpan ignoreType="user" hotKey="u" />
        </Grid>
        <Grid item xs={6}>
          <IgnoreSpan ignoreType="ml" hotKey="i" />
        </Grid>
        <Grid item xs={12}>
          <AddReps />
        </Grid>
      </Grid>
      {Object.entries(exercises).map(([id, ex], idx) => (
        <Box display="flex" alignItems="center" key={id}>
          <ToggleExercise key={id} idx={idx} exerciseID={id} exerciseName={ex.def.name.toLowerCase()}>
            <Box display="flex" alignItems="center" mt={2}>
              <Typography
                color="primary"
                variant="body1"
                className={openSpan?.exercise.id === id ? classes.activeHotkey : classes.hotkey}
              >
                {hotKeyForIndex(idx)}
              </Typography>
              <Box flexGrow={1} ml={1}>
                <Typography style={{ fontWeight: 500 }}>
                  {ex.modifiers && `${ex.modifiers?.join(" ").toLowerCase()} `}
                  {ex.def.name.toLowerCase()}
                </Typography>
                {exerciseReps[ex.id] > 0 && (
                  <Typography color="textSecondary" style={{ fontWeight: 200 }}>
                    {exerciseReps[ex.id]} reps
                  </Typography>
                )}
              </Box>
              <Box display="flex" alignItems="center">
                <Typography color="textSecondary" style={{ fontWeight: 300 }}>
                  {ex.equipment && inflatedEquipmentListToString(ex.equipment)}
                </Typography>
              </Box>
            </Box>
          </ToggleExercise>
          <DeleteExerciseType exercise={ex} />
        </Box>
      ))}
    </>
  )
}

interface ToggleExerciseProps {
  exerciseID: string
  exerciseName: string
  idx: number
  children: any
}

function ToggleExercise({ exerciseID, exerciseName, idx, children }: ToggleExerciseProps) {
  const hotKey = hotKeyForIndex(idx)

  const openSpan = useSelector(selectOpenSpan)
  const dispatch = useDispatch()
  const onClick = useCallback(() => {
    if (!openSpan) {
      dispatch(startSpan({ id: uuidv4(), ref: exerciseID }))
    }

    if (openSpan && openSpan.exercise.id === exerciseID) {
      dispatch(endSpan(openSpan.id))
    }
  }, [dispatch, openSpan, exerciseID])

  return (
    <HotKey action={onClick} hotKey={hotKey} description={`toggle ${exerciseName}`}>
      {children}
    </HotKey>
  )
}

interface IgnoreSpanProps {
  ignoreType: string
  hotKey: string
}

function IgnoreSpan({ ignoreType, hotKey }: IgnoreSpanProps) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const action = useCallback(() => {
    dispatch(ignoreHoverExercise(ignoreType))
  }, [dispatch, ignoreType])

  return (
    <HotKey hotKey={hotKey} action={action} description="mark span as ignored">
      <Box display="flex" alignItems="center" mt={2}>
        <Typography color="primary" variant="body1" className={classes.hotkey}>
          {hotKey}
        </Typography>
        <Box flexGrow={1} ml={1}>
          <Typography style={{ fontWeight: 500 }}>Ignore Span ({ignoreType})</Typography>
        </Box>
      </Box>
    </HotKey>
  )
}

function AddRep() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const addRep = useCallback(() => dispatch(reduxAddRep(uuidv4())), [dispatch])

  return (
    <HotKey hotKey={" "} action={addRep} description="add a rep">
      <Box display="flex" alignItems="center" mt={2}>
        <Typography color="primary" variant="body1" className={classes.hotkey}>
          {"\u2423"}
        </Typography>
        <Box flexGrow={1} ml={1}>
          <Typography style={{ fontWeight: 500 }}>Add Rep</Typography>
        </Box>
      </Box>
    </HotKey>
  )
}

function DeleteRep() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const hotKey = "x"

  const delRep = useCallback(() => {
    dispatch(reduxRemoveRep())
  }, [dispatch])

  return (
    <HotKey hotKey={hotKey} action={delRep} description="delete closest rep">
      <Box display="flex" alignItems="center" mt={2}>
        <Typography color="primary" variant="body1" className={classes.hotkey}>
          {hotKey}
        </Typography>
        <Box flexGrow={1} ml={1}>
          <Typography style={{ fontWeight: 500 }}>Delete Rep</Typography>
        </Box>
      </Box>
    </HotKey>
  )
}

function InsertSplit() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const hotKey = "p"

  const action = useCallback(() => {
    dispatch(insertSplit(uuidv4()))
  }, [dispatch])
  return (
    <HotKey hotKey={hotKey} action={action} description="insert split marker">
      <Box display="flex" alignItems="center" mt={2}>
        <Typography color="primary" variant="body1" className={classes.hotkey}>
          {hotKey}
        </Typography>
        <Box flexGrow={1} ml={1}>
          <Typography style={{ fontWeight: 500 }}>Insert Split</Typography>
        </Box>
      </Box>
    </HotKey>
  )
}

export default SidebarExercises
