import React from "react"
import dayjs from "dayjs"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import ScribeListContainer from "./ScribeListContainer"
import { Scribe, User } from "../../services/firebase/firestore/types"
import _ from "lodash"
import { useAllScribesListener } from "../../hooks/firestore-listener"
import UserFlag from "../../components/UserFlag"
import { firestoreUrl } from "../../utils/links"

interface OwnProps {
  scribes: Scribe[]
  users: Record<string, User>
  classes: any
}

const tooShortThreshold = 60 * 1_000_000 // 60s

function ScribeStats({ scribes, users, classes }: OwnProps) {
  const validScribes = scribes.filter(s => s.duration_us >= tooShortThreshold).filter(s => s.deleted_at === undefined)

  const scribesByAthlete = validScribes.reduce((acc: { [uid: string]: Scribe[] }, s) => {
    const uid = s.user_id || "<unknown>"
    const newVal = acc[uid] || []
    newVal.push(s)
    acc[uid] = newVal
    return acc
  }, {})

  return (
    <>
      <Typography variant="h2" className={classes.h2}>
        Scribes by Athlete (
        {Object.entries(scribesByAthlete)
          .map(
            ([user_id, scribes]) =>
              _.chain(scribes)
                .map(s => dayjs(s.started_at.toDate()).format("YYYY-MM-DD"))
                .uniq()
                .value().length,
          )
          .reduce((acc, sessionCount) => acc + sessionCount)}{" "}
        sessions)
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {Object.entries(scribesByAthlete)
              .sort(([a, alst], [b, blst]) => blst.length - alst.length)
              .map(([user_id, scribes]) => (
                <TableRow
                  key={user_id}
                  className={classes.clickableRow}
                  onClick={() => {
                    window.open(firestoreUrl(`users/${user_id}`), "_blank")?.focus()
                  }}
                >
                  <TableCell align="left">{users[user_id]?.display_name || user_id}</TableCell>
                  <TableCell align="left">
                    <div>{scribes.length} Scribes</div>
                  </TableCell>
                  <TableCell align="left">
                    {
                      _.chain(scribes)
                        .map(s => dayjs(s.started_at.toDate()).format("YYYY-MM-DD"))
                        .uniq()
                        .value().length
                    }{" "}
                    Sessions
                  </TableCell>
                  <TableCell>
                    <UserFlag userID={user_id} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default function WrappedScribeStats() {
  return <ScribeListContainer scribes={useAllScribesListener()} wrappedComponent={ScribeStats} />
}
