import dayjs from "dayjs"
import React from "react"

interface Scribe {
  started_at?: any
}

interface OwnProps {
  scribe: Scribe
}

function ScribeDate({ scribe }: OwnProps) {
  return <>{formatDate(scribe.started_at?.toDate())}</>
}

function formatDate(d?: Date): string {
  return dayjs(d).format("MMM D, YYYY - HH:mm")
}

export default ScribeDate
