import { ScribeColumn } from "../ScribeList"
import { TableCell } from "@mui/material"
import ChipList from "../../fields/ChipList"

export const ExercisesColumn: ScribeColumn = {
  header: <TableCell>Exercises</TableCell>,
  cell(s) {
    const chips = s.exercise_types?.map(t => {
      return {
        id: t,
      }
    })

    return <TableCell>{chips && <ChipList chips={chips} />}</TableCell>
  },
}
