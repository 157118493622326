import { ScribeColumn } from "../ScribeList"
import { TableCell } from "@mui/material"
import ScribeDate from "../../scribes/ScribeDate"

export const DateColumn: ScribeColumn = {
  header: <TableCell align="right">Date</TableCell>,
  cell(s) {
    return (
      <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
        <ScribeDate scribe={s} />
      </TableCell>
    )
  },
}
