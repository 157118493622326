import { Box, CircularProgress, Fab, Grid, Paper, Tooltip, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { BlueprintNew } from "../../services/firebase/firestore/types/blueprint"
import React, { useCallback, useState } from "react"
import YAML from "yaml"
import createStyles from "@mui/styles/createStyles"
import { ScribeExecutionProposal } from "../../services/firebase/firestore/types"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store"
import { setBlueprintYaml } from "../../store/session/reducer"
import { green, red } from "@mui/material/colors"
import _ from "lodash"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { FirestoreService } from "../../services/firebase"
import Editor from "@monaco-editor/react"

const useStyles = makeStyles(theme =>
  createStyles({
    smallFab: {
      margin: 4,
      maxWidth: "24px",
      maxHeight: "24px",
      minWidth: "24px",
      minHeight: "24px",
    },
    smallIcon: {
      width: 14,
      height: 14,
    },
    wrapper: {
      position: "relative",
    },
    fabProgress: {
      position: "absolute",
      top: 1,
      left: 1,
      zIndex: 1,
    },
  }),
)

interface OwnProps {
  scribeID: string
  proposal?: ScribeExecutionProposal
}

function BlueprintTab({ scribeID, proposal }: OwnProps) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const blueprintYaml = useSelector((state: RootState) => state.currentTaggingSession.blueprintYaml)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const isValid = error === ""

  const restoreFromProposal = useCallback(() => {
    const yamlStr = YAML.stringify(proposal?.blueprint, { sortMapEntries: true })
    dispatch(setBlueprintYaml(yamlStr))
  }, [dispatch, proposal])

  const saveBlueprint = useCallback(async () => {
    if (!proposal?.id) {
      return
    }
    setLoading(true)
    try {
      const doc = YAML.parse(blueprintYaml) as BlueprintNew
      await FirestoreService.updateProposalBlueprint(scribeID, proposal.id, doc)
      alert("Need to update proposal blueprint string")
    } finally {
      setLoading(false)
    }
  }, [blueprintYaml, proposal, scribeID, setLoading])

  const validate = useCallback(
    _.debounce(code => {
      try {
        YAML.parse(code)
        setError("")
      } catch (e: any) {
        setError(e.message)
      }
    }, 500),
    [setError],
  )

  const updateYaml = useCallback(
    (value?: string) => {
      if (!value) {
        return
      }
      if (value === blueprintYaml) {
        return
      }

      validate(value)
      dispatch(setBlueprintYaml(value))
    },
    [dispatch, blueprintYaml],
  )

  return (
    <Box mt={6}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="h2">Blueprint YAML</Typography>
            </Box>
            <Box>
              {(isValid && (
                <>
                  <CheckIcon style={{ color: green[500] }} />
                </>
              )) || (
                <>
                  <Tooltip title={error}>
                    <CloseIcon style={{ color: red[500] }} />
                  </Tooltip>
                </>
              )}
            </Box>
          </Box>
          {proposal?.id}
          <Paper>
            <Editor height="500px" value={blueprintYaml} language="yaml" onChange={updateYaml} />
          </Paper>
        </Grid>
        <Grid item xs={1}>
          <Box style={{ height: "100%" }} display="flex" flexDirection="column" justifyContent="center">
            <Tooltip title="Save blueprint">
              <div className={classes.wrapper}>
                <Fab disabled={loading || !isValid} className={classes.smallFab} size="small" onClick={saveBlueprint}>
                  <ArrowForwardIcon className={classes.smallIcon} />
                </Fab>
                {loading && <CircularProgress size={28} className={classes.fabProgress} />}
              </div>
            </Tooltip>
            <Tooltip title="Load from proposal">
              <Fab className={classes.smallFab} size="small" onClick={restoreFromProposal}>
                <ArrowBackIcon className={classes.smallIcon} />
              </Fab>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h2">Preview</Typography>
          <pre>{proposal?.blueprint_string}</pre>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BlueprintTab
