export type TagInfo = {
  title: string
  color?: string
  ro?: boolean
  link?: string
}

export enum ScribeTag {
  WrongPrediction = "wrong prediction",
  HardToLabel = "hard to label",
  WrongWearArm = "wrong wear arm",
  NeedsOpeartorAttetion = "needs_operator_attention",
  LabelingSpeedDoneBinary = "labeling_speed_done_binary",
  LabelingSpeedDoneFull = "labeling_speed_done_full",
  LabelingSpeedSkip = "labeling_speed_skip",
}

export const KnownTags: Partial<Record<string, TagInfo>> = {
  has_processed_file: { title: "Processed", color: "#afeeaf", ro: true },
  issue_ch165: { title: "Issue 165", color: "#f68181", link: "https://app.shortcut.com/glasswingdev/story/165" },
  issue_ch560: { title: "Issue 560", color: "#a80000", link: "https://app.shortcut.com/glasswingdev/story/560" },
  broken_scribe: { title: "Broken scribe", color: "#ffc200" },
  needs_operator_attention: { title: "Needs operator attention", color: "#4cdcff" },
  no_workout_detected: { title: "No workout detected" },
  uncertain_labeling: { title: "Uncertain labeling" },
  ignore_ml: { title: "Ignore ML", color: "#ff5454" },
  needs_ml_verification: { title: "Needs ML Verification", color: "#bc1bea" },
}

KnownTags[ScribeTag.HardToLabel] = { title: "Hard to Label" }
KnownTags[ScribeTag.WrongWearArm] = { title: "Wrong Wear Arm" }
KnownTags[ScribeTag.WrongPrediction] = { title: "Wrong Description" }
