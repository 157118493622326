import { Box, Paper, Tab, Tabs } from "@mui/material"
import { useEffect, useState } from "react"
import { Outlet, Link, useLocation } from "react-router-dom"

function Scribes() {
  const location = useLocation()

  const [activeTab, setActiveTab] = useState("/scribes/bytag")

  useEffect(() => {
    if (location.pathname === "/scribes") {
      // Skip, we'll get a new event because there's a redirect
      return
    }
    setActiveTab(location.pathname)
  }, [setActiveTab, location])

  const handleChange = (e: any, newValue: string) => {
    setActiveTab(newValue)
  }

  return (
    <Box>
      <Paper>
        <Tabs value={activeTab} onChange={handleChange}>
          <Tab label="By Tag" component={Link} to="/scribes/bytag" value="/scribes/bytag" />
          <Tab label="By User" component={Link} to="/scribes/byuser" value="/scribes/byuser" />
          <Tab label="By Exercise" component={Link} to="/scribes/byexercise" value="/scribes/byexercise" />
        </Tabs>
      </Paper>

      <Paper square sx={{ mt: 5 }}>
        <Outlet />
      </Paper>
    </Box>
  )
}

export default Scribes
