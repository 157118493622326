import UserInfo from "../UserInfo"

interface OwnProps {
  scribeID: string
  userID?: string
}

function ScribeDetails({ scribeID, userID }: OwnProps) {
  return (
    <>
      <div style={{ whiteSpace: "nowrap" }}>{scribeID}</div>
      <div>
        <UserInfo userID={userID} />
      </div>
    </>
  )
}

export default ScribeDetails
