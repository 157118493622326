import React, { memo, useCallback, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import dayjs from "dayjs"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import PersonIcon from "@mui/icons-material/Person"
import MemoryIcon from "@mui/icons-material/Memory"
import WarningIcon from "@mui/icons-material/Warning"
import InfoIcon from "@mui/icons-material/Info"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import Fab from "@mui/material/Fab"
import ReportIcon from "@mui/icons-material/Report"
import EditIcon from "@mui/icons-material/Edit"
import Tooltip from "@mui/material/Tooltip"
import RefreshIcon from "@mui/icons-material/Refresh"
import CheckIcon from "@mui/icons-material/Check"
import SyncAltIcon from "@mui/icons-material/SyncAlt"
import AddIcon from "@mui/icons-material/Add"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import VisibilityIcon from "@mui/icons-material/Visibility"
import _ from "lodash"
import WorkoutTable from "./WorkoutTable"
import ScribeData from "./data/ScribeData"
import ActivityClock from "./ActivityClock"
import UserData from "./UserData"
import SidebarExercises from "./SidebarExercises"
import { Scribe as ScribeT } from "../../services/firebase/firestore/types/scribe"
import SaveScribeButton from "./SaveScribeButton"
import { SensorData } from "./ScribeContainer"
import { FirestoreService } from "../../services/firebase"
import { ScribeExecutionProposal } from "../../services/firebase/firestore/types"
import {
  changeHoverExerciseToNextExerciseType,
  removeHoverExercise,
  setOverlayProposal,
  setSessionFromFirestore,
} from "../../store/session/reducer"
import { ScribeExecutionProposalStatus } from "../../services/firebase/firestore/types/scribe-execution-proposal"
import { setOpenState } from "../../store/exercise-dialog/reducer"
import ExerciseSelectorDialog from "./ExerciseSelectorDialog"
import { selectExerciseDialogIsOpen } from "../../store/exercise-dialog/selectors"
import { PredictionVisualization } from "../../services/firebase/storage/types/prediction-visualization"
import PredictionLegend from "./PredictionLegend"
import TriggerPredictionsSection from "./TriggerPredictionsSection"
import { SensorType } from "../../utils/sensorType"
import { matchScribe, reprocessScribe } from "../../services/backend"
import BlueprintTab from "./BlueprintTab"
import ScribeTags from "./ScribeTags"
import ScribeDate from "../../components/scribes/ScribeDate"
import { RootState } from "../../store"
import HelpDialog from "./HelpDialog"
import ScribeComments from "./ScribeComments"
import UserComments from "./UserComments"
import ScribeAuditLog from "./ScribeAuditLog"
import { ScribeTag } from "../../utils/tags"
import UserInfo from "../../components/UserInfo"
import ScribeInfo from "./ScribeInfo"
import { firestoreUrl } from "../../utils/links"

const drawerWidth = 268

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scribe_id: {
      color: theme.palette.text.secondary,
    },
    h2: {
      marginTop: 40,
      color: theme.palette.text.secondary,
    },
    scribe_head_subtitle: {
      color: theme.palette.text.secondary,
    },
    scribe_head_right: {
      textAlign: "right",
    },
    scribe_head_actions: {
      position: "relative",
    },
    scribe_head_actions_inner: {
      "& Button": {
        marginLeft: "8px",
      },

      position: "absolute",
      bottom: 16,
      right: 0,
    },
    actionsCell: {
      "& Button": {
        marginLeft: theme.spacing(1),
      },
    },
    proposalInfoCellContent: {
      display: "flex",
      alignItems: "center",
      flexWrap: "nowrap",
      fontWeight: "bold",
    },
    updateCellContent: {
      display: "flex",
      alignItems: "center",
    },
    mr1: {
      marginRight: theme.spacing(1),
    },
    proposalInfoCellIcon: {
      fontSize: "1.8em",
      marginRight: theme.spacing(1),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: "auto",
      padding: theme.spacing(0, 4, 4, 4),
    },
    notification: {
      backgroundColor: "#ECDFBD",
      borderRadius: 4,
      cursor: "pointer",
    },
    notificationIcon: {
      marginRight: theme.spacing(2),
    },
    smallFab: {
      maxWidth: "24px",
      maxHeight: "24px",
      minWidth: "24px",
      minHeight: "24px",
    },
    wrapper: {
      position: "relative",
    },
    progress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    fabProgress: {
      position: "absolute",
      top: -2,
      left: -2,
      zIndex: 1,
    },
  }),
)

export enum WearableSide {
  RIGHT = "RIGHT",
  LEFT = "LEFT",
}

interface WearablePose {
  arm: WearableSide
  crown: WearableSide
}

interface OwnProps {
  scribe?: ScribeT
  executionProposals?: ScribeExecutionProposal[]
  currentProposal?: ScribeExecutionProposal
  sensordata?: SensorData
  videoURL?: string
  userDescriptionImageURL?: string
  predictionVisualization?: PredictionVisualization
}

function Scribe({
  scribe,
  executionProposals,
  currentProposal,
  sensordata,
  videoURL,
  userDescriptionImageURL,
  predictionVisualization,
}: OwnProps) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const hasWrongDescription = !!scribe?.tags?.includes(ScribeTag.WrongPrediction)
  const isHardToLabel = !!scribe?.tags?.includes(ScribeTag.HardToLabel)
  const showExercisesDialog = useSelector(selectExerciseDialogIsOpen)

  const overlayProposal = useSelector((state: RootState) => state.currentTaggingSession.overlayProposal)

  const [isEditDescriptionDialogOpen, setIsEditDescriptionDialogOpen] = useState<boolean>(false)

  const [currentTab, setCurrentTab] = useState(0)
  const switchTab = (e: any, newTab: number) => {
    setCurrentTab(newTab)
  }

  let displayDescription = scribe?.user_description
  if (scribe?.user_edited_blueprint) {
    displayDescription = scribe?.proposal_description || displayDescription
  }

  const zoom = useSelector((state: RootState) => state.currentTaggingSession.accelerationGraph.zoom)
  const accelAxis = useSelector((state: RootState) => state.currentTaggingSession.accelerationGraph.showAxis)

  const wearablePose = getWearablePose(scribe)
  const normalizedSensorData = useMemo(() => {
    if (!scribe || !sensordata) {
      return sensordata
    }

    return _.mapValues(sensordata, (data, type) => {
      if (Number(type) === SensorType.Prediction) {
        // prediction data should not be normalized
        return data
      }

      let [minval, maxval] = data
        .map((axis, idx) => {
          const minval = axis.reduce((acc, v) => (acc < v ? acc : v))
          const maxval = axis.reduce((acc, v) => (acc > v ? acc : v))
          return [minval, maxval]
        })
        .reduce((res, [minval, maxval]) => {
          const combinedminval = Math.min(res[0], minval)
          const combinedmaxval = Math.max(res[1], maxval)
          const amp = Math.max(Math.abs(combinedminval), Math.abs(combinedmaxval))
          return [-amp, amp]
        })

      return data.map((axis, idx) => {
        // Filter out linear acceleration axes which are turned off.
        if (Number(type) === SensorType["Linear Acceleration"] && !accelAxis[idx]) {
          return [0]
        }

        let offset = 0
        let scale = 1

        if (Number(type) === SensorType["Linear Acceleration"]) {
          const numAxes = accelAxis.filter(e => e).length
          const moveidx = accelAxis.slice(0, idx).filter(e => e).length

          offset = (1 / numAxes) * (moveidx - (numAxes - 1) / 2)
          scale = zoom / numAxes
        } else if (Number(type) === SensorType.Gravity) {
          // Gravity values should be between -1 and 1. Sometimes they go crazy. This should make the
          // graphs more consistent between workouts.
          minval = -1.05
          maxval = 1.05
        }

        return (
          axis
            // Scale the graph
            .map(p => p * scale)
            // Set minimum value to 0
            .map(p => p - minval)
            // Scale all values to 0 <= v <= 1
            .map(p => p / (maxval - minval))
            // Offset the graph
            .map(p => p + offset)
        )
      })
    })
  }, [scribe, sensordata, zoom, accelAxis])

  if (!scribe) {
    return (
      <Grid container item justifyContent="center">
        <CircularProgress />
      </Grid>
    )
  }

  const activeExecutionProposal = executionProposals?.find(p => p.id === scribe.execution_proposal_id)
  const trainingProposal = executionProposals?.find(p => p.id === scribe.training_proposal_id)

  const completeExecutionProposals = executionProposals
    ?.filter(p => p.id !== scribe.execution_proposal_id)
    .filter(p => p.metadata.status === ScribeExecutionProposalStatus.Complete)
  const draftExecutionProposals = executionProposals
    ?.filter(p => p.id !== scribe.execution_proposal_id)
    .filter(p => p.metadata.status === ScribeExecutionProposalStatus.Draft)

  if (scribe.execution && !scribe.execution_proposal_id) {
    executionProposals = [
      ...(executionProposals || []),
      {
        execution: scribe.execution,
        metadata: {
          automatic: false,
          status: ScribeExecutionProposalStatus.Complete,
          created_at: scribe.started_at,
          updated_at: scribe.started_at,
          note: "loaded from prediction_manual (legacy execution)",
        },
      },
    ]
  }

  const sidebarNotifications = {
    hasPause: (scribe?.events ?? []).filter(e => e.type === "pause").length > 0,
    requestDescription: !scribe.user_description && !scribe.is_description_requested,
    descriptionRequested: !scribe.user_description && scribe.is_description_requested,
    hardToLabel: !!scribe?.tags?.includes(ScribeTag.HardToLabel),
    wrongDescription: !!scribe?.tags?.includes(ScribeTag.WrongPrediction),
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: 1000,
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: 3,
      }}
    >
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          {/* Notifications */}
          {Object.values(sidebarNotifications).some(v => !!v) && (
            <Box mt={4}>
              {sidebarNotifications.hasPause && (
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.notification}
                  p={2}
                  mt={2}
                  style={{ backgroundColor: "#E9BFBF" }}
                >
                  <WarningIcon htmlColor="#D27E7E" className={classes.notificationIcon} />
                  <Typography variant="body1">
                    This workout contains pause events, the timeline will NOT be correct
                  </Typography>
                </Box>
              )}
              {sidebarNotifications.requestDescription && (
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.notification}
                  p={2}
                  mt={2}
                  onClick={() => FirestoreService.requestDescription(scribe.id)}
                >
                  <WarningIcon htmlColor="#D4B669" className={classes.notificationIcon} />
                  <Box>
                    <Typography variant="body1">No user description</Typography>
                    <Typography variant="body2">Click to request from user</Typography>
                  </Box>
                </Box>
              )}

              {sidebarNotifications.descriptionRequested && (
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.notification}
                  p={2}
                  mt={2}
                  style={{ backgroundColor: "#BFC5E9" }}
                >
                  <InfoIcon htmlColor="#7E8BD2" className={classes.notificationIcon} />
                  <Typography variant="body1">User description requested</Typography>
                </Box>
              )}

              {sidebarNotifications.hardToLabel && (
                <Box display="flex" alignItems="center" className={classes.notification} p={2} mt={2}>
                  <WarningIcon htmlColor="#D4B669" className={classes.notificationIcon} />
                  <Typography variant="body1">Hard to label</Typography>
                </Box>
              )}

              {sidebarNotifications.wrongDescription && (
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.notification}
                  p={2}
                  mt={2}
                  style={{ backgroundColor: "#E9BFBF" }}
                >
                  <WarningIcon htmlColor="#D27E7E" className={classes.notificationIcon} />
                  <Typography variant="body1">Wrong description</Typography>
                </Box>
              )}
            </Box>
          )}

          {/* Info */}
          <Box mt={6}>
            <ScribeInfo scribe={scribe} />
            <Typography variant="body1" component="div">
              <Box mb={2}>
                <div>
                  <a target="_blank" rel="noreferrer" href={firestoreUrl(`users/${scribe.user_id}`)}>
                    <UserInfo userID={scribe.user_id} />
                  </a>
                </div>
                <ScribeDate scribe={scribe} />
                <div>{formatTime(scribe.duration_us)}</div>
              </Box>
              <Box mb={2}>
                <div>
                  Build {scribe.metadata?.wodscribe_app_version} {scribe.metadata?.app_origin && "(TestFlight)"}
                </div>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <div>{scribe.metadata?.watch_wear_position}</div>
                  </Box>
                  <Tooltip arrow title="Switch arm" placement="top">
                    <div>
                      {wearablePose.arm === WearableSide.RIGHT && (
                        <EmojiPeopleIcon
                          style={{
                            width: 16,
                            height: 16,
                            transform: "scale(-1, 1)",
                            cursor: "pointer",
                          }}
                          onClick={() => FirestoreService.toggleTag(scribe.id, ScribeTag.WrongWearArm)}
                        />
                      )}
                      {wearablePose.arm === WearableSide.LEFT && (
                        <EmojiPeopleIcon
                          style={{
                            width: 16,
                            height: 16,
                            cursor: "pointer",
                          }}
                          onClick={() => FirestoreService.toggleTag(scribe.id, ScribeTag.WrongWearArm)}
                        />
                      )}
                    </div>
                  </Tooltip>
                </Box>
              </Box>
              <Box>
                <a target="_blank" rel="noreferrer" href={firestoreUrl(`scribes/${scribe.id}`)}>
                  <Box display="flex" alignItems="center" mb={2}>
                    Firestore
                    <OpenInNewIcon style={{ width: 14, height: 14, marginLeft: 4 }} />
                  </Box>
                </a>
                {scribe.clone_of && (
                  <Box>
                    Clone of{" "}
                    <a href={`/scribe/${scribe.clone_of}`} target="blank">
                      {scribe.clone_of}
                    </a>
                  </Box>
                )}
                <Box mt={2} display="flex" flexDirection="row" justifyContent="space-around">
                  <ReprocessButton scribeID={scribe.id} />
                  <MatchStructureButton scribeID={scribe.id} />
                </Box>
              </Box>
            </Typography>
          </Box>

          {/* User Comments*/}
          <Box mt={6}>
            <UserComments userID={scribe.user_id!} />
          </Box>

          {/* Tags*/}
          <Box mt={6}>
            <Typography variant="h1">Tags</Typography>
            <ScribeTags scribe={scribe} />
          </Box>

          {/* Execution proposals */}
          <Box mt={6}>
            <Box display="flex" mb={1}>
              <Box flexGrow={1}>
                <Typography variant="h1">Proposals</Typography>
              </Box>
              <TriggerPredictionsSection scribeID={scribe.id} />
            </Box>

            {!executionProposals?.length && "No proposals yet"}

            {activeExecutionProposal && <ProposalSection category="Active" proposals={[activeExecutionProposal]} />}

            {trainingProposal && (
              <ProposalSection
                category="Training"
                proposals={[trainingProposal]}
                deleteHandler={p => {
                  if (!scribe.id) {
                    console.log("missing scribe id")
                    return
                  }
                  FirestoreService.setTrainingProposal(scribe.id, null).catch(r => console.log("failed", r))
                }}
              />
            )}

            {completeExecutionProposals && completeExecutionProposals.length > 0 && (
              <ProposalSection category="Complete" proposals={completeExecutionProposals} />
            )}

            {draftExecutionProposals && draftExecutionProposals.length > 0 && (
              <ProposalSection category="Draft" proposals={draftExecutionProposals} />
            )}
          </Box>

          {/* Description */}
          <Box mt={6}>
            <Box display="flex" mb={1}>
              <Box flexGrow={1}>
                <Typography variant="h1">Description</Typography>
              </Box>
              <Box>
                {scribe.user_description && !hasWrongDescription && (
                  <Tooltip title="Mark as wrong" placement="top">
                    <Fab
                      size="small"
                      color="primary"
                      style={{
                        maxWidth: "24px",
                        maxHeight: "24px",
                        minWidth: "24px",
                        minHeight: "24px",
                        marginLeft: "4px",
                      }}
                      onClick={() => FirestoreService.setTag(scribe.id, ScribeTag.WrongPrediction)}
                    >
                      <ReportIcon style={{ width: 14, height: 14 }} />
                    </Fab>
                  </Tooltip>
                )}

                {scribe.user_description && hasWrongDescription && (
                  <Tooltip title="Mark as correct" placement="top">
                    <Fab
                      size="small"
                      color="primary"
                      style={{
                        maxWidth: "24px",
                        maxHeight: "24px",
                        minWidth: "24px",
                        minHeight: "24px",
                        marginLeft: "4px",
                      }}
                      onClick={() => FirestoreService.unsetTag(scribe.id, ScribeTag.WrongPrediction)}
                    >
                      <CheckIcon style={{ width: 14, height: 14 }} />
                    </Fab>
                  </Tooltip>
                )}

                <Tooltip title="Edit" placement="top">
                  <Fab
                    size="small"
                    color="primary"
                    style={{
                      maxWidth: "24px",
                      maxHeight: "24px",
                      minWidth: "24px",
                      minHeight: "24px",
                      marginLeft: "4px",
                    }}
                    onClick={() => setIsEditDescriptionDialogOpen(true)}
                  >
                    <EditIcon style={{ width: 14, height: 14 }} />
                  </Fab>
                </Tooltip>

                <EditUserDescriptionDialog
                  scribe={scribe}
                  isOpen={isEditDescriptionDialogOpen}
                  onClose={() => setIsEditDescriptionDialogOpen(false)}
                />
              </Box>
            </Box>

            {scribe.user_title && <b>{scribe.user_title}</b>}

            <div style={{ overflowX: "auto" }}>
              <Typography variant="body2" component="pre">
                {displayDescription || "No description yet"}
              </Typography>
            </div>

            {userDescriptionImageURL && (
              <a target="_blank" rel="noreferrer" href={userDescriptionImageURL}>
                <img src={userDescriptionImageURL} alt="Workout description" width="100%" />
              </a>
            )}
          </Box>

          {/* Workout Comments */}
          <Box mt={6}>
            <ScribeComments scribeID={scribe.id} />
          </Box>

          {/* Audit Log */}
          <Box mt={6}>
            <Typography variant="h1">Audit Log</Typography>
            <Box pt={2}>
              <ScribeAuditLog scribeID={scribe.id} />
            </Box>
          </Box>
        </div>
      </Drawer>
      <Box display="flex">
        <Box flexGrow={1}>
          <Paper square>
            <Tabs indicatorColor="primary" value={currentTab} onChange={switchTab}>
              <Tab label="Label" />
              <Tab label="Blueprint" />
            </Tabs>
          </Paper>
        </Box>
        <HelpDialog />
      </Box>

      <TabPanel value={currentTab} index={0}>
        <Grid container className={classes.scribe_head_subtitle}>
          <Grid item xs={6}>
            <Typography variant="h2" className={classes.h2}>
              Scribe <ActivityClock />
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.scribe_head_actions}>
            <span className={classes.scribe_head_actions_inner}>
              {!isHardToLabel && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => FirestoreService.setTag(scribe.id, ScribeTag.HardToLabel)}
                >
                  Hard to label
                </Button>
              )}
              {isHardToLabel && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => FirestoreService.unsetTag(scribe.id, ScribeTag.HardToLabel)}
                >
                  Not hard to label
                </Button>
              )}
            </span>
          </Grid>
        </Grid>

        <Typography variant="h2" className={classes.h2}>
          Data
        </Typography>
        <ScribeData
          proposal={currentProposal}
          prediction={overlayProposal?.execution}
          rawSensordata={sensordata}
          sensordata={normalizedSensorData}
          videoURL={videoURL}
        />

        <PredictionLegend predictionVisualization={predictionVisualization} />
        <SaveScribeButton
          scribeID={scribe.id}
          scribeExecutionID={scribe.execution_proposal_id}
          proposalMetadata={currentProposal?.metadata}
        />
        <ExerciseSelectorDialog isOpen={showExercisesDialog} onClose={() => dispatch(setOpenState(false))} />
      </TabPanel>

      <TabPanel index={1} value={currentTab}>
        <BlueprintTab scribeID={scribe.id} proposal={currentProposal} />
      </TabPanel>

      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          {/* Points */}
          <Box mt={6}>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography variant="h1">Points</Typography>
              </Box>
              <Typography variant="h1">{scribe.points}</Typography>
            </Box>
          </Box>

          {/* Exercises */}
          <Box mt={6}>
            <Box display="flex" mb={1}>
              <Box flexGrow={1}>
                <Typography variant="h1">Exercises</Typography>
              </Box>
              <Box>
                <Tooltip title="Add exercise [+]" placement="top">
                  <Fab
                    size="small"
                    color="primary"
                    style={{
                      maxWidth: "24px",
                      maxHeight: "24px",
                      minWidth: "24px",
                      minHeight: "24px",
                      marginLeft: "4px",
                    }}
                    onClick={() => dispatch(setOpenState(true))}
                  >
                    <AddIcon style={{ width: 14, height: 14 }} />
                  </Fab>
                </Tooltip>
              </Box>
            </Box>
            <SidebarExercises />
          </Box>

          {/* Labels */}
          <Box mt={6}>
            <Box display="flex" mb={1}>
              <Box flexGrow={1}>
                <Typography variant="h1">Labels</Typography>
              </Box>
              <Box>
                <Tooltip title="Cycle exercise type [c]" placement="top">
                  <Fab
                    size="small"
                    color="primary"
                    style={{
                      maxWidth: "24px",
                      maxHeight: "24px",
                      minWidth: "24px",
                      minHeight: "24px",
                      marginLeft: "4px",
                    }}
                    onClick={() => dispatch(changeHoverExerciseToNextExerciseType())}
                  >
                    <SyncAltIcon style={{ width: 14, height: 14 }} />
                  </Fab>
                </Tooltip>

                <Tooltip title="Delete exercise [d]" placement="top">
                  <Fab
                    size="small"
                    color="primary"
                    style={{
                      maxWidth: "24px",
                      maxHeight: "24px",
                      minWidth: "24px",
                      minHeight: "24px",
                      marginLeft: "4px",
                    }}
                    onClick={() =>
                      window.confirm("Are you sure you want to delete the exercise?") && dispatch(removeHoverExercise())
                    }
                  >
                    <DeleteOutlineIcon style={{ width: 14, height: 14 }} />
                  </Fab>
                </Tooltip>
              </Box>
            </Box>
            <WorkoutTable />
          </Box>

          {/* Blueprint 2*/}
          <Blueprint scribeID={scribe.id} proposal={currentProposal} />

          {/* Blueprint */}
          <Box mt={6}>
            <Box display="flex" mb={1}>
              <Box flexGrow={1}>
                <Typography variant="h1">Blueprint</Typography>
              </Box>
            </Box>
            <div style={{ overflowX: "auto", marginTop: 8 }}>
              {scribe.blueprint && (
                <>
                  <Typography variant="body1">
                    {scribe.blueprint.rounds} rounds {scribe.blueprint.class} (
                    {scribe.blueprint && scribe.blueprint.rep_scheme && `[${scribe.blueprint.rep_scheme.join(", ")}]`}
                    {scribe.blueprint.rep_scheme_type.toLowerCase()})
                  </Typography>
                  {scribe.blueprint.exercises.map((ex, idx) => (
                    <Typography key={idx} variant="body2">
                      {ex}
                    </Typography>
                  ))}
                </>
              )}
            </div>
          </Box>
        </div>
      </Drawer>
    </Box>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel({ children, index, value }: TabPanelProps) {
  return <>{value === index && children}</>
}

interface EditUserDescriptionDialogProps {
  scribe: ScribeT
  onClose: () => void
  isOpen: boolean
}

function EditUserDescriptionDialog(props: EditUserDescriptionDialogProps) {
  const { scribe, onClose, isOpen } = props

  const [newDescription, setNewDescription] = useState<string>(scribe.user_description ?? "")
  const [newTitle, setNewTitle] = useState<string>(scribe.user_title ?? "")

  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth="sm" fullWidth>
      <DialogTitle id="simple-dialog-title">Update user description</DialogTitle>
      <DialogContent>
        <Box mb={4}>
          <TextField
            label="Workout title"
            multiline
            rows={1}
            value={newTitle}
            onKeyDown={e => e.stopPropagation()}
            onChange={e => setNewTitle(e.target.value)}
            defaultValue="Workout title"
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box mb={4}>
          <TextField
            label="Workout description"
            multiline
            rows={25}
            value={newDescription}
            onKeyDown={e => e.stopPropagation()}
            onChange={e => setNewDescription(e.target.value)}
            defaultValue="Workout description"
            variant="outlined"
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            FirestoreService.setUserDescription(scribe.id, newTitle, newDescription)
            onClose()
          }}
          color="primary"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface BlueprintProps {
  scribeID: string
  proposal?: ScribeExecutionProposal
}

function Blueprint({ scribeID, proposal }: BlueprintProps) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const generateBlueprint = useCallback(() => {
    if (!proposal?.id) {
      return
    }
    setLoading(true)
    alert("Not implemented")
  }, [scribeID, proposal, setLoading])

  return (
    <Box mt={6}>
      <Box display="flex" mb={1}>
        <Box flexGrow={1}>
          <Typography variant="h1">Blueprint (Proposal)</Typography>
        </Box>
        <Box>
          <Tooltip title="Create Blueprint String" placement="top">
            <div className={classes.wrapper}>
              <Fab
                size="small"
                color="primary"
                disabled={loading}
                className={classes.smallFab}
                onClick={generateBlueprint}
              >
                <RefreshIcon style={{ width: 14, height: 14 }} />
              </Fab>
              {loading && <CircularProgress size={28} className={classes.fabProgress} style={{ marginLeft: "4px" }} />}
            </div>
          </Tooltip>
        </Box>
      </Box>

      {proposal?.blueprint && (
        <>
          <p>Class: {proposal.blueprint.class}</p>
        </>
      )}
      {proposal?.blueprint_string && (
        <>
          <pre>{proposal.blueprint_string}</pre>
        </>
      )}
    </Box>
  )
}

interface ProposalSectionProps {
  category: string
  proposals: ScribeExecutionProposal[]

  deleteHandler?: (proposal: ScribeExecutionProposal) => void
}

function ProposalSection({ category, proposals, deleteHandler }: ProposalSectionProps) {
  return (
    <Box mb={4}>
      <Typography variant="h2">{category}</Typography>
      {proposals.map(p => (
        <ProposalSectionItem key={p.id} proposal={p} deleteHandler={deleteHandler} />
      ))}
    </Box>
  )
}

interface ProposalSectionItemProps {
  proposal: ScribeExecutionProposal
  deleteHandler?: (p: ScribeExecutionProposal) => void
}

const useProposalSectionItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    userContainer: {
      minWidth: 0,
    },
    hoverStuff: {
      borderRadius: 4,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: theme.palette.grey[200],
      },
    },
  }),
)

function ProposalSectionItem({ proposal, deleteHandler }: ProposalSectionItemProps) {
  const classes = useProposalSectionItemStyles()
  const dispatch = useDispatch()
  const currentOverlayID = useSelector((state: RootState) => state.currentTaggingSession.overlayProposal?.id)
  const isOverlay = currentOverlayID === proposal.id

  const onProposalClick = useCallback(() => {
    dispatch(
      setSessionFromFirestore({
        proposal: proposal,
        timestamp: dayjs().valueOf(),
      }),
    )
  }, [dispatch, proposal])

  const onOverlayClick = useCallback(
    e => {
      dispatch(setOverlayProposal({ id: proposal.id, execution: proposal.execution }))
      e.stopPropagation()
    },
    [dispatch, proposal],
  )

  const onDeleteClick = useCallback(
    e => {
      e.stopPropagation()
      if (!deleteHandler) {
        return
      }
      deleteHandler(proposal)
    },
    [proposal, deleteHandler],
  )

  return (
    <Tooltip title={String(proposal.id)}>
      <Box display="flex" alignItems="center" className={classes.hoverStuff} p={1} mt={1} onClick={onProposalClick}>
        <Box>
          {proposal.metadata.automatic && <MemoryIcon style={{ width: 14, height: 14 }} />}
          {!proposal.metadata.automatic && <PersonIcon style={{ width: 14, height: 14 }} />}
        </Box>
        <Box flexGrow={1} px={2} className={classes.userContainer}>
          {!proposal.metadata.automatic && <UserData userID={proposal.metadata.user_id} />}
          <Typography variant="body1">
            {proposal.metadata.automatic && `Model: ${proposal.metadata.model || "<unknown>"}`}
          </Typography>
          <Typography variant="body2">{formatDate(proposal.metadata.updated_at.toDate())}</Typography>
        </Box>
        <Box>
          <Tooltip arrow title="Use as overlay" placement="top">
            <IconButton onClick={onOverlayClick} size="large">
              <VisibilityIcon style={{ width: 14, height: 14, color: isOverlay ? "#0000ff" : undefined }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <ChevronRightIcon style={{ width: 14, height: 14 }} />
        </Box>
        {deleteHandler && (
          <Box>
            <Tooltip arrow title="Clear training proposal" placement="top">
              <IconButton onClick={onDeleteClick} size="large">
                <HighlightOffIcon style={{ width: 14, height: 14 }} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Tooltip>
  )
}

interface ReprocessButtonProps {
  scribeID: string
}

function ReprocessButton({ scribeID }: ReprocessButtonProps) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const onClick = useCallback(() => {
    setLoading(true)
    reprocessScribe(scribeID)
      .then(() => window.location.reload())
      .finally(() => setLoading(false))
  }, [scribeID, setLoading])

  return (
    <div className={classes.wrapper}>
      <Button variant="contained" size="small" color="secondary" onClick={onClick}>
        Reprocess
      </Button>
      {loading && <CircularProgress className={classes.progress} size={24} />}
    </div>
  )
}

interface MatchStructureButtonProps {
  scribeID: string
}

function MatchStructureButton({ scribeID }: MatchStructureButtonProps) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const onClick = useCallback(() => {
    setLoading(true)
    matchScribe(scribeID).finally(() => setLoading(false))
  }, [scribeID, setLoading])

  return (
    <div className={classes.wrapper}>
      <Button variant="contained" size="small" color="secondary" onClick={onClick}>
        Match
      </Button>
      {loading && <CircularProgress className={classes.progress} size={24} />}
    </div>
  )
}

function formatDate(d?: Date): string {
  return dayjs(d).format("MMM D, YYYY - HH:mm")
}

function formatTime(t?: number): string {
  if (!t) {
    return ""
  }

  let sec = Math.floor(t / 1000000)
  if (sec < 60) {
    return `${sec}s`
  }

  return `${Math.floor(sec / 60)}m ${sec % 60}s`
}

function getWearablePose(scribe?: ScribeT): WearablePose {
  return {
    arm:
      scribe?.metadata?.watch_wear_position.includes("right wrist") && !scribe?.tags?.includes(ScribeTag.WrongWearArm)
        ? WearableSide.RIGHT
        : WearableSide.LEFT,
    crown: scribe?.metadata?.watch_wear_position.includes("(crown right)") ? WearableSide.RIGHT : WearableSide.LEFT,
  }
}

export default memo(Scribe)
