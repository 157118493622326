import React, { useContext } from "react"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import { useNavigate, Navigate } from "react-router-dom"
import UserContext from "../../context/user-context"
import firebase from "firebase/compat/app"

function LoginPage() {
  const user = useContext(UserContext)
  const navigate = useNavigate()

  if (user !== null) {
    return <Navigate to="/" replace />
  }

  return (
    <StyledFirebaseAuth
      uiConfig={{
        signInFlow: "popup",
        signInSuccessUrl: "/signedIn",
        signInOptions: [
          // @ts-ignore https://github.com/prescottprue/react-redux-firebase/issues/909
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
          signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            if (!redirectUrl) {
              return false
            }

            // @ts-ignore
            firebase.handleRedirectResult(authResult).then(() => {
              navigate(redirectUrl)
            })

            return false
          },
        },
      }}
      firebaseAuth={firebase.auth()}
    />
  )
}

export default LoginPage
