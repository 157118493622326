import { useCallback, useState } from "react"
import Button from "@mui/material/Button"
import { requestRepCount } from "../../../services/backend"
import { useDispatch, useSelector } from "react-redux"
import { selectSpanUnderCursor } from "../../../store/session/selectors"
import { DATA_FREQUENCY } from "../../../utils/constants"
import { SensorData } from "../ScribeContainer"
import { v4 as uuidv4 } from "uuid"
import { addReps, removeRepsBetween, RepDescriptor } from "../../../store/session/reducer"
import { Box, Checkbox, CircularProgress, FormControlLabel } from "@mui/material"
import { useHotkeys } from "react-hotkeys-hook"

interface OwnProps {
  sensordata: SensorData
}

function RepCountRequester({ sensordata }: OwnProps) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [useFallback, setUseFallback] = useState(false)
  const span = useSelector(selectSpanUnderCursor)
  const onClick = useCallback(() => {
    if (!span || !span.end) {
      return
    }
    // find subdata
    const startIdx = span.begin * DATA_FREQUENCY
    const endIdx = span.end * DATA_FREQUENCY

    let subsensordata: SensorData = {}
    Object.keys(sensordata).forEach(key => {
      subsensordata[Number(key)] = sensordata[Number(key)].map(v => {
        return v.slice(startIdx, endIdx)
      })
    })
    setLoading(true)
    requestRepCount(span.exercise.def.id, subsensordata, useFallback)
      .then(reps => {
        let newReps = reps.map((rep): RepDescriptor => {
          return {
            id: uuidv4(),
            reptime: span.begin + rep / 1_000_000,
          }
        })
        dispatch(removeRepsBetween({ begin: span.begin, end: span.end! }))
        dispatch(addReps(newReps))
        console.log("add reps", newReps)
      })
      .catch(error => console.log("TODO", error))
      .finally(() => setLoading(false))
  }, [span, sensordata, dispatch, setLoading, useFallback])

  useHotkeys("a", onClick, { description: "Request rep count" }, [onClick])

  return (
    <Box display="flex" alignItems="center">
      <Button variant="contained" size="small" onClick={onClick} disabled={loading || !span}>
        Request rep count
      </Button>
      <FormControlLabel
        style={{ marginLeft: 1 }}
        control={<Checkbox checked={useFallback} onChange={() => setUseFallback(!useFallback)} />}
        label="Fallback to postprocessing"
      />
      {loading && <CircularProgress size={24} style={{ marginLeft: 1 }} />}
    </Box>
  )
}

export default RepCountRequester
