import { EquipmentDefinition, ExerciseDefinition } from "../../services/firebase/firestore/types/definitions"
import { ExerciseModifier, Measure } from "../../services/firebase/firestore/types/scribe"
import _ from "lodash"
import { Exercise } from "./basic"

export type InflatedSpans = { [spanID: string]: InflatedSpan }

export interface InflatedSpan {
  id: string
  exercise: InflatedExercise
  ignoredBy: string[]
  begin: number // Seconds
  end?: number
  marks: InflatedMark[]
}

export type InflatedMark = number

export type InflatedExercise = {
  id: string
  def: ExerciseDefinition
  modifiers?: ExerciseModifier[]
  equipment?: InflatedEquipment[]
}

export type InflatedEquipment = {
  def: EquipmentDefinition
  measure?: Measure
}

export function inflatedExerciseToExercise(ex: InflatedExercise): Exercise {
  let exercise: Exercise = {
    type: ex.def.id,
  }
  if (ex.modifiers) {
    exercise.modifiers = ex.modifiers
  }
  if (ex.equipment) {
    exercise.equipment = ex.equipment.map(e => ({
      type: e.def.id,
      measure: e.measure,
    }))
  }

  return exercise
}

export function inflatedExerciseToString(ex: InflatedExercise): string {
  let res = ""
  if (ex.modifiers) {
    res += [...ex.modifiers].sort().join(" ") + " "
  }

  res += ex.def.name

  if (ex.equipment) {
    res += " " + inflatedEquipmentListToString(ex.equipment)
  }

  return res
}

export function inflatedEquipmentListToString(eqs: InflatedEquipment[]): string {
  if (eqs.length === 0) {
    return ""
  }

  let res = ""
  if (eqs.length > 1) {
    if (inflatedEquipmentEquals(eqs[0], eqs[1])) {
      res += "2x "
    }
  }

  const eq = eqs[0]

  if (eq.measure) {
    res += `${eq.measure.value}${eq.measure.unit} `
  }
  res += eq.def.name
  return res
}

export function inflatedExerciseEquals(e1?: InflatedExercise, e2?: InflatedExercise): boolean {
  if (e1 === e2) {
    return true
  }

  if (e1 === undefined || e2 === undefined) {
    return false
  }

  if (e1.def.id !== e2.def.id) {
    return false
  }
  if (!_.isEqual(_.sortBy(e1.modifiers), _.sortBy(e2.modifiers))) {
    return false
  }

  return inflatedEquipmentListEquals(e1.equipment, e2.equipment)
}

export function inflatedEquipmentListEquals(e1?: InflatedEquipment[], e2?: InflatedEquipment[]): boolean {
  if (e1 === e2) {
    return true
  }

  if (e1 === undefined || e2 === undefined) {
    return false
  }

  if (e1.length !== e2.length) {
    return false
  }

  const sortedEq1 = [...e1].sort()
  const sortedEq2 = [...e2].sort()
  return _.zip(sortedEq1, sortedEq2).every(([eq1, eq2]) => inflatedEquipmentEquals(eq1!, eq2!))
}

export function inflatedEquipmentEquals(e1: InflatedEquipment, e2: InflatedEquipment): boolean {
  if (e1 === e2) {
    return true
  }

  if (e1.def.id !== e2.def.id) {
    return false
  }

  if (e1.measure === e2.measure) {
    return true
  }

  if (e1.measure === undefined || e2.measure === undefined) {
    return false
  }

  return e1.measure.unit === e2.measure.unit && e1.measure.value === e2.measure.value
}
