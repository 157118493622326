import React, { useCallback, useState } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Dialog from "@mui/material/Dialog"
import { useSelector, useDispatch } from "react-redux"
import { InflatedEquipment, InflatedExercise, inflatedExerciseToString } from "../../store/types"
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  DialogActions,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import ClearIcon from "@mui/icons-material/Clear"
import Autocomplete from "@mui/material/Autocomplete"
import { v4 as uuidv4 } from "uuid"
import { ExerciseModifier, Measure, unmarshalMeasure } from "../../services/firebase/firestore/types/scribe"
import { EquipmentDefinition, ExerciseDefinition } from "../../services/firebase/firestore/types/definitions"
import { selectEquipmentDefs, selectExerciseDefs } from "../../store/definitions/selectors"
import { setExercise as reduxSetExercise } from "../../store/session/reducer"
import { selectInflatedExercises } from "../../store/session/selectors"
import EditIcon from "@mui/icons-material/Edit"

interface OwnProps {
  isOpen: boolean
  onClose: () => void
}

const modifierLookup: Record<string, ExerciseModifier> = {
  strict: ExerciseModifier.Strict,
  deficit: ExerciseModifier.Deficit,
  kipping: ExerciseModifier.Kipping,
  weighted: ExerciseModifier.Weighted,
  negative: ExerciseModifier.Negative,
}

const sentinelDefinition: ExerciseDefinition = { id: "__SENTINEL__", name: "" }

function ExerciseSelectorDialog({ isOpen, onClose }: OwnProps) {
  const dispatch = useDispatch()

  const exerciseDefs = useSelector(selectExerciseDefs)
  const equipmentDefs = useSelector(selectEquipmentDefs)

  const [exerciseID, setExerciseID] = useState<string | null>(null)
  const [exercise, setExercise] = useState<InflatedExercise>({
    id: uuidv4(),
    def: sentinelDefinition,
  })

  const addEquipment = useCallback(
    (eqDef: EquipmentDefinition) => {
      const otherEq = exercise.equipment?.find(eq => eq.def.id === eqDef.id)
      setExercise({
        ...exercise,
        equipment: [
          ...(exercise.equipment || []),
          {
            def: eqDef,
            measure: otherEq?.measure, // e.g. when adding a second KB, the weight is matched.
          },
        ],
      })
    },
    [exercise, setExercise],
  )

  const setEquipmentMeasure = useCallback(
    (idx: number, measure: Measure) => {
      if (exercise.equipment && exercise.equipment.length > idx) {
        exercise.equipment[idx].measure = measure
        setExercise(exercise)
      }
    },
    [exercise, setExercise],
  )

  const removeEquipment = useCallback(
    idx => {
      setExercise({
        ...exercise,
        equipment: exercise.equipment?.filter((_, i) => i !== idx),
      })
    },
    [exercise, setExercise],
  )

  const setExerciseType = useCallback(
    (_, def: ExerciseDefinition | null) => {
      if (!def) {
        return
      }

      setExercise({ ...exercise, def })
    },
    [exercise, setExercise],
  )

  const toggleModifier = useCallback(
    (mod: ExerciseModifier) => {
      if (exercise.modifiers?.includes(mod)) {
        setExercise({
          ...exercise,
          modifiers: exercise.modifiers?.filter(m => m !== mod),
        })
      } else {
        setExercise({
          ...exercise,
          modifiers: [...(exercise.modifiers ?? []), mod],
        })
      }
    },
    [exercise, setExercise],
  )

  const setEditExercise = useCallback(
    (ex: InflatedExercise) => {
      setExerciseID(ex.id)
      setExercise(ex)
    },
    [setExerciseID, setExercise],
  )

  const unsetEditExercise = useCallback(() => {
    setExerciseID(null)
    setExercise({ id: uuidv4(), def: sentinelDefinition })
  }, [setExerciseID, setExercise])

  const onSave = useCallback(() => {
    dispatch(
      reduxSetExercise({
        id: exerciseID || uuidv4(),
        exercise: {
          type: exercise.def.id,
          modifiers: exercise.modifiers || [],
          equipment:
            exercise.equipment?.map(eq => ({
              type: eq.def.id,
              measure: eq.measure,
            })) ?? [],
        },
      }),
    )
    setExerciseID(null)
    setExercise({ id: uuidv4(), def: sentinelDefinition })
  }, [dispatch, exercise, setExercise, exerciseID, setExerciseID])

  const availableExercises = useSelector(selectInflatedExercises)

  return (
    <Dialog fullWidth={true} maxWidth="sm" onClose={onClose} aria-labelledby="simple-dialog-title" open={isOpen}>
      <DialogTitle id="simple-dialog-title">Exercise editor</DialogTitle>
      <DialogContent>
        {exerciseID && (
          <Box mt={4}>
            <Typography display="inline">Editing exercise: {exerciseID}</Typography>
            <IconButton aria-label="delete" onClick={unsetEditExercise} size="large">
              <ClearIcon fontSize="small" />
            </IconButton>
          </Box>
        )}

        <Box mt={4}>
          <Typography variant="h2">Exercise</Typography>
          <Autocomplete
            options={Object.values(exerciseDefs)}
            getOptionLabel={ex => ex.name}
            onChange={setExerciseType}
            onKeyDown={e => e.stopPropagation()}
            value={exercise.def}
            renderInput={params => <TextField {...params} autoFocus variant="standard" label="Exercises" />}
          />
        </Box>

        <Box mt={4}>
          <Typography variant="h2">
            Modifiers
            {exercise?.modifiers === undefined && (
              <Typography sx={{ marginLeft: 1 }} variant="body1" color="textSecondary" display="inline">
                (undefined)
              </Typography>
            )}
          </Typography>

          {Object.keys(modifierLookup).map(mod => (
            <FormControlLabel
              key={mod}
              label={mod}
              control={
                <Checkbox
                  checked={(exercise.modifiers ?? []).includes(modifierLookup[mod])}
                  onChange={(e, isChecked) => toggleModifier(modifierLookup[mod])}
                />
              }
            />
          ))}
        </Box>

        <Box mt={4}>
          <Typography variant="h2">
            Equipment
            {exercise?.equipment === undefined && (
              <Typography sx={{ marginLeft: 1 }} variant="body1" color="textSecondary" display="inline">
                (undefined)
              </Typography>
            )}
          </Typography>

          {exercise.def.equipment
            ?.filter(id => equipmentDefs[id])
            .map(id => (
              <Chip
                key={id}
                sx={{ marginRight: 2 }}
                variant="outlined"
                color="primary"
                avatar={<Avatar>+</Avatar>}
                onClick={() => addEquipment(equipmentDefs[id])}
                label={equipmentDefs[id].name}
              />
            ))}

          {exercise.equipment?.map((eq, idx) => (
            <EquipmentRow
              key={idx}
              eq={eq}
              onUpdate={m => setEquipmentMeasure(idx, m)}
              onDelete={() => removeEquipment(idx)}
            />
          ))}
        </Box>

        <Box mt={4}>
          <Button color="primary" variant="contained" onClick={onSave}>
            {exerciseID ? "Update exercise" : "Add exercise"}
          </Button>
        </Box>

        <Box mt={4}>
          {Object.entries(availableExercises).map(([id, ex]) => (
            <Box key={id}>
              <IconButton aria-label="edit" onClick={() => setEditExercise(ex)} size="large">
                <EditIcon fontSize="small" />
              </IconButton>
              <Typography display="inline">{inflatedExerciseToString(ex)}</Typography>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface EquipmentRowProps {
  eq: InflatedEquipment
  onUpdate: (m: Measure) => void
  onDelete: () => void
}

function EquipmentRow({ eq, onUpdate, onDelete }: EquipmentRowProps) {
  let [measureString, setMeasureString] = useState(eq.measure ? `${eq.measure.value}${eq.measure.unit}` : "")
  let onMeasureInputChange = useCallback(
    e => {
      const newVal = e.target.value
      setMeasureString(newVal)
      const m = unmarshalMeasure(newVal)
      if (m) {
        onUpdate(m)
      }
    },
    [setMeasureString, onUpdate],
  )

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
      <Grid item>
        <Typography>{eq.def.name}</Typography>
      </Grid>
      <Grid item>
        <TextField
          value={measureString}
          variant="standard"
          onChange={onMeasureInputChange}
          onKeyDown={e => e.stopPropagation()}
        />
      </Grid>
      <Grid item>
        <IconButton aria-label="delete" onClick={onDelete} size="large">
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default ExerciseSelectorDialog
