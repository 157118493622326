import {
  DateColumn,
  DetailsColumn,
  DurationColumn,
  ExercisesColumn,
  MetaColumn,
  TagsColumn,
} from "../../../components/scribelist/columns"

export const DefaultColumns = [DurationColumn, DateColumn, DetailsColumn, TagsColumn, ExercisesColumn, MetaColumn]
