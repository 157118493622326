import React from "react"
import ChatIcon from "@mui/icons-material/Chat"
import { Box, Tooltip, Typography } from "@mui/material"

interface OwnProps {
  user_description?: string
  user_description_image_ref?: string
}

function DescriptionIndicator({ user_description, user_description_image_ref }: OwnProps) {
  const shouldShowDescriptionIcon = user_description || user_description_image_ref
  return (
    <Box sx={{ display: "inline", mx: 1 }}>
      {shouldShowDescriptionIcon && (
        <Tooltip
          arrow
          title={
            <>
              <Typography>User Description:</Typography>
              <Typography variant="body2" component="pre">
                {user_description || "[image]"}
              </Typography>
            </>
          }
        >
          <ChatIcon fontSize="small" />
        </Tooltip>
      )}
      {/* Hidden icon just to get correct spacing */}
      {!shouldShowDescriptionIcon && <ChatIcon htmlColor="#FFFFFF" fontSize="small" />}
    </Box>
  )
}

export default DescriptionIndicator
