import firebase from "firebase/compat/app"
import { Scribe } from "../../services/firebase/firestore/types"
import { usePaginatedScribesListener } from "../../hooks/firestore-listener"
import {
  CircularProgress,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import React, { Fragment, useState } from "react"

export interface ScribeColumn {
  header: JSX.Element
  cell: (s: Scribe) => JSX.Element
}

interface OwnProps {
  query: firebase.firestore.Query<Scribe>
  columns: ScribeColumn[]
}

function ScribeList({ query, columns }: OwnProps) {
  const [limit, setLimit] = useState(25)
  const { scribes, loading, rowsEstimate, page, setPage, error } = usePaginatedScribesListener(query, { limit: limit })

  const handlePageChange = (event: any, page: number) => {
    setPage(page)
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const count = parseInt(event.target.value, 10)
    setLimit(count)
  }

  const openScribe = (s: Scribe) => {
    window.open(`/scribe/${s.id}`, "_blank")?.focus()
  }

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map((c, idx) => (
                  <Fragment key={idx}>{c.header}</Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                scribes.map(s => (
                  <TableRow sx={{ cursor: "pointer" }} key={s.id} onClick={() => openScribe(s)}>
                    {columns.map((c, idx) => (
                      <Fragment key={idx}>{c.cell(s)}</Fragment>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          count={rowsEstimate}
          component="div"
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPage={limit}
        />
      </Paper>
      <Snackbar open={Boolean(error)} message={String(error)} />
    </div>
  )
}

export default ScribeList
