import { Box, Button, Divider, TextField } from "@mui/material"
import { useCallback, useContext, useState } from "react"
import { Comment } from "../../services/firebase/firestore/types/comments"
import userContext from "../../context/user-context"
import ChatIcon from "@mui/icons-material/Chat"
import dayjs from "dayjs"
import Tooltip from "@mui/material/Tooltip"
import { useSelector } from "react-redux"
import _ from "lodash"
import { RootState } from "../../store"

function formatDate(d?: Date): string {
  return dayjs(d).format("MMM D, YYYY - HH:mm")
}

interface CommentCardProps {
  data: Comment
  onDelete?: () => void
}

function CommentCard({ data, onDelete }: CommentCardProps) {
  const users = useSelector((state: RootState) => state.users.users)
  const fullName = _.get(users, [data.user_id || "", "display_name"], data.user_id)

  const [open, setOpen] = useState(false)
  const handleClickOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <>
      <Tooltip title={`By ${fullName} on ${formatDate(data.timestamp?.toDate())}`} placement="right">
        <Box onClick={onDelete}>
          <Box display="flex" alignItems="top" p={1} mt={1}>
            <ChatIcon sx={{ width: ".75em", height: ".75em" }} />
            <Box sx={{ pl: 1, flexGrow: 1, minWidth: 0 }}>
              <pre style={{ margin: 0, whiteSpace: "pre-wrap" }}>{data.content}</pre>
            </Box>
          </Box>
        </Box>
      </Tooltip>
    </>
  )
}

interface CommentFormProps {
  onAddComment: (content: string) => void
}

function CommentForm({ onAddComment }: CommentFormProps) {
  const [content, setContent] = useState("")

  const onClick = useCallback(() => {
    if (content === "") {
      return
    }
    onAddComment(content)
    setContent("")
  }, [content, setContent, onAddComment])

  return (
    <>
      <TextField
        multiline
        label="Comment"
        value={content}
        onChange={e => setContent(e.target.value)}
        onKeyDown={e => e.stopPropagation()}
        variant="standard"
      />
      <Button onClick={onClick}>Add</Button>
    </>
  )
}

interface OwnProps {
  comments: Comment[]
  showInput?: boolean
  onAddComment: (c: Comment) => Promise<void>
  onDeleteComment: (c: Comment) => Promise<void>
}

function Comments({ comments, showInput = true, onAddComment, onDeleteComment }: OwnProps) {
  const user = useContext(userContext)

  const addComment = useCallback(
    async (content: string) => {
      if (!user?.uid) {
        throw Error("No user found")
      }
      const newComment = {
        content: content,
        user_id: user.uid,
      }

      await onAddComment(newComment)
    },
    [user, onAddComment],
  )

  const deleteComment = useCallback(
    async (c: Comment) => {
      if (!window.confirm("Delete this comment?")) {
        return
      }
      await onDeleteComment(c)
    },
    [onDeleteComment],
  )

  return (
    <>
      {comments.map((c, idx) => (
        <div key={c.id}>
          <CommentCard data={c} onDelete={() => deleteComment(c)} />
          {idx !== comments.length - 1 && <Divider />}
        </div>
      ))}
      {showInput && <CommentForm onAddComment={addComment} />}
    </>
  )
}

export default Comments
