import { ExerciseModifier, Measure } from "../../services/firebase/firestore/types/scribe"

export interface Span {
  ref: string // references the exercise ID
  begin: number
  end?: number
  ignoredBy?: string[]
}

export interface Mark {
  type: string
  time: number
  verified?: boolean
}

export interface Exercise {
  type: string
  modifiers?: ExerciseModifier[]
  equipment?: Equipment[]
}

export function exerciseKey(e: Exercise): string {
  // TODO: add modifiers and equipment
  return `${e.type}`
}

export interface Equipment {
  type: string
  measure?: Measure
}
