import React, { ChangeEvent, useCallback, useState } from "react"
import { Box, Grid, Theme } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import CircularProgress from "@mui/material/CircularProgress"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import _ from "lodash"
import Graphs from "./Graphs"
import Minimap from "./Minimap"
import Count from "./Count"
import { SensorData } from "../ScribeContainer"
import { WorkoutExecution } from "../../../services/firebase/firestore/types/scribe"
import RepCountInfo from "./RepCountInfo"
import { useDispatch, useSelector } from "react-redux"
import { selectEnabledGraphAxes, selectvisibleDataSources } from "../../../store/session/selectors"
import { sensorNameToSensorType, sensorTypeToSensorName } from "../../../utils/sensorType"
import { setVisibleDataSources } from "../../../store/session/reducer"
import RepCountRequester from "./RepCountRequester"
import PartCopier from "./PartCopier"
import GraphBounds from "./GraphBounds"
import { RootState } from "../../../store"
import { ScribeExecutionProposal } from "../../../services/firebase/firestore/types"
import CutOptions from "./CutOptions"
import RangeOptions from "./RangeOptions"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    graph: {
      marginTop: 10,
    },
    exercise_chip: {
      marginRight: theme.spacing(1),
    },
  }),
)

interface OwnProps {
  proposal?: ScribeExecutionProposal
  prediction?: WorkoutExecution
  sensordata?: SensorData
  rawSensordata?: SensorData
  videoURL?: string
}

function ScribeData({ proposal, prediction, sensordata, rawSensordata, videoURL }: OwnProps) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [showMinimap, setShowMinimap] = useState<boolean>(true)
  const [showVideo, setShowVideo] = useState<boolean>(false)

  const shownDataSources = useSelector(selectvisibleDataSources)
  const linearAccelerationGraphConfig = useSelector((state: RootState) => state.currentTaggingSession.accelerationGraph)

  const setShownDataSources = useCallback(
    (ds: string[]) => dispatch(setVisibleDataSources(ds.map(sensorNameToSensorType))),
    [dispatch],
  )

  const shownAxes = useSelector(selectEnabledGraphAxes)

  // Callbacks
  const onShowMinimap = useCallback((e: ChangeEvent<HTMLInputElement>) => setShowMinimap(e.target.checked), [])

  const shownDataSourceTypes = shownDataSources.map(k => sensorTypeToSensorName(k))

  if (!sensordata) {
    return (
      <Grid container item justifyContent="center">
        <CircularProgress />
      </Grid>
    )
  }

  const keyedSensordata = _.mapKeys(sensordata, (v, t) => sensorTypeToSensorName(Number(t)))

  const visibleAxes: Record<string, number[]> = {}
  shownAxes.forEach((v, idx) => (visibleAxes[sensorTypeToSensorName(idx)] = v))

  const toggleDataSource = (name: string) => setShownDataSources(_.xor(shownDataSourceTypes, [name]))

  return (
    <>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox checked={showMinimap} onChange={onShowMinimap} color="primary" name="minimap" />}
          label="Minimap"
        />

        {Object.keys(keyedSensordata).map(sensorType => (
          <FormControlLabel
            key={sensorType}
            control={
              <Checkbox
                checked={shownDataSourceTypes.includes(sensorType)}
                onChange={() => toggleDataSource(sensorType)}
                color="primary"
                name={sensorType}
              />
            }
            label={sensorType.charAt(0).toUpperCase() + sensorType.slice(1)}
          />
        ))}

        <FormControlLabel
          control={
            <Checkbox
              checked={showVideo}
              onChange={() => setShowVideo(!showVideo)}
              color="primary"
              name="video"
              disabled={videoURL === null}
            />
          }
          label="Video"
        />
      </FormGroup>
      <Box display="flex" alignItems="flex-end">
        {/* Left side*/}
        <Box flexGrow={1}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Count sensordata={keyedSensordata} />
            <Box marginLeft={10}>
              <PartCopier />
            </Box>
          </Box>
          <RepCountRequester sensordata={rawSensordata!} />
          <GraphBounds />
        </Box>
        {/* Right side*/}
        <Box textAlign="right">
          <RangeOptions />
          <CutOptions proposal={proposal} />
          <RepCountInfo />
        </Box>
      </Box>

      <div className={classes.graph}>{showMinimap && <Minimap sensors={keyedSensordata["Gravity"]} />}</div>
      <div className={classes.graph}>
        <Graphs
          prediction={prediction}
          sensordata={keyedSensordata}
          types={shownDataSourceTypes}
          visibleAxes={visibleAxes}
          linearAccelerationGraphConfig={linearAccelerationGraphConfig}
        />
      </div>
    </>
  )
}

export default ScribeData
