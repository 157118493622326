import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { v5 as uuidv5 } from "uuid"

import { ScribeExecutionProposal } from "../../services/firebase/firestore/types"
import { Equipment, Exercise, Mark, Span } from "../types"
import { SensorType } from "../../utils/sensorType"
import YAML from "yaml"
import { WorkoutExecution } from "../../services/firebase/firestore/types/scribe"
import _ from "lodash"
import { Cut } from "../../pixi/utils"

export type ReduxStoreSpans = { [uuid: string]: Span }
export type ReduxStoreMarks = { [uuid: string]: Mark }
export type ReduxStoreExercises = { [uuid: string]: Exercise }

interface SetExercisePayload {
  id: string
  exercise: Exercise
}

interface SetEnabledAxesPayload {
  sensorType: number
  axes: number[]
}

interface SetPredictionPayload {
  proposal: ScribeExecutionProposal
  timestamp: number
}

interface StartSpanActionPayload {
  id: string
  ref: string
}

interface ExerciseUpdator {
  id: string
  values: {
    begin?: number
    end?: number
  }
}

interface TimeSpan {
  begin: number
  end: number
}

interface OverlayProposal {
  id?: string
  execution: WorkoutExecution
}

export enum RangeMode {
  DRAG = "drag",
  CLICK = "click",
}

interface TaggingSession {
  currentProposalID?: string
  graphCursor: number

  exercises: ReduxStoreExercises
  spans: ReduxStoreSpans
  marks: ReduxStoreMarks
  isDirty: boolean

  activeDuration: number
  userActivityStarted: number | null
  userActionCount: number

  enabledGraphAxes: number[][]

  graphClickedTime?: number

  visibleDataSources: number[]

  blueprintYaml: string

  overlayProposal?: OverlayProposal

  accelerationGraph: {
    zoom: number
    showAxis: [boolean, boolean, boolean]
  }

  cuts: Cut[]
  rangeMode: RangeMode
}

export interface RepDescriptor {
  id: string
  reptime: number
}

export enum UserActivityState {
  IDLE,
  ACTIVE,
}

interface SetUserActivityStatePayload {
  state: UserActivityState
  timestamp: number
}

function exerciseHash(ex: Exercise): string {
  const equipmentSort = (e1: Equipment, e2: Equipment): number => {
    if (e1.type === e2.type) {
      if (!e1.measure) {
        return -1
      }
      if (!e2.measure) {
        return 1
      }
      if (e1.type !== e2.type) {
        return e1.type < e2.type ? -1 : 1
      }
      return e1.measure.value - e2.measure.value
    }
    return e1.type.localeCompare(e2.type)
  }
  const equipment = ex.equipment
    ?.sort(equipmentSort)
    .map(eq => (eq.measure ? `${eq.type} ${eq.measure.value}${eq.measure.unit}` : eq.type))
    .join(", ")
  return uuidv5(`${ex.type} Modifiers: [${ex.modifiers?.sort().join(", ")}] Equipment: [${equipment}]`, UUID_NAMESPACE)
}

const UUID_NAMESPACE = "6ffc53d5-19dc-4d9f-861e-d99cc1a33c68"
const taggingSessionSlice = createSlice({
  name: "taggingSession",
  initialState: {
    currentProposalID: undefined,
    graphCursor: 0,

    exercises: {},
    spans: {},
    marks: {},
    isDirty: false,

    activeDuration: 0,
    userActivityStarted: null,
    userActionCount: 0, // TODO (mathias) actions are not counted yet so it's always 0.

    enabledGraphAxes: [],

    showPredictionExerciseTypes: [],
    visibleDataSources: [SensorType["Linear Acceleration"], SensorType.Gravity],

    blueprintYaml: "",

    accelerationGraph: {
      zoom: 3,
      showAxis: [true, true, true],
    },
    cuts: [],
    rangeMode: RangeMode.DRAG,
  } as TaggingSession,
  reducers: {
    setUserActivityState(state, action: PayloadAction<SetUserActivityStatePayload>) {
      if (action.payload.state === UserActivityState.IDLE) {
        if (state.userActivityStarted !== null && state.userActivityStarted < action.payload.timestamp) {
          state.activeDuration += action.payload.timestamp - state.userActivityStarted
          state.userActivityStarted = null
        }
      } else if (action.payload.state === UserActivityState.ACTIVE) {
        if (state.userActivityStarted === null) {
          state.userActivityStarted = action.payload.timestamp
        }
      }
    },

    // Update from Firestore
    setSessionFromFirestore(state, action: PayloadAction<SetPredictionPayload>) {
      state.currentProposalID = action.payload.proposal.id
      state.isDirty = false
      state.userActivityStarted = action.payload.timestamp
      const metrics = action.payload.proposal.metadata.labeling_metrics
      state.activeDuration = (metrics?.active_time_us ?? 0) / 1000
      state.userActionCount = metrics?.action_count ?? 0

      state.spans = {}
      state.marks = {}

      let exerciseHashToAvailableExercisesIDLookup: Record<string, string> = {}
      const execution = action.payload.proposal.execution

      const blueprint = action.payload.proposal.blueprint
      if (blueprint) {
        try {
          state.blueprintYaml = YAML.stringify(blueprint, {
            sortMapEntries: true,
          })
        } catch (e) {
          console.log("failed to encode as yaml", e)
        }
      }

      action.payload.proposal.section_splits?.forEach(split => {
        state.marks[uuidv5(`split_${split}`, UUID_NAMESPACE)] = {
          type: "split",
          time: split / 1_000_000,
        }
      })
      state.exercises = {}

      execution.forEach((fireEx, idx) => {
        const exercise: Exercise = {
          type: fireEx.exercise.type,
          modifiers: fireEx.exercise.modifiers,
          equipment: fireEx.exercise.equipment?.map(fireEq => ({
            type: fireEq.type,
            measure: fireEq.measure,
          })),
        }
        const exHash = exerciseHash(exercise)
        if (!exerciseHashToAvailableExercisesIDLookup[exHash]) {
          const availExKey = uuidv5(`avail_ex_${exHash}`, UUID_NAMESPACE)
          state.exercises[availExKey] = exercise
          exerciseHashToAvailableExercisesIDLookup[exHash] = availExKey
        }

        state.spans[uuidv5(`ex_${idx}`, UUID_NAMESPACE)] = {
          ref: exerciseHashToAvailableExercisesIDLookup[exHash],
          begin: fireEx.time_start / 1000000,
          end: fireEx.time_end / 1000000,
          ignoredBy: fireEx.ignored_by,
        }

        if (fireEx.tags) {
          fireEx.tags.forEach(t => {
            state.marks[uuidv5(`mark_${t.time}`, UUID_NAMESPACE)] = {
              type: "rep",
              time: t.time / 1000000,
            }
          })
        }
      })
    },

    // Spans
    startSpan(state, action: PayloadAction<StartSpanActionPayload>) {
      const { id, ref } = action.payload

      // There can only be one open exercise at any time
      if (Object.values(state.spans).find(e => e.end === undefined) !== undefined) {
        return
      }

      // If the cursor is over a span, change the span type instead of creating a new span
      let markedSpan = Object.values(state.spans).find(e => e.begin <= state.graphCursor && e.end! > state.graphCursor)
      if (markedSpan !== undefined) {
        if (markedSpan.ref !== ref) {
          state.isDirty = true
        }

        markedSpan.ref = ref
        return
      }

      console.log(`starting span ${id}`)
      state.spans[id] = { begin: state.graphCursor, ref }
      state.isDirty = true
    },
    endSpan(state, action: PayloadAction<string>) {
      const id = action.payload

      console.log(`ending span ${id}`)
      state.spans[id].end = state.graphCursor
      state.isDirty = true
    },
    editSpan(state, action: PayloadAction<ExerciseUpdator>) {
      const { id, values } = action.payload

      if (values.begin) {
        state.spans[id].begin = values.begin
      }

      if (values.end) {
        state.spans[id].end = values.end
      }

      const updatedSpan = state.spans[id]
      const overlaps = Object.entries(state.spans)
        .filter(([k, _]) => k !== id)
        .filter(([_, s]) => s.begin < updatedSpan.end! && s.end! > updatedSpan.begin)
        .filter(([_, s]) => updatedSpan.ref === s.ref)

      if (overlaps.length > 0) {
        state.spans[id].begin = Math.min(updatedSpan.begin, ...overlaps.map(([_, s]) => s.begin))
        state.spans[id].end = Math.max(updatedSpan.end!, ...overlaps.map(([_, s]) => s.end!))
        overlaps.forEach(([k, _]) => delete state.spans[k])
      }

      Object.entries(state.spans)
        .filter(([_, s]) => s.begin > updatedSpan.begin && s.end! < updatedSpan.end!)
        .forEach(([k, _]) => delete state.spans[k])

      state.isDirty = true
    },
    ignoreHoverExercise(state, action: PayloadAction<string>) {
      const exEntry = Object.entries(state.spans).find(
        ([id, ex]) => ex.begin < state.graphCursor && (!ex.end || ex.end > state.graphCursor),
      )
      if (!exEntry) {
        return
      }
      const exID = exEntry[0]

      let set = new Set(state.spans[exID].ignoredBy)
      let ignoreType = action.payload
      if (set.has(ignoreType)) {
        set.delete(ignoreType)
      } else {
        set.add(ignoreType)
      }
      state.spans[exID].ignoredBy = Array.from(set)

      state.isDirty = true
    },
    removeHoverExercise(state, action: PayloadAction<void>) {
      const exEntry = Object.entries(state.spans).find(
        ([id, ex]) => ex.begin < state.graphCursor && (!ex.end || ex.end > state.graphCursor),
      )
      if (!exEntry) {
        return
      }

      const exID = exEntry[0]
      delete state.spans[exID]
      state.isDirty = true
    },
    changeHoverExerciseToNextExerciseType(state, action: PayloadAction<void>) {
      const exEntry = Object.entries(state.spans).find(
        ([id, ex]) => ex.begin < state.graphCursor && (!ex.end || ex.end > state.graphCursor),
      )
      if (!exEntry) {
        return
      }

      const [exID, ex] = exEntry

      const availableExerciseRefs = Object.keys(state.exercises)
      const curTypeIdx = availableExerciseRefs.indexOf(ex.ref)
      const newTypeIdx = (curTypeIdx + 1) % availableExerciseRefs.length

      state.spans[exID].ref = availableExerciseRefs[newTypeIdx]
      state.isDirty = true
    },

    // Repetitions
    addRep(state, action: PayloadAction<string>) {
      const id = action.payload
      state.marks[id] = {
        type: "rep",
        time: state.graphCursor,
      }
      state.isDirty = true
    },
    addReps(state, action: PayloadAction<RepDescriptor[]>) {
      action.payload.forEach(({ id, reptime }) => (state.marks[id] = { type: "rep", time: reptime }))
      state.isDirty = true
    },
    editRep(state, action: PayloadAction<RepDescriptor>) {
      const { id, reptime } = action.payload
      state.marks[id].time = reptime
      state.isDirty = true
    },
    addRepsEvenly(state, action: PayloadAction<number>) {
      const repCount = action.payload
      const selectedSpanId = _.findKey(
        state.spans,
        span => !!(span.end && span.begin < state.graphCursor && span.end >= state.graphCursor),
      )

      if (!selectedSpanId) {
        return
      }
      const { begin, end } = state.spans[selectedSpanId]

      Object.entries(state.marks)
        .filter(([id, m]) => m.time > begin && m.time < end!)
        .forEach(([id, time]) => delete state.marks[id])

      let repDist = (end! - begin) / repCount
      for (let idx = 0.5; idx <= repCount; idx++) {
        state.marks[uuidv5(`rep_${selectedSpanId}_${idx}`, UUID_NAMESPACE)] = {
          type: "rep",
          time: begin + repDist * idx,
        }
      }

      state.isDirty = true
    },

    removeRep(state) {
      const allReps = Object.entries(state.marks)

      const closestRep = allReps.reduce((closest, rep) => {
        const currentRepDist = Math.abs(rep[1].time - state.graphCursor)
        const closestRepDist = Math.abs(closest[1].time - state.graphCursor)
        return currentRepDist < closestRepDist ? rep : closest
      })

      const closestDist = Math.abs(closestRep[1].time - state.graphCursor)
      if (closestDist < 1.2) {
        delete state.marks[closestRep[0]]
      }
      state.isDirty = true
    },

    removeRepsBetween(state, action: PayloadAction<TimeSpan>) {
      const { begin, end } = action.payload
      Object.entries(state.marks)
        .filter(([id, m]) => m.time > begin && m.time < end)
        .forEach(([id, time]) => delete state.marks[id])
      state.isDirty = true
    },

    insertSplit(state, action: PayloadAction<string>) {
      const id = action.payload
      state.marks[id] = { type: "split", time: state.graphCursor }
      state.isDirty = true
    },

    // Exercises
    setExercise(state, action: PayloadAction<SetExercisePayload>) {
      state.exercises[action.payload.id] = action.payload.exercise
      state.isDirty = true
    },
    // Removed an exercise and all spans of it's type
    removeExerciseType(state, action: PayloadAction<string>) {
      Object.entries(state.spans)
        .filter(([id, span]) => span.ref === action.payload)
        .forEach(([id, e]) => delete state.spans[id])
      delete state.exercises[action.payload]
      state.isDirty = true
    },

    // Graph Cursor
    setGraphCursor(state, action: PayloadAction<number>) {
      state.graphCursor = action.payload
    },

    // Graph Saved
    unsetDirtyBit(state, action: PayloadAction<void>) {
      state.isDirty = false
    },

    setCurrentProposalID(state, action: PayloadAction<string>) {
      state.currentProposalID = action.payload
    },

    setEnabledGraphAxesForType(state, action: PayloadAction<SetEnabledAxesPayload>) {
      state.enabledGraphAxes[action.payload.sensorType] = action.payload.axes
    },

    graphClicked(state, action: PayloadAction<number>) {
      state.graphClickedTime = action.payload
    },

    setVisibleDataSources(state, action: PayloadAction<number[]>) {
      state.visibleDataSources = action.payload
    },

    setBlueprintYaml(state, action: PayloadAction<string>) {
      state.blueprintYaml = action.payload
    },
    setOverlayProposal(state, action: PayloadAction<OverlayProposal>) {
      state.overlayProposal = action.payload
    },
    copyPartFromOverlay(state, action: PayloadAction<void>) {
      if (!state.overlayProposal) {
        return
      }
      const overlayExecution = state.overlayProposal.execution
      // find part from overlay
      const cursorMs = state.graphCursor * 1_000_000
      const part = overlayExecution.find(p => p.time_start < cursorMs && p.time_end > cursorMs)
      if (!part) {
        return
      }

      const exerciseType = part.exercise.type

      // find ref for exercise type
      const entry = Object.entries(state.exercises).find(([id, ex]) => ex.type === exerciseType)
      let ref = entry?.[0]
      if (!ref) {
        // find previously copied part of the same type
        ref = uuidv5(`overlay_ex_${exerciseType}`, UUID_NAMESPACE)
        if (!state.exercises[ref]) {
          // create new exercise
          state.exercises[ref] = { type: exerciseType }
        }
      }

      // create span
      const spanId = uuidv5(`overlay_part_${part.time_start}_${ref}`, UUID_NAMESPACE)
      state.spans[spanId] = {
        ref: ref,
        begin: part.time_start / 1_000_000,
        end: part.time_end / 1_000_000,
      }

      // marks
      if (part.tags) {
        part.tags.forEach(t => {
          state.marks[uuidv5(`mark_${t.time}`, UUID_NAMESPACE)] = {
            type: "rep",
            time: t.time / 1000000,
          }
        })
      }

      state.isDirty = true
    },

    // Acceleration graph controls
    setAccelerationGraphZoom(state, action: PayloadAction<number>) {
      state.accelerationGraph.zoom = action.payload
    },
    setAccelerationGraphShowAxis(state, action: PayloadAction<[number, boolean]>) {
      let [axis, isOn] = action.payload
      state.accelerationGraph.showAxis[axis] = isOn
    },

    // Cutting
    setCuts(state, action: PayloadAction<Cut[]>) {
      state.cuts = action.payload
    },

    // Range mode
    setRangeMode(state, action: PayloadAction<RangeMode>) {
      state.rangeMode = action.payload
    },
  },
})

export const {
  setUserActivityState,
  setSessionFromFirestore,

  startSpan,
  endSpan,
  editSpan,

  ignoreHoverExercise,
  removeHoverExercise,
  changeHoverExerciseToNextExerciseType,

  addRep,
  addReps,
  editRep,
  removeRep,
  removeRepsBetween,
  insertSplit,
  addRepsEvenly,

  setExercise,
  removeExerciseType,

  setGraphCursor,
  setEnabledGraphAxesForType,

  graphClicked,

  setVisibleDataSources,

  unsetDirtyBit,
  setCurrentProposalID,

  setBlueprintYaml,

  setOverlayProposal,
  copyPartFromOverlay,

  setAccelerationGraphZoom,
  setAccelerationGraphShowAxis,

  setCuts,
  setRangeMode,
} = taggingSessionSlice.actions

export default taggingSessionSlice.reducer
export type reducerT = ReturnType<typeof taggingSessionSlice.reducer>
