import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EquipmentDefinitions, ExerciseDefinitions } from "../../services/firebase/firestore/types"

interface Definitions {
  exercises: ExerciseDefinitions
  equipment: EquipmentDefinitions
}

const definitionsSlice = createSlice({
  name: "definitions",
  initialState: {
    exercises: {},
    equipment: {},
  } as Definitions,
  reducers: {
    setExercises(state, action: PayloadAction<ExerciseDefinitions>) {
      state.exercises = action.payload
      state.exercises["UNKNOWN"] = {
        id: "UNKNOWN",
        name: "Unknown",
      }
    },

    setEquipment(state, action: PayloadAction<EquipmentDefinitions>) {
      state.equipment = action.payload
    },
  },
})

export const { setEquipment, setExercises } = definitionsSlice.actions
export default definitionsSlice.reducer
