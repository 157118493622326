import { useSelector } from "react-redux"
import { RootState } from "../store"
import UserFlag from "./UserFlag"
import dayjs from "dayjs"

interface OwnProps {
  userID?: string
}

function UserInfo({ userID }: OwnProps) {
  const username = useSelector((state: RootState) => {
    const user = state.users.users[userID ?? ""] ?? {}
    return user.name || (userID && userID.substring(0, 8)) || "Unknown"
  })

  const gender = useSelector((state: RootState) => userID && state.users.users[userID]?.gender)
  const age = useSelector((state: RootState) => {
    const user = state.users.users[userID ?? ""] ?? {}
    return user.date_of_birth && dayjs().diff(dayjs(user.date_of_birth?.toDate()), "y")
  })

  let genderSymbol = ""
  if (gender === "female") {
    genderSymbol = "F"
  } else if (gender === "male") {
    genderSymbol = "M"
  }

  return (
    <span>
      <UserFlag userID={userID} /> {genderSymbol}
      {age} {username}
    </span>
  )
}

export default UserInfo
