import React from "react"
import { RootState } from "../../store"
import { useSelector } from "react-redux"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import { Theme, Typography } from "@mui/material"
import _ from "lodash"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    truncate: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  }),
)

interface OwnProps {
  userID?: string
}

function UserData({ userID }: OwnProps) {
  const classes = useStyles()
  const users = useSelector((state: RootState) => state.users.users)
  const fullName = _.get(users, [userID || "", "display_name"], userID)

  return (
    <Typography variant="body1" className={classes.truncate}>
      {fullName}
    </Typography>
  )
}

export default UserData
