import { Comment } from "../../services/firebase/firestore/types/comments"
import { useCallback, useState } from "react"
import Comments from "./Comments"
import { FirestoreService } from "../../services/firebase"
import { useUserCommentsListener } from "../../hooks/firestore-listener"
import { Typography } from "@mui/material"
import { Box, Fab, Tooltip } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

interface OwnProps {
  userID: string
}

function UserComments({ userID }: OwnProps) {
  const comments = useUserCommentsListener(userID)

  const addComment = useCallback(
    async (comment: Comment) => {
      await FirestoreService.addUserComment(userID, comment)
    },
    [userID],
  )

  const deleteComment = useCallback(
    async (c: Comment) => {
      await FirestoreService.deleteUserComment(userID, c.id!)
    },
    [userID],
  )

  const [showCommentInput, setShowCommentInput] = useState<boolean>(false)
  const addCommentButtonAction = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => setShowCommentInput(v => !v),
    [setShowCommentInput],
  )

  return (
    <>
      <Box display="flex" mb={1}>
        <Box flexGrow={1}>
          <Typography variant="h1">User Comments</Typography>
        </Box>
        <Tooltip title="Show input" placement="top">
          <Fab
            size="small"
            color="primary"
            sx={{
              maxWidth: 24,
              maxHeight: 24,
              minWidth: 24,
              minHeight: 24,
              marginLeft: 2,
            }}
            onClick={addCommentButtonAction}
          >
            {showCommentInput ? (
              <RemoveIcon sx={{ width: 14, height: 14 }} />
            ) : (
              <AddIcon sx={{ width: 14, height: 14 }} />
            )}
          </Fab>
        </Tooltip>
      </Box>
      <Comments
        comments={comments}
        showInput={showCommentInput}
        onAddComment={addComment}
        onDeleteComment={deleteComment}
      />
    </>
  )
}

export default UserComments
