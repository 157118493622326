import firebase from "firebase/compat/app"

export interface TaggingIndexScribe {
  id: string
  proposal: string
  parts: number[]
}

export interface TaggingIndex {
  name: string
  counts: Record<string, number>
  secs: Record<string, number>
}

export const taggingIndexDocFirestoreConverter = {
  toFirestore(taggingIndex: TaggingIndex): firebase.firestore.DocumentData {
    throw Error("Not implemented!")
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): TaggingIndex {
    const data = snapshot.data(options)
    return {
      name: data["name"],
      counts: data["counts"],
      secs: data["secs"],
    }
  },
}

export const taggingIndexScribeDocFirestoreConverter = {
  toFirestore(taggingIndex: TaggingIndexScribe): firebase.firestore.DocumentData {
    throw Error("Not implemented!")
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): TaggingIndexScribe {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      proposal: data["proposal"],
      parts: data["parts"],
    }
  },
}
