import { Cut } from "../pixi/utils"

export function removeCuts(t: number, cuts: Cut[]): number {
  let toRemove = 0
  for (const cut of cuts) {
    if (t > cut.time_end) {
      toRemove += cut.time_end - cut.time_start
    } else if (t > cut.time_start) {
      toRemove += t - cut.time_start
    }
  }
  return t - toRemove
}

export function addCuts(t: number, cuts: Cut[]): number {
  let toAdd = 0
  for (const cut of cuts) {
    if (t + toAdd > cut.time_start) {
      toAdd += cut.time_end - cut.time_start
    }
  }
  return t + toAdd
}
