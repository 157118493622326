import React from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { RootState } from "../../../store"
import { setGraphCursor } from "../../../store/session/reducer"
import { Theme } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import createStyles from "@mui/styles/createStyles"
import * as PIXI from "pixi.js"
import _ from "lodash"
import Marker, { SimpleMarker } from "../../../pixi/marker"
import { defaultColors, drawLines } from "../../../pixi/lines"
import { DATA_FREQUENCY } from "../../../utils/constants"

const MINIMAP_HEIGHT: number = 30
const PADDING: number = 1
const STROKE_WIDTH: number = 1.0
const BACKGROUND_COLOR = 0x000000
const VIEWPORT_COLOR = 0x909090

const styles = (theme: Theme) =>
  createStyles({
    graph_overlay: {
      backgroundColor: theme.palette.grey[800],
      position: "absolute",
      width: "calc(100vw - 268px - 268px)",
      left: 268,
      height: MINIMAP_HEIGHT,
    },
    placeholder: {
      height: MINIMAP_HEIGHT,
    },
  })

class Minimap extends React.PureComponent<any> {
  pixiref: React.RefObject<HTMLCanvasElement>
  pixiparentref: React.RefObject<HTMLDivElement>
  pixiapp?: PIXI.Application
  viewport?: Marker<number>
  downsamplingFactor: number

  constructor(props: any) {
    super(props)

    this.pixiref = React.createRef()
    this.pixiparentref = React.createRef()
    this.downsamplingFactor = 1

    this.redraw = this.redraw.bind(this)
  }

  updateGraphCursorRedux = _.throttle((pos: number) => this.props.setGraphCursor(pos), 200)

  componentDidMount() {
    this.pixiapp = new PIXI.Application({
      antialias: true,
      view: this.pixiref.current || undefined,
      resizeTo: this.pixiparentref.current || undefined,
      resolution: 2,
      autoDensity: true,
    })

    this.redraw()

    this.handleResize = _.debounce(this.handleResize.bind(this), 500)
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  componentDidUpdate() {
    if (this.viewport) {
      const pos = this.toCoord(this.props.graphCursorPos)
      this.viewport.update(pos)
    }
  }

  toCoord(x: number): number {
    return (x * DATA_FREQUENCY) / this.downsamplingFactor
  }

  toTime(x: number): number {
    return (x * this.downsamplingFactor) / DATA_FREQUENCY
  }

  handleResize() {
    this.redraw()
  }

  redraw() {
    if (this.pixiapp === null) {
      return
    }

    const sensors: number[][] = this.props.sensors
    const width = this.pixiapp!.screen.width
    this.downsamplingFactor = Math.max(1, Math.floor(sensors[0].length / width))
    const culledSensors = sensors.map(axis => axis.filter((v, idx) => idx % this.downsamplingFactor === 0))

    const viewportWidth = width / this.downsamplingFactor
    this.viewport = new SimpleMarker({
      interactionmanager: this.pixiapp!.renderer.plugins.interaction,
      initpos: this.toCoord(this.props.graphCursorPos),
      toPos: t => t,
      create: () =>
        new PIXI.Graphics()
          .lineStyle(1.0, VIEWPORT_COLOR)
          .beginFill(VIEWPORT_COLOR)
          .drawRect(-viewportWidth / 2, 0, viewportWidth, MINIMAP_HEIGHT)
          .endFill(),
      onDragEnd: (pos: number) => this.updateGraphCursorRedux(this.toTime(pos)),
      onDragMove: (pos: number) => this.updateGraphCursorRedux(this.toTime(pos)),
    })

    const container = new PIXI.Graphics()
      .beginFill(BACKGROUND_COLOR)
      .drawRect(0, 0, width, MINIMAP_HEIGHT)
      .endFill()
      .on("click", (e: PIXI.InteractionEvent) => this.updateGraphCursorRedux(this.toTime(e.data.global.x)))
    container.interactive = true
    container.name = "minimap"

    const lines = drawLines(culledSensors, MINIMAP_HEIGHT, PADDING, STROKE_WIDTH, defaultColors)
    lines.cacheAsBitmap = true
    container.addChild(this.viewport.displayobject, lines)

    this.pixiapp!.stage.removeChildren()
    this.pixiapp!.stage.addChild(container)

    this.pixiapp!.stage.setTransform(0, 0, width / culledSensors[0].length, 1)
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.placeholder}>
        <div ref={this.pixiparentref} className={classes.graph_overlay}>
          <canvas ref={this.pixiref}></canvas>
        </div>
      </div>
    )
  }
}

const wrapper = compose(
  connect(
    (state: RootState) => ({
      graphCursorPos: state.currentTaggingSession.graphCursor,
    }),
    {
      setGraphCursor,
    },
  ),
)

export default wrapper(withStyles(styles)(Minimap))
