import * as PIXI from "pixi.js"
import _ from "lodash"

const COLOR_PALETTE: string[] = [
  "#2b3252", // Blue
  "#ef5455", // Red
  "#197a43", // Green
  "#914781",
  "#5c3c8f",
  "#915847",
  "#917c47",
  "#5dbe68",
  "#a4be5d",
  "#5d7dbe",
  "#be5db4",
  "#d66969",
  "#d69869",
  "#d6ae69",
  "#b0d669",
  "#69d6a7",
  "#ff6666",
  "#ffde66",
  "#66ff6e",
  "#c466ff",
  "#69e5e9",
  "#433a3a",
]

export const defaultColors = (idx: number) => COLOR_PALETTE[idx % COLOR_PALETTE.length]

export function drawLines(
  data: number[][],
  height: number,
  padding: number,
  strokeWidth: number,
  colorIndex: (idx: number) => string,
  compression: number = 1,
): PIXI.Graphics {
  const gfx = new PIXI.Graphics()
  makePolygons(data, height, padding, compression).forEach((polygon, axis) => {
    gfx.lineStyle(strokeWidth, PIXI.utils.string2hex(colorIndex(axis))).drawPolygon(polygon)
  })
  return gfx
}

export function drawLine(
  data: number[],
  height: number,
  padding: number,
  strokeWidth: number,
  color: string,
  compression: number = 1,
): PIXI.Graphics {
  const gfx = new PIXI.Graphics()
  const polygon = makePolygon(data, height, padding, compression)
  gfx.lineStyle(strokeWidth, PIXI.utils.string2hex(color)).drawPolygon(polygon)
  return gfx
}

function makePolygon(sensordata: number[], height: number, padding: number, compression: number): PIXI.Polygon {
  const polygondata = sensordata
    // Stretch values to match graph height
    .map(p => p * (height - 2 * padding))
    // Build polygon data
    .flatMap((p, idx) => [idx * compression!, p])
  const polygon = new PIXI.Polygon(polygondata)
  polygon.closeStroke = false
  return polygon
}

function makePolygons(sensordata: number[][], height: number, padding: number, compression: number): PIXI.Polygon[] {
  return sensordata.map(axis => {
    return makePolygon(axis, height, padding, compression)
  })
}
