import { Scribe } from "../../services/firebase/firestore/types"
import { Box, Button, Checkbox, Chip, FormControlLabel, TextField } from "@mui/material"
import { Autocomplete } from "@mui/material"
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react"
import { FirestoreService } from "../../services/firebase"
import _ from "lodash"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { KnownTags } from "../../utils/tags"

interface OwnProps {
  scribe: Scribe
}

function ScribeTags({ scribe }: OwnProps) {
  const [values, setValues] = useState<string[]>([])
  const [dirty, setDirty] = useState(false)

  const onChange = useCallback(
    (e: any, newValue: string[]) => {
      setValues(newValue)
      const equal = _.isEqual(new Set(newValue), new Set(scribe.tags))
      setDirty(!equal)
    },
    [setValues, setDirty, scribe],
  )

  const onChangeIsTest = useCallback(
    (e: any, isChecked: boolean) => {
      FirestoreService.setIsTest(scribe.id, isChecked).catch(e => console.log(e))
    },
    [scribe],
  )

  const reset = useCallback(() => {
    setValues(scribe.tags || [])
    setDirty(false)
  }, [setValues, setDirty, scribe])

  const save = useCallback(() => {
    FirestoreService.setTags(scribe.id, values).then(() => setDirty(false))
  }, [values, scribe])

  const options = useMemo(() => {
    let all = new Set(Object.keys(KnownTags))

    scribe.tags?.forEach(t => all.add(t))

    return Array.from(all)
  }, [scribe])

  useEffect(() => {
    if (dirty) {
      return
    }
    setValues(scribe.tags || [])
  }, [dirty, scribe])

  return (
    <Box>
      <FormControlLabel control={<Checkbox checked={scribe.is_test} onChange={onChangeIsTest} />} label="Is Test" />
      <Autocomplete
        multiple
        freeSolo
        disableClearable
        disableCloseOnSelect
        size="small"
        value={values}
        onChange={onChange}
        options={options}
        getOptionLabel={option => KnownTags[option]?.title || option}
        getOptionDisabled={option => KnownTags[option]?.ro || false}
        onKeyDown={e => e.stopPropagation()}
        renderOption={(props, option, { selected }) => {
          const known = KnownTags[option]
          const label = known?.title || option
          let style: CSSProperties = {}
          let checkboxStyle: CSSProperties = { marginRight: 8 }
          if (known?.color) {
            style.backgroundColor = known.color
            checkboxStyle.color = known.color
          }

          return (
            <li {...props}>
              <Checkbox color="default" checked={selected} style={checkboxStyle} />
              <span style={style}>{label}</span>
            </li>
          )
        }}
        renderTags={(value, getTagProps) => {
          return value.map((option: string, index) => {
            const known = KnownTags[option]
            const label = known?.title || option
            let style: CSSProperties = {}
            if (known?.color) {
              style.backgroundColor = known.color
            }

            const onClick = known?.link
              ? () => {
                  window.open(known.link, "_blank")?.focus()
                }
              : undefined

            return (
              <Chip
                {...getTagProps({ index })}
                disabled={known?.ro || false}
                onClick={onClick}
                icon={known?.link ? <OpenInNewIcon /> : undefined}
                size="small"
                style={style}
                label={label}
              />
            )
          })
        }}
        renderInput={params => <TextField variant="standard" {...params} />}
      />
      <Box display="flex" mt={3}>
        <Box flexGrow={1}>
          <Button size="small" variant="contained" onClick={reset} disabled={!dirty}>
            Reset
          </Button>
        </Box>
        <Box>
          <Button size="small" variant="contained" color="primary" onClick={save} disabled={!dirty}>
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ScribeTags
