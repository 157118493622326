import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import { Typography } from "@mui/material"
import dayjs from "dayjs"

type OwnProps = {}

function ActivityClock(props: OwnProps) {
  const userActivityStarted = useSelector((state: RootState) => state.currentTaggingSession.userActivityStarted)
  const activeDuration = useSelector((state: RootState) => state.currentTaggingSession.activeDuration)

  const [, setCount] = React.useState(0)
  const requestRef = useRef<number>()

  useEffect(() => {
    if (userActivityStarted === null) {
      return () => {}
    }

    requestRef.current = window.requestAnimationFrame(update)
    function update(t: number) {
      setCount(v => (v + 1) % 42) // Just change it to force a rerender
      requestRef.current = window.requestAnimationFrame(update)
    }

    return () => {
      cancelAnimationFrame(requestRef.current!)
    }
  }, [setCount, userActivityStarted])

  let activeTime = activeDuration
  if (userActivityStarted !== null) {
    activeTime += dayjs().valueOf() - userActivityStarted
  }

  return (
    <div>
      <Typography>
        {userActivityStarted === null ? "IDLE" : "ACTIVE"} {formatTimeSeconds(activeTime)}
      </Typography>
    </div>
  )
}

function formatTimeSeconds(ms: number): string {
  const s = ms / 1000
  return `${Math.floor(s / 60)}:${String(Math.floor(s % 60)).padStart(2, "0")}:${String(ms % 1000).padStart(3, "0")}`
}

export default ActivityClock
