import React from "react"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import { connect } from "react-redux"
import { compose } from "redux"
import { RootState } from "../../../store"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import CloseIcon from "@mui/icons-material/Close"
import { addReps, removeRepsBetween } from "../../../store/session/reducer"
import { v4 as uuidv4 } from "uuid"
import { selectSpanUnderCursor } from "../../../store/session/selectors"
import { DATA_FREQUENCY } from "../../../utils/constants"

enum Direction {
  Up,
  Down,
}

interface OwnProps {
  state: RootState
  addReps: any
  removeRepsBetween: any
  sensordata: { [sensorType: string]: number[][] }
}

class Count extends React.Component<OwnProps> {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <>
        <Typography component="span">Count:</Typography>

        <IconButton
          style={{ color: "#2B3252", fontWeight: "bold" }}
          size="small"
          onClick={() => this.counting(0, Direction.Up)}
        >
          <ArrowDownwardIcon fontSize="inherit" />
        </IconButton>

        <IconButton
          style={{ color: "#2B3252", fontWeight: "bold" }}
          size="small"
          onClick={() => this.counting(0, Direction.Down)}
        >
          <ArrowUpwardIcon fontSize="inherit" />
        </IconButton>

        <IconButton
          style={{ color: "#EF5455", fontWeight: "bold" }}
          size="small"
          onClick={() => this.counting(1, Direction.Up)}
        >
          <ArrowDownwardIcon fontSize="inherit" />
        </IconButton>

        <IconButton
          style={{ color: "#EF5455", fontWeight: "bold" }}
          size="small"
          onClick={() => this.counting(1, Direction.Down)}
        >
          <ArrowUpwardIcon fontSize="inherit" />
        </IconButton>

        <IconButton
          style={{ color: "#197A43", fontWeight: "bold" }}
          size="small"
          onClick={() => this.counting(2, Direction.Up)}
        >
          <ArrowDownwardIcon fontSize="inherit" />
        </IconButton>

        <IconButton
          style={{ color: "#197A43", fontWeight: "bold" }}
          size="small"
          onClick={() => this.counting(2, Direction.Down)}
        >
          <ArrowUpwardIcon fontSize="inherit" />
        </IconButton>

        <IconButton
          style={{ fontWeight: "bold" }}
          size="small"
          onClick={() => {
            const ispan = selectSpanUnderCursor(this.props.state)
            if (ispan) {
              this.props.removeRepsBetween({
                begin: ispan.begin,
                end: ispan.end,
              })
            }
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </>
    )
  }

  counting(axis: number, direction: Direction) {
    const ispan = selectSpanUnderCursor(this.props.state)

    if (!ispan) {
      return
    }

    let sensorChunk = this.props.sensordata["Gravity"][axis].slice(
      ispan.begin * DATA_FREQUENCY,
      ispan.end! * DATA_FREQUENCY,
    )

    const maxval = Math.max(...sensorChunk)
    const minval = Math.min(...sensorChunk)

    if (direction === Direction.Down) {
      sensorChunk = sensorChunk.map(v => maxval - (v - minval))
    }

    const upperbound = minval + (maxval - minval) * 0.6
    const lowerbound = minval + (maxval - minval) * 0.4

    let loopstate: any = {
      shouldCount: sensorChunk[0] > upperbound,
      peakval: sensorChunk[0],
      peaktime: ispan.begin,
      peaks: [],
    }

    sensorChunk.push(Number.NEGATIVE_INFINITY)
    sensorChunk.forEach((v, i) => {
      if (v < lowerbound && loopstate.shouldCount) {
        loopstate.peaks.push({
          id: uuidv4(),
          reptime: ispan.begin + loopstate.peaktime / DATA_FREQUENCY,
        })
        loopstate.shouldCount = false
        loopstate.peakval = upperbound
      }

      if (v > upperbound) {
        loopstate.shouldCount = true

        if (v > loopstate.peakval) {
          loopstate.peakval = v
          loopstate.peaktime = i
        }
      }
    })

    if (ispan.exercise.def.id === "DOUBLEUNDER") {
      loopstate.peaks = loopstate.peaks.reduce((acc: any[], v: any) => {
        if (acc.length === 0 || v.reptime > acc[acc.length - 1].reptime + 0.4) {
          acc.push(v)
        }

        return acc
      }, [])
    }

    this.props.removeRepsBetween({ begin: ispan.begin, end: ispan.end })
    this.props.addReps(loopstate.peaks)
  }
}

const wrapper = compose(
  connect(
    (state: RootState) => ({
      state,
    }),
    {
      addReps,
      removeRepsBetween,
    },
  ),
)

export default wrapper(Count)
