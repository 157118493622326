import React, { useContext, useEffect, useState } from "react"
import { BrowserRouter as Router, Link, Navigate, Route, Routes } from "react-router-dom"
import "@fontsource/rubik/300.css"
import "@fontsource/rubik/400.css"
import "@fontsource/rubik/500.css"

import AppBar from "@mui/material/AppBar"
import Button from "@mui/material/Button"
import Toolbar from "@mui/material/Toolbar"
import Box from "@mui/material/Box"
import { createTheme, CssBaseline, StyledEngineProvider, ThemeProvider, Typography } from "@mui/material"

import Login from "./login/Login"
import ScribeContainer from "./scribe/ScribeContainer"
import ScribeStats from "./stats/ScribeStats"
import firebase from "firebase/compat/app"
import UserContext from "../context/user-context"
import { useDispatch } from "react-redux"
import { setEquipment, setExercises } from "../store/definitions/reducer"
import { setUsers } from "../store/users-reducer"
import { FirestoreService } from "../services/firebase"
import UserList from "./users/UserList"
import Scribes from "./scribes/Scribes"
import ScribesByTag from "./scribes/tabs/ScribesByTag"
import ScribesByUser from "./scribes/tabs/ScribesByUser"
import ScribesByExercise from "./scribes/tabs/ScribesByExercise"
import SpeedTaggingContainer from "./speedtagging/SpeedTaggingContainer"
import VerifyExerciseList from "./verify/VerifyExerciseList"
import Verify from "./verify/Verify"
import Exp from "./Exp"
import { HotkeysProvider } from "react-hotkeys-hook"

export const theme = createTheme({
  spacing: 4,
  typography: {
    fontFamily: "Rubik, Arial, sans-serif",
    h1: {
      fontSize: "20px",
      fontWeight: 500,
    },
    h2: {
      fontSize: "18px",
      fontWeight: 400,
    },
    body1: {
      fontSize: "12px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "12px",
      fontWeight: 300,
    },
  },
})

interface MenuButtonProps {
  to: string
  children: any
}

const MenuButton = ({ to, children }: MenuButtonProps) => (
  <Button sx={{ marginLeft: 3, marginRight: 5 }} color="inherit" component={Link} to={to}>
    {children}
  </Button>
)

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    const [exercises, equipment] = FirestoreService.fetchDefinitions()
    exercises.then(v => dispatch(setExercises(v)))
    equipment.then(v => dispatch(setEquipment(v)))
  }, [dispatch])

  useEffect(() => {
    FirestoreService.fetchUsers().then(v => dispatch(setUsers(v)))
  }, [dispatch])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <HotkeysProvider>
          <CssBaseline />
          <Router>
            <AppBar position="fixed" sx={{ zIndex: 1201 }}>
              <Toolbar>
                <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
                  Wodscribe labeling
                </Typography>
                <MenuButton to="/">Home</MenuButton>
                <MenuButton to="/speed">Speed</MenuButton>
                <MenuButton to="/users">Users</MenuButton>
                <MenuButton to="/stats">Stats</MenuButton>
                <MenuButton to="/verify-exercises">Verify</MenuButton>
              </Toolbar>
            </AppBar>

            <Box
              sx={{
                flexGrow: 1,
                maxWidth: "80%",
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: 3,
              }}
            >
              <Toolbar />
              <WaitForAuth>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/scribe/:id" element={<AuthRedirect wrappedComponent={ScribeContainer} />} />
                  <Route path="/stats" element={<AuthRedirect wrappedComponent={ScribeStats} />} />
                  <Route path="/verify-exercises" element={<AuthRedirect wrappedComponent={VerifyExerciseList} />} />
                  <Route path="/verify/:type/:cls" element={<AuthRedirect wrappedComponent={Verify} />} />
                  <Route path="/scribes" element={<AuthRedirect wrappedComponent={Scribes} />}>
                    <Route index element={<Navigate to="bytag" />} />
                    <Route path="bytag" element={<ScribesByTag />} />
                    <Route path="byuser" element={<ScribesByUser />} />
                    <Route path="byexercise" element={<ScribesByExercise />} />
                  </Route>
                  <Route path="/exp" element={<AuthRedirect wrappedComponent={Exp} />} />
                  <Route path="/users" element={<AuthRedirect wrappedComponent={UserList} />} />
                  <Route path="/speed" element={<AuthRedirect wrappedComponent={SpeedTaggingContainer} />} />
                  <Route path="/" element={<Navigate replace to="/scribes/bytag?tag=needs_operator_attention" />} />
                </Routes>
              </WaitForAuth>
            </Box>
          </Router>
        </HotkeysProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

function WaitForAuth({ children }: any) {
  const [user, setUser] = useState<firebase.User | null | undefined>(undefined)
  useEffect(() => firebase.auth().onAuthStateChanged(user => setUser(user)), [])

  if (user === undefined) {
    return null
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

function AuthRedirect({ wrappedComponent, ...passThroughProps }: any) {
  const user = useContext(UserContext)

  if (user === null) {
    return <Navigate to="/login" replace />
  }

  const ChildComp = wrappedComponent
  return <ChildComp {...passThroughProps} />
}

export default App
