import { Box, Button, Divider, TextField, Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { resetUserSettings, updateUserSetting, UserSettingsUpdate } from "../../store/user-settings/reducer"
import { selectUserSettings } from "../../store/user-settings/selectors"

function VerifySidebar() {
  const dispatch = useAppDispatch()

  const settings = useAppSelector(selectUserSettings)

  const updateSettings = useCallback(
    (update: UserSettingsUpdate) => {
      dispatch(updateUserSetting(update))
    },
    [dispatch],
  )

  const onReset = useCallback(() => {
    dispatch(resetUserSettings())
  }, [dispatch])

  return (
    <Box>
      <Box m={2}>
        <Typography variant="h4">Options</Typography>
        <Typography>These settings are saved in the browser</Typography>
      </Box>
      <Box mt={4} mb={6}>
        <Divider />
      </Box>
      <Box m={2}>
        <TextField
          value={settings.bumpSize}
          onChange={e => updateSettings({ bumpSize: Number(e.target.value) })}
          size="small"
          label="Bump Size"
          helperText="How much cursor keys moves the span"
          variant="outlined"
          type="number"
        />
      </Box>
      <Box m={2}>
        <TextField
          value={settings.loadSize}
          onChange={e => updateSettings({ loadSize: Number(e.target.value) })}
          label="Load size"
          helperText="How many parts to load"
          size="small"
          type="number"
          variant="outlined"
        />
      </Box>
      <Box m={2}>
        <Button size="small" onClick={onReset} color="error" variant="contained">
          Reset to default
        </Button>
      </Box>
    </Box>
  )
}

export default VerifySidebar
