import { useParams } from "react-router-dom"
import { Alert, Box, Button, Drawer, Grid, Paper, Typography } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { FirestoreService } from "../../services/firebase"
import VerifyGraphs from "./VerifyGraphs"
import CircularProgress from "@mui/material/CircularProgress"
import { PartInfo, partKey } from "../../store/types/verify"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { fetchPart, jumpToPart, reset, setEphemeralExercises, verifyPartCanSave } from "../../store/verify/reducer"
import _ from "lodash"
import VerifySaveButton from "./VerifySaveButton"
import VerifyPartDataCard from "./VerifyPartDataCard"
import VerifyActions from "./VerifyActions"
import VerifyExerciseSelector from "./VerifyExerciseSelector"
import { selectCursorPart, selectDirty, selectInflight, selectParts } from "../../store/verify/selectors"
import VerifySidebar from "./VerifySidebar"
import { Exercise, exerciseKey } from "../../store/types"
import { v5 as uuidv5 } from "uuid"
import { selectUserSettings } from "../../store/user-settings/selectors"

const UUID_NAMESPACE = "915210b6-90e8-45d7-96d5-2c47e9191bbc"

export default function Verify() {
  const dispatch = useAppDispatch()

  const { type, cls } = useParams()

  const [exerciseParts, setExerciseParts] = useState<PartInfo[] | null>(null)
  const [error, setError] = useState<string>("")
  const [showSidebar, setShowSidebar] = useState(false)

  const parts = useAppSelector(selectParts)
  const inflight = useAppSelector(selectInflight)
  const dirty = useAppSelector(selectDirty)
  const currentPart = useAppSelector(selectCursorPart)
  const minLoadSize = useAppSelector(selectUserSettings).loadSize

  const loading = inflight.length > 0

  const requestPart = useCallback(
    (info: PartInfo) => {
      if (parts.find(v => _.isEqual(v.info, info))) {
        console.log("skip existing", info)
        return
      }
      console.log("fetch", info)
      dispatch(fetchPart(info))
    },
    [parts, dispatch],
  )

  useEffect(() => {
    // Part Data loading
    if (!exerciseParts) {
      return
    }
    if (exerciseParts.length === 0) {
      console.warn("nothing to load")
      return
    }
    if (parts.length === 0 && inflight.length === 0) {
      console.log("load first")
      /*
      // DEBUG 
      for (const p of exerciseParts) {
        if (p.scribeID === "6FFBCEF3-8291-4948-8354-CA90AE5729F9" && p.partIdx === 0) {
          requestPart(p)
          return
        }
      }
      */

      requestPart(exerciseParts[0])
      return
    }
    if (!currentPart) {
      return
    }
    const idx = parts.findIndex(v => _.isEqual(v.info, currentPart.info))

    const loadedOrInflight = parts.length + inflight.length
    const leftInLoaded = loadedOrInflight - idx - 1
    const minLoad = Math.min(exerciseParts.length, minLoadSize)
    if (leftInLoaded < minLoad) {
      // Time to load some more
      const possibleChoices = exerciseParts
        .filter(p => !inflight.find(i => i === partKey(p)))
        .filter(p => !parts.find(v => _.isEqual(v.info, p)))
        .slice(undefined, minLoad)

      possibleChoices.forEach(p => requestPart(p))
    }
  }, [currentPart, inflight, parts, exerciseParts, requestPart, minLoadSize])

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      // Disable in development mode
      return
    }
    const handler = (e: BeforeUnloadEvent) => {
      if (dirty.length > 0) {
        e.preventDefault()
        e.returnValue = "You have unsaved changes. Save them or lose them forever."
      }
    }

    window.addEventListener("beforeunload", handler)
    return () => {
      window.removeEventListener("beforeunload", handler)
    }
  }, [dirty])

  useEffect(() => {
    // Load exercise data
    if (!type || !cls) {
      return
    }
    dispatch(reset())

    FirestoreService.fetchExercises(type, cls).then(v => {
      // create list of all parts
      let parts: PartInfo[] = []

      v.forEach(i => {
        i.parts.forEach(p => {
          parts.push({
            scribeID: i.id,
            proposalID: i.proposal,
            partIdx: p,
          })
        })
      })

      setExerciseParts(parts)
    })
  }, [type, cls, dispatch])

  useEffect(() => {
    // Update ephemeral exercises
    if (!currentPart) {
      return
    }

    const key = partKey(currentPart.info)

    //const exercises: Record<string, Exercise> = {}
    const exercises: Record<string, Exercise> = {}

    const alts = currentPart.part.alts?.map(p => p.exercise).filter(e => e.type !== type)
    for (const alt of alts ?? []) {
      const exKey = exerciseKey(alt)
      const id = uuidv5(exKey, UUID_NAMESPACE)
      exercises[id] = alt
    }
    dispatch(
      setEphemeralExercises({
        mainExercise: {
          key,
          exercise: currentPart.part.exercise,
        },
        exercises,
      }),
    )
  }, [currentPart, type, dispatch])

  return (
    <Box>
      <Drawer variant="persistent" anchor="right" open={showSidebar}>
        <Box m={2} mt={20}>
          <VerifySidebar />
        </Box>
      </Drawer>
      <Button variant={showSidebar ? "contained" : "outlined"} onClick={() => setShowSidebar(t => !t)}>
        {showSidebar ? "Hide" : "Show"} Options
      </Button>
      <Box display="flex">
        <Box my={2}>
          <Typography variant="h1">{type}</Typography>
          <Typography variant="h2">{cls}</Typography>
          <Typography variant="subtitle2">
            Loaded: {parts.length}, Total: {(exerciseParts ?? []).length}
          </Typography>
        </Box>
        <Box> {loading && <CircularProgress />}</Box>
      </Box>
      {error && (
        <Alert severity="error" onClose={() => setError("")}>
          {error}
        </Alert>
      )}
      <Box my={2}>
        <VerifyGraphs />
      </Box>

      <Grid container spacing={2} m={2}>
        <Grid item xs={5}>
          {currentPart && <VerifyPartDataCard part={currentPart} type={type ?? "unknown"} cls={cls ?? "unknown"} />}
        </Grid>
        <Grid item xs={5}>
          <Box>
            <VerifyActions />
          </Box>
          <Box m={2}>
            <VerifySaveButton />
          </Box>
          <Box m={2}>
            <Typography variant="h6">Changed parts</Typography>
            {dirty.map(p => {
              const key = partKey(p.info)
              const canSave = verifyPartCanSave(p)
              return (
                <Box key={key}>
                  {key} {canSave ? "✅" : "❌"}
                </Box>
              )
            })}
          </Box>
          {inflight.length > 0 && (
            <Box m={2}>
              <Typography variant="h6">Loading</Typography>
              {inflight.map(p => {
                return <Box key={p}>{p}</Box>
              })}
            </Box>
          )}
        </Grid>
        <Grid item xs={2}>
          <VerifyExerciseSelector />
          <Box mt={4}>
            <Typography variant="h4">Description</Typography>
            <pre>{currentPart?.description}</pre>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
