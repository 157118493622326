import * as PIXI from "pixi.js"
import { Viewport } from "pixi-viewport"
import { PartInfo, partKey } from "../../store/types/verify"
import PartGraph, { EditMarkCallback, EditSpanCallback } from "./partgraph"
import { DATA_FREQUENCY } from "../../utils/constants"
import { InflatedPartData } from "../../store/verify/reducer"
import _ from "lodash"
import { formatSeconds } from "../../utils/format"
import { InflatedExercise } from "../../store/types"
import { VERIFY_PADDING } from "../../pages/verify/data"

// manage all the pixi part of the exercise graph

export type CursorUpdate = (info: PartInfo, offset: number) => void

interface Options {
  app: PIXI.Application
  types: string[]
  onChangeSpan: EditSpanCallback
  onChangeMark: EditMarkCallback
  onCursorUpdate: CursorUpdate
}

class VerifyPixi {
  private readonly partsContainer: PIXI.Container
  private readonly cursorContainer: PIXI.Container
  private readonly cursorText: PIXI.Text
  private readonly app: PIXI.Application
  private readonly types: string[]
  private readonly onChangeSpan: EditSpanCallback
  private readonly onChangeMark: EditMarkCallback
  private readonly onCursorUpdate: CursorUpdate

  private readonly partsMap = new Map<string, PartGraph>()
  private parts: PartGraph[] = []

  private xPadding = 20
  private cursorX: number = 0
  private currentGraph: PartGraph | null = null
  private viewport: Viewport
  private moving: boolean = false

  constructor(params: Options) {
    this.app = params.app
    this.types = params.types
    this.onChangeSpan = params.onChangeSpan
    this.onChangeMark = params.onChangeMark
    this.onCursorUpdate = _.throttle(params.onCursorUpdate, 200)

    console.log("create exercise pixi")

    const vp = new Viewport({
      interaction: this.app.renderer.plugins.interaction,
    })
      .drag({ direction: "x", wheel: true })
      .on("moved", this._moveViewport.bind(this))
      .on("moved-end", this._moveViewportEnd.bind(this))

    // cursor
    this.cursorText = new PIXI.Text("--:--", {})

    this.cursorContainer = new PIXI.Container()
    this.cursorContainer.name = "cursor"
    this.cursorContainer.x = this.app.screen.width / 2
    this.cursorContainer.addChild(this.cursorText)

    const cursorLine = new PIXI.Graphics().lineStyle(1, PIXI.utils.string2hex("#ff0000")).moveTo(0, 0).lineTo(0, 240)
    this.cursorContainer.addChild(cursorLine)

    // parts
    this.partsContainer = new PIXI.Container()
    this.partsContainer.name = "parts"
    this.partsContainer.y = 50

    vp.addChild(this.partsContainer)
    this.viewport = vp

    this.app.stage.addChild(vp, this.cursorContainer)
    this.resize()
  }

  resize() {
    this.cursorX = this.app.screen.width / 2
    this.cursorContainer.x = this.cursorX
  }

  toTime(x: number): number {
    return x / DATA_FREQUENCY
  }

  toPartTime(part: InflatedPartData, x: number): number {
    const t = x / DATA_FREQUENCY
    const offset = part.offset / 1_000_000
    return t + offset
  }

  _moveViewport({ viewport }: { viewport: Viewport }) {
    this.moving = true
    // hit test all parts
    let pos = 0
    const center = viewport.left + this.cursorX
    let match: PartGraph | null = null

    for (const g of this.parts) {
      const start = pos
      let length = g.part.length
      const end = pos + length

      if (center > start && center < end) {
        match = g
        break
      }

      pos += length + this.xPadding
    }

    if (match) {
      const t = this.toTime(center - pos)
      this.currentGraph = match
      this._updateCursorText(this.toPartTime(match.part, center - pos))
      //console.log("move viewport", t)
      this.onCursorUpdate(match.part.info, t)
    } else {
      this.cursorText.text = "--:--"
      this.currentGraph = null
    }
  }

  _moveViewportEnd() {
    this.moving = false
  }

  _getOrCreatePart(part: InflatedPartData): PartGraph {
    const key = partKey(part.info)
    if (this.partsMap.has(key)) {
      return this.partsMap.get(key)!
    }

    const g = new PartGraph({
      part,
      types: this.types,
      interactionManager: this.app.renderer.plugins.interaction,
      onChangeSpan: this.onChangeSpan,
      onChangeMark: this.onChangeMark,
    })
    this.partsMap.set(key, g)
    return g
  }

  _updatePart(part: InflatedPartData, exercises: { [p: string]: InflatedExercise }): PartGraph {
    const g = this._getOrCreatePart(part)
    g.updateData(part, exercises)
    return g
  }

  updateData(parts: InflatedPartData[], exercises: { [p: string]: InflatedExercise }) {
    const isFirst = this.parts.length === 0

    this.parts = []

    this.partsContainer.removeChildren()

    let offset = 0

    const rect = this.viewport.getVisibleBounds()

    // Hide anything more than 3 screen widths out of view
    const extraMax = rect.width * 3
    const leftMax = rect.x - extraMax
    const rightMax = rect.x + rect.width + extraMax

    for (const p of parts) {
      const g = this._updatePart(p, exercises)
      this.parts.push(g)

      const c = g.container
      c.x = offset

      if (c.position.x + c.width < leftMax || c.position.x > rightMax) {
        // hidden
      } else {
        this.partsContainer.addChild(c)
      }

      offset += c.width + this.xPadding
    }

    if (isFirst && this.parts.length === 1) {
      this.scrollTo(this.parts[0].part, VERIFY_PADDING + 0.1)
    }
  }

  _updateCursorText(t: number) {
    const partText = formatSeconds(t)
    this.cursorText.text = `${partText}`
  }

  scrollTo(part: InflatedPartData, offset: number) {
    if (this.moving) {
      return
    }
    const newKey = partKey(part.info)

    const g = this.partsMap.get(newKey)
    if (!g) {
      console.log("container not created yet", newKey, Array.from(this.partsMap.keys()))
      return
    }

    const d = offset * DATA_FREQUENCY
    const x = g.container.x - this.app.screen.width / 2 + d
    this.viewport.moveCorner(x, 0)

    this._updateCursorText(offset + part.offset / 1_000_000)
    this.onCursorUpdate(part.info, offset)
  }
}

export default VerifyPixi
