import { Box, Chip, Stack } from "@mui/material"
import { CSSProperties } from "react"

interface ChipProps {
  id: string
  label?: string
  color?: string
}

interface OwnProps {
  chips: ChipProps[]
}

/*
  Displays a list of chips with optional label and color
 */
function ChipList({ chips }: OwnProps) {
  return (
    <Box>
      {chips.map(c => {
        const label = c.label ?? c.id
        const style: CSSProperties = {}
        if (c.color) {
          style.backgroundColor = c.color
        }
        return <Chip sx={{ fontSize: "0.8em", mx: 0.3, my: 0.2 }} size="small" key={c.id} style={style} label={label} />
      })}
    </Box>
  )
}

export default ChipList
