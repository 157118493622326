import { Box, Checkbox, FormControlLabel, Theme, Typography } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import TextField from "@mui/material/TextField"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store"
import { setAccelerationGraphZoom, setAccelerationGraphShowAxis } from "../../../store/session/reducer"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textfield: {
      borderColor: "#3f51b5",
      margin: theme.spacing(3, 0),
      width: 70,
    },
    buttons: {
      margin: theme.spacing(3, 3),
    },
  }),
)

function GraphBounds() {
  const classes = useStyles()
  const dispatch = useDispatch()

  const zoom = useSelector((state: RootState) => state.currentTaggingSession.accelerationGraph.zoom)
  const [axisx, axisy, axisz] = useSelector(
    (state: RootState) => state.currentTaggingSession.accelerationGraph.showAxis,
  )

  return (
    <Box display="flex" alignItems="center">
      <Box mr={2}> Linear Acceleration:</Box>
      <Box mr={2}>
        <TextField
          label="Zoom"
          type="number"
          size="small"
          variant="outlined"
          className={classes.textfield}
          value={zoom}
          onChange={e => dispatch(setAccelerationGraphZoom(Number(e.target.value)))}
          onKeyDown={e => e.stopPropagation()}
        />
      </Box>
      <FormControlLabel
        label="X"
        control={
          <Checkbox
            checked={axisx}
            onChange={(e, isChecked) => dispatch(setAccelerationGraphShowAxis([0, isChecked]))}
          />
        }
      />
      <FormControlLabel
        label="Y"
        control={
          <Checkbox
            checked={axisy}
            onChange={(e, isChecked) => dispatch(setAccelerationGraphShowAxis([1, isChecked]))}
          />
        }
      />
      <FormControlLabel
        label="Z"
        control={
          <Checkbox
            checked={axisz}
            onChange={(e, isChecked) => dispatch(setAccelerationGraphShowAxis([2, isChecked]))}
          />
        }
      />
    </Box>
  )
}

export default GraphBounds
