import { ScribeColumn } from "../ScribeList"
import { TableCell } from "@mui/material"
import ScribeDetails from "../../scribes/ScribeDetails"

export const DetailsColumn: ScribeColumn = {
  header: <TableCell>Details</TableCell>,
  cell(s) {
    return (
      <TableCell
        sx={{
          color: "text.secondary",
          fontSize: "0.8em",
        }}
      >
        <ScribeDetails scribeID={s.id} userID={s.user_id} />
      </TableCell>
    )
  },
}
