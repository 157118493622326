import {
  PredictionVisualization,
  VisualizationPoint,
} from "../services/firebase/storage/types/prediction-visualization"
import _ from "lodash"
import { DATA_FREQUENCY } from "./constants"

export interface InflatedPredictionVisualization {
  data: number[][]
  keys: string[]
}

// this converts a list of visualization points to a list of values at a certain frequency
function resample(vs: VisualizationPoint[], frequency: number): number[] {
  const end = vs[vs.length - 1].end
  const perPixel = 1_000_000 / frequency
  let output = []
  let j = 0
  for (let i = 0; i < end; i += perPixel) {
    while (j < vs.length - 1 && vs[j].start < i) {
      j++
    }

    if (vs[j].start >= i) {
      output.push(1 - vs[j].value)
    }
  }
  return output
}

export function inflateVisualization(
  pred: PredictionVisualization | undefined,
): InflatedPredictionVisualization | undefined {
  if (!pred) {
    return undefined
  }

  const data = Object.values(pred.channels).map(v => resample(v, DATA_FREQUENCY))
  return {
    data: data,
    keys: Object.keys(pred.channels),
  }
}

// return the value for every exercise type at a certain time
export function predictionsAtTime(
  predictionVisualization: PredictionVisualization,
  time: number,
): Record<string, number> {
  return _.chain(predictionVisualization.channels)
    .mapValues(points => points.find(p => p.start <= time && p.end >= time)) // find the first matching point
    .omitBy(_.isUndefined)
    .mapValues(p => p!.value)
    .value()
}
