import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import _ from "lodash"
import { FirestoreService, StorageService } from "../../services/firebase"
import dayjs from "dayjs"
import { createStyles, makeStyles } from "@mui/styles"
import { Box, Button, CircularProgress, Grid, Theme, Typography } from "@mui/material"
import { Scribe, scribeFirestoreConverter } from "../../services/firebase/firestore/types/scribe"
import firebase from "firebase/compat"
import SpeedGraphs from "./SpeedGraphs"
import { ScribeTag } from "../../utils/tags"
import userContext from "../../context/user-context"
import { scribeExecutionProposalFirestoreConverter } from "../../services/firebase/firestore/types/scribe-execution-proposal"
import { BlueprintNew } from "../../services/firebase/firestore/types/blueprint"
import { useSelector } from "react-redux"
import { selectExerciseDefs } from "../../store/definitions/selectors"
import { RootState } from "../../store"
import { useHotkeys } from "react-hotkeys-hook"

type OwnProps = {}

export type SensorData = number[][]

export class Span {
  a: number
  b: number

  constructor(a: number, b: number) {
    this.a = a
    this.b = b
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  }),
)

function getExercisesFromBlueprint(bp: BlueprintNew | undefined): string[] {
  switch (bp?.class) {
    case "COMPOUND":
      return _.flatMap(bp.spec.sections, (s: any) => getExercisesFromBlueprint(s))
    case "STANDARD":
      return bp.spec.exercises.map((e: any) => e.type)
    case "EMOM":
      return _.flatMap(bp.spec.round_specs, (r: any) => r.exercises.map((e: any) => e.type))
    case "TABATA":
      return _.flatMap(bp.spec.round_specs, (r: any) => r.exercises.map((e: any) => e.type))
    default:
      return []
  }
}

function getBlueprintType(bp: BlueprintNew | undefined): string | undefined {
  if (bp?.class == "COMPOUND") {
    const types = _.uniq<string | undefined>(bp.spec.sections.map((s: any) => getBlueprintType(s)))
    if (types.length > 1) {
      return "COMPOUND"
    }
    return _.get(types, 0)
  }

  return bp?.class
}

function SpeedTaggingContainer(props: OwnProps) {
  const classes = useStyles()

  const [sensorData, setSensorData] = useState<SensorData>([])
  const [currentWorkout, setCurrentWorkout] = useState<Scribe | undefined>()
  const [allWorkouts, setAllWorkouts] = useState<Scribe[] | undefined>()
  const [lastDocInFirestoreQuery, setLastDocInFirestoreQuery] = useState<
    firebase.firestore.QueryDocumentSnapshot | undefined
  >()
  const [spans, setSpans] = useState<Span[]>([])
  const [binaryPred, setBinaryPred] = useState<Span[]>([])
  const [isShowingEndOfData, setIsShowingEndOfData] = useState<boolean>(false)
  const operatorUser = useContext(userContext)
  const exerciseDefs = useSelector(selectExerciseDefs)
  const users = useSelector((state: RootState) => state.users.users)
  const fullName = _.get(users, [currentWorkout?.user_id || "", "display_name"], currentWorkout?.user_id)

  useEffect(() => {
    if (currentWorkout) {
      return
    }

    if (!allWorkouts || allWorkouts?.length === 0) {
      let q = FirestoreService.db()
        .collection("scribes")
        .where("tags", "array-contains", "needs_operator_attention")
        .limit(20)

      if (lastDocInFirestoreQuery) {
        q = q.startAfter(lastDocInFirestoreQuery)
      }

      q.withConverter(scribeFirestoreConverter).onSnapshot(val => {
        console.log("Got a Firestore query result")
        const workouts = val.docs.map(d => d.data())
        const nextWorkout = workouts.shift()!

        setAllWorkouts(workouts)
        setLastDocInFirestoreQuery(val.docs[val.docs.length - 1])

        console.log("Setting current workout ID", nextWorkout.id)
        setSensorData([])
        setBinaryPred([])
        setCurrentDataWindow(0)
        setCurrentWorkout(nextWorkout)
      })

      return
    }

    const nextWorkout = allWorkouts[0]
    setSensorData([])
    setBinaryPred([])
    setCurrentDataWindow(0)
    setAllWorkouts(w => w?.slice(1))
    setCurrentWorkout(nextWorkout)
  }, [currentWorkout, allWorkouts])

  // useEffect(() => {
  //   console.log(
  //     `All Workouts [${allWorkouts
  //       ?.slice(0, 5)
  //       .map(w => w.id.substring(0, 5))
  //       .join(",")}]{${allWorkouts?.length}}`,
  //   )
  //     if (allWorkouts && allWorkouts.length > 0) {
  //       // Prefetch
  //       console.log(`Prefetching ${allWorkouts[0].id}`)
  //       StorageService.fetchProcessedSensorFile(allWorkouts[0].id)
  //     }
  // }, [allWorkouts])

  useEffect(() => {
    if (!currentWorkout) {
      return
    }

    console.log(`Loading binary prediction for ${currentWorkout.id}`)

    FirestoreService.db()
      .collection("scribes")
      .doc(currentWorkout.id)
      .collection("execution_proposals")
      .withConverter(scribeExecutionProposalFirestoreConverter)
      .where("metadata.model", "==", "binary_v10")
      // .orderBy("metadata.created_at", "desc")
      .limit(1)
      .onSnapshot(val => {
        if (val.docs.length == 0) {
          return
        }

        setBinaryPred(val.docs[0].data().execution.map(p => new Span(p.time_start / 40000, p.time_end / 40000)))
      })
  }, [currentWorkout, setBinaryPred])

  useEffect(() => {
    if (!currentWorkout) {
      return
    }

    console.log(`Loading sensor file for ${currentWorkout.id}`)
    StorageService.fetchProcessedSensorFile(currentWorkout.id)
      .then(sensorfile => setSensorData(_.mapValues(sensorfile.sensors.data, d => _.unzip(d))[9]))
      .catch((error: any) => {
        // TODO Handle error.
      })
  }, [currentWorkout])

  const skipWorkout = useCallback(() => {
    if (!currentWorkout) {
      return
    }

    FirestoreService.setTag(currentWorkout.id, ScribeTag.LabelingSpeedSkip)
    FirestoreService.unsetTag(currentWorkout.id, ScribeTag.NeedsOpeartorAttetion)

    setSpans([])
    setIsShowingEndOfData(false)
    setCurrentWorkout(undefined)
  }, [setSpans, currentWorkout, setCurrentWorkout, setIsShowingEndOfData])

  const saveWorkout = useCallback(() => {
    if (!currentWorkout || !isShowingEndOfData) {
      return
    }

    const stats = { active_time_us: 1, action_count: 1 }
    const exec = spans.map(s => ({
      time_start: Math.floor(s.a * 40000),
      time_end: Math.floor(s.b * 40000),
      exercise: { type: "EXERCISE" },
    }))

    FirestoreService.createProposal(
      currentWorkout?.id,
      exec,
      { class: "STANDARD", spec: {} },
      [],
      operatorUser?.uid!,
      stats,
    ).then(doc => {
      FirestoreService.setTag(currentWorkout.id, ScribeTag.LabelingSpeedDoneBinary)
      FirestoreService.unsetTag(currentWorkout.id, ScribeTag.NeedsOpeartorAttetion)
      setSpans([])
      setIsShowingEndOfData(false)
      setCurrentWorkout(undefined)
    })
  }, [currentWorkout, setSpans, spans, setIsShowingEndOfData, isShowingEndOfData])

  const isShowingLastDataCB = useCallback(
    (b: boolean) => {
      setIsShowingEndOfData(b)
    },
    [setIsShowingEndOfData],
  )

  const addSpan = useCallback(
    (newSpan: Span) => {
      setSpans(ss => {
        ss.push(newSpan)
        ss.sort((a, b) => a.a - b.a)
        const res: Span[] = [ss[0]]
        ss.forEach(s => {
          const head = res[res.length - 1].b
          if (s.a <= head) {
            res[res.length - 1].b = Math.max(head, s.b)
            return
          }

          res.push(s)
        })

        return res
      })
    },
    [setSpans],
  )

  const [currentDataWindow, setCurrentDataWindow] = useState<number>(0)
  const nextWindow = useCallback(() => setCurrentDataWindow(w => w + 1), [])
  const prevWindow = useCallback(() => setCurrentDataWindow(w => Math.max(0, w - 1)), [])

  const exercises = useMemo(
    () => _.uniq(getExercisesFromBlueprint(currentWorkout?.user_edited_blueprint).map(e => exerciseDefs[e]?.name ?? e)),
    [currentWorkout, exerciseDefs],
  )
  const bpType = getBlueprintType(currentWorkout?.user_edited_blueprint)

  useHotkeys("a", nextWindow, { description: "Scroll data graphs" }, [nextWindow])
  useHotkeys("s", saveWorkout, { description: "Save tagging" }, [saveWorkout])
  useHotkeys("p", skipWorkout, { description: "Skip this workout" }, [skipWorkout])
  useHotkeys("e", prevWindow, { description: "Scroll data graphs" }, [prevWindow])

  if (!currentWorkout) {
    return <CircularProgress className={classes.progress} size={24} />
  }

  return (
    <>
      {/* Top information */}

      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <Box sx={{ fontSize: 16 }}>
            <Typography sx={{ fontWeight: "bold", display: "inline", pr: 4 }}>{fullName}</Typography>
            <Typography sx={{ display: "inline" }}>
              {dayjs(currentWorkout.started_at.toDate()).format("MMMM YYYY")}
            </Typography>
          </Box>
          <Typography sx={{ fontSize: 12 }}>{currentWorkout.id}</Typography>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          {currentDataWindow > 0 && (
            <Button sx={{ ml: 2 }} size="small" variant="contained" onClick={nextWindow}>
              [E] Scroll back
            </Button>
          )}
          {!isShowingEndOfData && (
            <Button sx={{ ml: 2 }} size="small" variant="contained" onClick={nextWindow}>
              [A] Scroll
            </Button>
          )}
          {isShowingEndOfData && (
            <Button sx={{ ml: 2 }} size="small" variant="contained" onClick={saveWorkout}>
              [S] Save
            </Button>
          )}
          <Button sx={{ ml: 2 }} size="small" variant="contained" onClick={skipWorkout}>
            [P] Skip
          </Button>
        </Grid>
      </Grid>

      {/* Exercises */}

      <Box mb={4}>
        <Typography sx={{ display: "inline", mr: 4, fontWeight: "bold" }}>{bpType ?? "UNKNOWN"}</Typography>
        {exercises.map(e => (
          <Box key={e} sx={{ display: "inline", mr: 4 }}>
            <Box sx={{ height: 20, width: 20, backgroundColor: "primary", display: "inline" }} />
            <Typography sx={{ display: "inline" }}>{e}</Typography>
          </Box>
        ))}
      </Box>

      {/* Graphs */}

      <SpeedGraphs
        spans={spans}
        isShowingLastDataCB={isShowingLastDataCB}
        currentDataWindow={currentDataWindow}
        binaryPred={binaryPred}
        addSpan={addSpan}
        sensordata={sensorData}
      ></SpeedGraphs>
    </>
  )
}

export default SpeedTaggingContainer
