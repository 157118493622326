import React, { ElementType } from "react"
import ReactDOM from "react-dom"
import "./index.css"
import { Provider } from "react-redux"
import App from "./pages/App"
import store from "./store"
import * as serviceWorker from "./serviceWorker"

const render = (Component: ElementType) => {
  return ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Component />
      </Provider>
    </React.StrictMode>,
    document.getElementById("root"),
  )
}

render(App)

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept("./pages/App", () => {
    const NextApp = require("./pages/App").default
    render(NextApp)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
