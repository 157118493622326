import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { User } from "../services/firebase/firestore/types"

export type Users = Record<string, User>
interface UsersSliceState {
  users: Users
}

const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: {},
  } as UsersSliceState,
  reducers: {
    setUsers(state, action: PayloadAction<User[]>) {
      state.users = action.payload.reduce((acc: Users, u) => {
        acc[u.id] = u
        return acc
      }, {})
    },
  },
})

export const { setUsers } = usersSlice.actions
export default usersSlice.reducer
