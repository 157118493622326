import { useCallback, useEffect, useRef, useState } from "react"
import * as PIXI from "pixi.js"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { changeMark, changeSpan, InflatedPartData, setCursor } from "../../store/verify/reducer"
import VerifyPixi from "../../pixi/verify/verifypixi"
import _ from "lodash"
import { BeginEnd } from "../../pixi/utils"
import { selectAllExercises, selectCursorOffset, selectCursorPart, selectParts } from "../../store/verify/selectors"
import { PartInfo } from "../../store/types/verify"

/**
 * Manage communication between the pixi graph and the React/redux state
 */
function VerifyGraphs() {
  const dispatch = useAppDispatch()

  const pixiRef = useRef<HTMLCanvasElement>(null)
  const pixiParentRef = useRef<HTMLDivElement>(null)
  const [exercisePixi, setExercisePixi] = useState<VerifyPixi>()
  const pixiApp = useRef<PIXI.Application>()

  const currentPart = useAppSelector(selectCursorPart)
  const currentCursorOffset = useAppSelector(selectCursorOffset)

  const parts = useAppSelector(selectParts)
  const exercises = useAppSelector(selectAllExercises)

  const onChangeSpan = useCallback(
    (part: InflatedPartData, change: BeginEnd) => {
      dispatch(changeSpan({ info: part.info, change }))
    },
    [dispatch],
  )

  const onChangeMark = useCallback(
    (part: InflatedPartData, id: string, time: number) => {
      dispatch(changeMark({ info: part.info, id, time }))
    },
    [dispatch],
  )

  const onCursorUpdate = useCallback(
    (info: PartInfo, offset: number) => {
      //console.log("set cursor", offset)
      dispatch(setCursor({ info: info, offset }))
    },
    [dispatch],
  )

  useEffect(() => {
    let app: PIXI.Application | undefined = pixiApp.current
    if (!app) {
      app = new PIXI.Application({
        view: pixiRef.current || undefined,
        resizeTo: pixiParentRef.current || undefined,
        resolution: 2,
        antialias: true,
        autoDensity: true,
        backgroundAlpha: 0.05,
      })
    }

    // add to debugger
    // @ts-ignore
    globalThis.__PIXI_APP__ = app

    pixiApp.current = app

    console.log("Created PIXI")

    // TODO: make configurable
    const types = ["Gravity", "Linear Acceleration"]
    const ep = new VerifyPixi({
      app,
      types,
      onChangeSpan,
      onChangeMark,
      onCursorUpdate,
    })
    setExercisePixi(ep)

    return () => {
      console.log("destroy pixi class")
      // destroy doesn't work correctly, so we "clear" the app instead
      pixiApp.current?.stage.removeChildren()
    }
  }, [pixiRef, pixiParentRef, onChangeSpan, onChangeMark, onCursorUpdate])

  useEffect(() => {
    // update graph if part change
    if (!currentPart) {
      return
    }
    exercisePixi?.scrollTo(currentPart, currentCursorOffset)
  }, [currentPart, currentCursorOffset, exercisePixi])

  // Handle resize
  useEffect(() => {
    const handleResize = _.debounce(function () {
      exercisePixi?.resize()
    }, 500)

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [exercisePixi])

  useEffect(() => {
    // Update parts
    exercisePixi?.updateData(parts, exercises)
  }, [parts, exercises, exercisePixi])

  const height = 340

  return (
    <div
      style={{
        height: height,
        left: 0,
      }}
    >
      <div
        ref={pixiParentRef}
        style={{
          position: "absolute",
          left: 0,
          width: "calc(100vw - (100vw - 100%))", // the full viewport minus the width of the scrollbar
          height: height,
        }}
      >
        <canvas ref={pixiRef} />
      </div>
    </div>
  )
}

export default VerifyGraphs
