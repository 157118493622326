import React, { useEffect, useRef, useState } from "react"
import * as PIXI from "pixi.js"
import _ from "lodash"
import SpeedGraph from "../../pixi/speedgraph"
import { defaultColors } from "../../pixi/lines"
import { Span } from "./SpeedTaggingContainer"

PIXI.utils.skipHello()

type OwnProps = {
  // prediction?: WorkoutExecution
  sensordata: number[][]
  spans: Span[]
  binaryPred: Span[]
  currentDataWindow: number

  addSpan: (s: Span) => void
  isShowingLastDataCB: (b: boolean) => void
}

function SpeedGraphs(props: OwnProps) {
  const pixiref = useRef<HTMLCanvasElement>(null)
  const pixiparentref = useRef<HTMLDivElement>(null)
  const [graph, setGraph] = useState<SpeedGraph>()

  useEffect(() => {
    console.log("Creating PIXI")
    const pixiapp = new PIXI.Application({
      antialias: true,
      view: pixiref.current || undefined,
      resizeTo: pixiparentref.current || undefined,
      backgroundAlpha: 0,
      resolution: 2,
      autoDensity: true,
    })
    pixiapp.ticker.maxFPS = 10 // TODO experimental

    const graphsContainer = new PIXI.Container()
    graphsContainer.name = "speed-graph"
    setGraph(
      new SpeedGraph({
        container: graphsContainer,
        interactionmanager: pixiapp!.renderer.plugins.interaction,
        addSpan: props.addSpan,
        isShowingLastDataCallback: props.isShowingLastDataCB,
        color: defaultColors,
        width: Math.ceil(pixiparentref.current!.offsetWidth),
      }),
    )

    pixiapp.stage.addChild(graphsContainer)

    return () => {
      console.log("Destroying PIXI")
      pixiapp.destroy(false, { children: true })
    }
  }, [pixiref, pixiparentref, setGraph])

  useEffect(() => {
    graph?.setWindow(props.currentDataWindow)
  }, [graph, props.currentDataWindow])

  useEffect(() => {
    if (!props.sensordata || props.sensordata.length == 0) {
      return
    }

    graph?.updateData(props.sensordata)
  }, [graph, props.sensordata])

  useEffect(() => {
    graph?.updateSpans(props.spans)
  }, [graph, props.spans])

  useEffect(() => {
    graph?.updateBinaryPred(props.binaryPred)
  }, [graph, props.binaryPred])

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
      }}
    >
      <div
        ref={pixiparentref}
        style={{
          height: 340,
          position: "absolute",
          width: "calc(100vw)",
        }}
      >
        <canvas ref={pixiref}></canvas>
      </div>
    </div>
  )
}

export default SpeedGraphs
