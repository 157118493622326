import Box from "@mui/material/Box"
import Button, { ButtonProps } from "@mui/material/Button"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { v4 as uuidv4 } from "uuid"
import {
  addRep,
  changeSpan,
  jumpToPart,
  removeRep,
  setCursor,
  setRepCount,
  setSpanType,
  toggleIgnore,
  verifyReps,
  verifySpan,
} from "../../store/verify/reducer"
import { useHotkeys } from "react-hotkeys-hook"
import { selectCursorPart, selectCursorPartOffset, selectParts } from "../../store/verify/selectors"
import { DATA_FREQUENCY } from "../../utils/constants"
import { useCallback } from "react"
import { VERIFY_PADDING } from "./data"
import { BeginEnd } from "../../pixi/utils"
import { Typography } from "@mui/material"
import HelpDialog from "../scribe/HelpDialog"
import { selectUserSettings } from "../../store/user-settings/selectors"

interface HotKeyButtonProps {
  hotkey: string
  description: string
  onClick: () => void
  preventDefault?: boolean
}

function HotKeyButton({
  hotkey,
  description,
  onClick,
  children,
  preventDefault,
  ...rest
}: HotKeyButtonProps & ButtonProps) {
  const buttonClick = useCallback(() => {
    onClick()
  }, [onClick])

  const hotkeyClick = useCallback(
    (keyboardEvent: KeyboardEvent) => {
      onClick()
      if (preventDefault) {
        keyboardEvent.preventDefault()
      }
    },
    [onClick, preventDefault],
  )

  useHotkeys(hotkey, hotkeyClick, { description: description }, [hotkey, onClick, description])

  return (
    <Button onClick={buttonClick} {...rest}>
      {children} [{hotkey}]
    </Button>
  )
}

function VerifyActions() {
  const dispatch = useAppDispatch()
  const currentPart = useAppSelector(selectCursorPart)
  const cursorPartOffset = useAppSelector(selectCursorPartOffset)
  const parts = useAppSelector(selectParts)
  const userSettings = useAppSelector(selectUserSettings)

  const onAddRep = useCallback(() => {
    dispatch(addRep(uuidv4()))
  }, [dispatch])

  const onDeleteRep = useCallback(() => {
    dispatch(removeRep())
  }, [dispatch])

  const onVerifyReps = useCallback(() => {
    dispatch(verifyReps())
  }, [dispatch])

  const onSetReps = useCallback(
    (n: number) => {
      dispatch(setRepCount(n))
    },
    [dispatch],
  )

  const onVerifySpanExJump = useCallback(() => {
    if (!currentPart) {
      return
    }
    dispatch(setSpanType({ ref: currentPart.spans["main"].ref }))
    dispatch(verifySpan(currentPart.info))
    setTimeout(() => {
      jumpDelta(1)
    }, 1)
  }, [dispatch, currentPart])

  const onBeginCurrent = useCallback(() => {
    if (!currentPart) {
      return
    }
    dispatch(setCursor({ info: currentPart.info, offset: VERIFY_PADDING }))
  }, [dispatch, currentPart])

  const onEndCurrent = useCallback(() => {
    if (!currentPart) {
      return
    }
    const end = currentPart.length / DATA_FREQUENCY
    dispatch(setCursor({ info: currentPart.info, offset: end - VERIFY_PADDING }))
  }, [dispatch, currentPart])

  const jumpDelta = useCallback(
    n => {
      if (!currentPart || n === 0) {
        return
      }
      const idx = parts.findIndex(p => p.info === currentPart.info)
      if (idx < 0) {
        return
      }
      if (n < 0 && idx + n < 0) {
        return
      }

      if (parts.length < idx + 1 + n) {
        return
      }
      const next = parts[idx + n]
      dispatch(jumpToPart(next.info))
    },
    [dispatch, currentPart, parts],
  )

  const onVerifySpan = useCallback(() => {
    if (!currentPart) {
      return
    }
    dispatch(verifySpan(currentPart.info))
  }, [dispatch, currentPart])

  const onIgnorePart = useCallback(() => {
    if (!currentPart) {
      return
    }
    dispatch(toggleIgnore(currentPart.info))
    setTimeout(() => {
      jumpDelta(1)
    }, 1)
  }, [dispatch, currentPart, jumpDelta])

  const currentReps = Object.values(currentPart?.marks ?? {}).length

  const repOptions = Array.from(Array(5).keys()).map(n => n - 2 + currentReps)

  const incReps = useCallback(() => {
    dispatch(setRepCount(currentReps + 1))
  }, [dispatch, currentReps])

  const decReps = useCallback(() => {
    dispatch(setRepCount(currentReps - 1))
  }, [dispatch, currentReps])

  const verifyRepCount = useCallback(() => {
    dispatch(setRepCount(currentReps))
  }, [dispatch, currentReps])

  const isRepCountVerified = currentPart?.validation.rep_count ?? false

  const bumpNearest = useCallback(
    // Bump the side that's closest to the cursor
    (dir: "left" | "right") => {
      if (!currentPart) {
        return
      }
      // TODO: handle splitting
      const span = currentPart.spans[`main`]
      // find span marker
      const dtBegin = Math.abs(cursorPartOffset - span.begin)
      const dtEnd = Math.abs(cursorPartOffset - span.end)

      const dt = Math.min(dtBegin, dtEnd)
      if (dt > 10) {
        console.log("too far away")
        return
      }

      let change: BeginEnd
      let move = userSettings.bumpSize / DATA_FREQUENCY

      if (dir === "left") {
        move *= -1
      }

      if (dtBegin < dtEnd) {
        change = { begin: span.begin + move }
      } else {
        change = { end: span.end + move }
      }
      dispatch(changeSpan({ info: currentPart.info, change }))
    },
    [dispatch, currentPart, cursorPartOffset, userSettings],
  )
  const bumpSpan = useCallback(
    // move a specific side of the span
    (which: "begin" | "end", dir: "left" | "right") => {
      if (!currentPart) {
        return
      }
      // TODO: handle splitting
      const span = currentPart.spans[`main`]

      let change: BeginEnd
      let move = userSettings.bumpSize / DATA_FREQUENCY

      if (dir === "left") {
        move *= -1
      }
      if (which === "begin") {
        change = { begin: span.begin + move }
      } else {
        change = { end: span.end + move }
      }
      dispatch(changeSpan({ info: currentPart.info, change }))
    },
    [dispatch, currentPart, userSettings],
  )

  useHotkeys("c", verifyRepCount, { description: "Verify reps" }, [verifyRepCount])
  useHotkeys("equal", incReps, { description: "Set reps to current reps + 1" }, [incReps])
  useHotkeys("minus", decReps, { description: "Set reps to current reps - 1" }, [decReps])

  // useHotkeys(
  //   "*",
  //   (k, h) => {
  //     console.log("hotkey", k, h)
  //   },
  //   { description: "debug" },
  //   [],
  // )

  return (
    <Box>
      <Box display="flex">
        <Typography variant="h4">Actions</Typography>
        <HelpDialog />
      </Box>
      <Box m={2}>
        Reps:
        {repOptions.map(n => (
          <Button
            key={n}
            onClick={() => onSetReps(n)}
            variant={n === currentReps ? "contained" : "outlined"}
            size="small"
            color={n === currentReps ? (isRepCountVerified ? "primary" : "secondary") : undefined}
          >
            {n}
            {n === currentReps && " [c]"}
            {n === currentReps + 1 && " [=]"}
            {n === currentReps - 1 && " [-]"}
          </Button>
        ))}
      </Box>
      <Box m={2}>
        <HotKeyButton
          hotkey={"space"}
          size="small"
          preventDefault={true}
          description="Add a rep at the cursor"
          variant="outlined"
          onClick={onAddRep}
        >
          Add Rep
        </HotKeyButton>
        <HotKeyButton
          hotkey={"x"}
          size="small"
          description="Delete the rep near the cursor"
          variant="outlined"
          onClick={onDeleteRep}
        >
          Delete Rep
        </HotKeyButton>
      </Box>
      <Box m={2}>
        <HotKeyButton
          hotkey={"q"}
          size="small"
          variant="outlined"
          description={"Verify span/exercise and jump to next"}
          onClick={onVerifySpanExJump}
        >
          Verify span and exercise and jump to next
        </HotKeyButton>
      </Box>
      <Box m={2}>
        <HotKeyButton hotkey={"v"} size="small" description="Verify the reps" variant="outlined" onClick={onVerifyReps}>
          Verify {currentReps} Reps count and times
        </HotKeyButton>
        <HotKeyButton hotkey={"s"} size="small" description="Verify the span" variant="outlined" onClick={onVerifySpan}>
          Verify Span
        </HotKeyButton>
        <HotKeyButton hotkey={"i"} size="small" description={"Ignore part"} variant="outlined" onClick={onIgnorePart}>
          Ignore part
        </HotKeyButton>
      </Box>
      <Box m={2}>
        <HotKeyButton
          hotkey={"n"}
          size="small"
          description="Jump to the next part"
          variant="outlined"
          onClick={() => jumpDelta(1)}
        >
          Next part
        </HotKeyButton>
        <HotKeyButton
          hotkey={"p"}
          size="small"
          description="Jump to the previous part"
          variant="outlined"
          onClick={() => jumpDelta(-1)}
        >
          Previous part
        </HotKeyButton>
        <HotKeyButton
          hotkey={"a"}
          size="small"
          description="Jump to start of current part"
          variant="outlined"
          onClick={onBeginCurrent}
        >
          Go to Beginning of current part
        </HotKeyButton>
        <HotKeyButton
          hotkey={"e"}
          size="small"
          description="Jump to end of current part"
          variant="outlined"
          onClick={onEndCurrent}
        >
          Go to End of current part
        </HotKeyButton>
      </Box>
      <Box m={2}>
        <HotKeyButton
          hotkey={"t"}
          size="small"
          variant="outlined"
          description="Bump marker left"
          onClick={() => bumpNearest("left")}
        >
          Bump marker left
        </HotKeyButton>
        <HotKeyButton
          hotkey={"y"}
          size="small"
          variant="outlined"
          description="Bump marker right"
          onClick={() => bumpNearest("right")}
        >
          Bump marker right
        </HotKeyButton>
      </Box>
      <Box m={2}>
        <HotKeyButton
          preventDefault={true}
          hotkey={"up"}
          size="small"
          variant="outlined"
          description="Bump begin left"
          onClick={() => bumpSpan("begin", "left")}
        >
          Bump begin left
        </HotKeyButton>
        <HotKeyButton
          preventDefault={true}
          hotkey={"down"}
          size="small"
          variant="outlined"
          description="Bump begin right"
          onClick={() => bumpSpan("begin", "right")}
        >
          Bump begin right
        </HotKeyButton>
        <HotKeyButton
          preventDefault={true}
          hotkey={"left"}
          size="small"
          variant="outlined"
          description="Bump end left"
          onClick={() => bumpSpan("end", "left")}
        >
          Bump end left
        </HotKeyButton>
        <HotKeyButton
          preventDefault={true}
          hotkey={"right"}
          size="small"
          variant="outlined"
          description="Bump end right"
          onClick={() => bumpSpan("end", "right")}
        >
          Bump end right
        </HotKeyButton>
      </Box>
    </Box>
  )
}

export default VerifyActions
