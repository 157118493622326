import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import Scribe from "./Scribe"
import { useScribeExecutionProposalsListener, useScribeListener } from "../../hooks/firestore-listener"
import _ from "lodash"
import { StorageService } from "../../services/firebase"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store"
import {
  setEnabledGraphAxesForType,
  setGraphCursor,
  setSessionFromFirestore,
  setUserActivityState,
  UserActivityState,
} from "../../store/session/reducer"
import dayjs from "dayjs"
import { PredictionVisualization } from "../../services/firebase/storage/types/prediction-visualization"
import { inflateVisualization } from "../../utils/predictionVisualization"
import { SensorType } from "../../utils/sensorType"

type OwnProps = {}

const IDLE_TIMEOUT = 3000

export type SensorData = { [sensorType: number]: number[][] }

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

function ScribeContainer(props: OwnProps) {
  const [videoURL, setVideoURL] = useState<string | undefined>()
  const [userDescriptionImageURL, setUserDescriptionImageURL] = useState<string | undefined>()
  const [sensorData, setSensorData] = useState<SensorData | undefined>()
  const [predictionVisualization, setPredictionVisualization] = useState<PredictionVisualization | undefined>()

  const { id: scribeID } = useParams()
  if (!scribeID) {
    throw new Error("ID missing in url")
  }

  const scribe = useScribeListener(scribeID)
  const executionProposals = useScribeExecutionProposalsListener(scribeID)
  const currentProposalID = useSelector((state: RootState) => state.currentTaggingSession.currentProposalID)
  const isSessionDirty = useSelector((state: RootState) => state.currentTaggingSession.isDirty)
  const currentOverlayId = useSelector((state: RootState) => state.currentTaggingSession.overlayProposal?.id)
  const currentOverlay = executionProposals?.find(p => p.id === currentOverlayId)
  const currentProposal = executionProposals?.find(p => p.id === currentProposalID)
  const activeExecutionID = scribe?.execution_proposal_id
  const activeExecution = scribe?.execution
  const dispatch = useDispatch()

  useEffect(() => {
    let isActive: Boolean = false
    const toIdle = _.debounce(() => {
      dispatch(
        setUserActivityState({
          state: UserActivityState.IDLE,
          timestamp: dayjs().valueOf(),
        }),
      )
      isActive = false
    }, IDLE_TIMEOUT)

    const handler = (e: Event) => {
      if (isActive === false) {
        dispatch(
          setUserActivityState({
            state: UserActivityState.ACTIVE,
            timestamp: dayjs().valueOf(),
          }),
        )
      }

      isActive = true
      toIdle()
    }

    window.addEventListener("mousemove", handler)
    window.addEventListener("keydown", handler)
    toIdle()

    return () => {
      window.addEventListener("mousemove", handler)
      window.addEventListener("keydown", handler)
      toIdle.cancel()
    }
  }, [dispatch])

  useEffect(() => {
    const handler = (e: BeforeUnloadEvent) => {
      if (isSessionDirty) {
        e.preventDefault()
        e.returnValue = "You have unsaved changes. Save them or lose them forever."
      }
    }

    window.addEventListener("beforeunload", handler)

    return () => {
      window.removeEventListener("beforeunload", handler)
    }
  }, [isSessionDirty])

  useEffect(() => {
    if (!currentProposalID && activeExecution && executionProposals !== undefined) {
      const currentProposal = executionProposals.find(p => p.id === currentProposalID)
      if (!currentProposal) {
        return
      }
      dispatch(
        setSessionFromFirestore({
          proposal: currentProposal,
          timestamp: dayjs().valueOf(),
        }),
      )
    }
  }, [activeExecutionID, activeExecution, currentProposalID, executionProposals, setPredictionVisualization, dispatch])

  // Fetch the vis data (prediction graph) for the selected overlay
  useEffect(() => {
    if (!currentOverlay || !currentOverlay.metadata.visualization_path) {
      return
    }
    StorageService.fetchPredictionVisualizationFile(currentOverlay.metadata.visualization_path).then(viz => {
      setPredictionVisualization(viz)
      const convertedVis = inflateVisualization(viz)
      if (convertedVis) {
        setSensorData(sd => ({
          ...sd,
          [SensorType.Prediction]: convertedVis.data,
        }))
        dispatch(
          setEnabledGraphAxesForType({
            sensorType: SensorType.Prediction,
            axes: convertedVis.keys.map((v, idx) => idx),
          }),
        )
      } else {
        setSensorData(sd => _.omit(sd, SensorType.Prediction))
      }
    })
  }, [currentOverlay, setSensorData, dispatch, setPredictionVisualization])

  useEffect(() => {
    if (!scribe) {
      return
    }

    StorageService.fetchProcessedSensorFile(scribeID)
      .then(sensorfile => {
        const sd = _.mapValues(sensorfile.sensors.data, d => _.unzip(d))

        // Inflate heartrate datapoints
        if (sensorfile.heartrate && sensorfile.heartrate.length > 0) {
          let paddedHeartrate = sensorfile.heartrate
          paddedHeartrate.push({
            time_us: Infinity,
            value: sensorfile.heartrate[sensorfile.heartrate.length - 1].value,
          })

          let hrIdx = 0
          const freqStep = 1000000 / sensorfile.sensors.frequency
          let inflatedHeartrate: number[] = []
          for (let i = 0; i < Object.values(sensorfile.sensors.data)[0].length; i++) {
            const t = i * freqStep
            if (t > paddedHeartrate[hrIdx].time_us) {
              hrIdx++
            }
            inflatedHeartrate.push(paddedHeartrate[hrIdx].value)
          }

          sd[21] = [inflatedHeartrate]
        }

        setSensorData(old => ({ ...old, ...sd }))
      })
      .catch((error: any) => {
        // TODO Handle error.
      })

    StorageService.fetchVideoURL(scribeID)
      .then(setVideoURL)
      .catch(error => {
        // ignore errors
      })

    if (scribe.user_description_image_ref) {
      StorageService.fetchDescriptionImageURL(scribe.user_description_image_ref).then(setUserDescriptionImageURL)
    }
  }, [scribeID, scribe])

  const query = useQuery()

  useEffect(() => {
    const startTime = query.get("time")
    if (startTime) {
      dispatch(setGraphCursor(Number(startTime)))
    }
  }, [query, dispatch])

  return (
    // <ShortcutProvider ignoreTagNames={["BlueprintTab"]}>
    <Scribe
      scribe={scribe}
      executionProposals={executionProposals}
      currentProposal={currentProposal}
      sensordata={sensorData}
      videoURL={videoURL}
      userDescriptionImageURL={userDescriptionImageURL}
      predictionVisualization={predictionVisualization}
    />
    // </ShortcutProvider>
  )
}

export default ScribeContainer
