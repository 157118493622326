import * as PIXI from "pixi.js"
import { Span } from "./utils"
import Marker, { SimpleMarker } from "./marker"

const HANDLE_SIZE = 10
const COLOR = PIXI.utils.string2hex("#222021")

class ExerciseMarker {
  leftMarker: Marker<number>
  rightMarker: Marker<number>
  connector: PIXI.Graphics
  fill: PIXI.Graphics
  displayobject: PIXI.DisplayObject
  isOpenEnded: boolean
  fillColor?: number

  constructor(
    interactionmanager: PIXI.InteractionManager,
    begin: number,
    end: number,
    updateBounds: (v: { begin?: number; end?: number }) => void,
    isOpenEnded: boolean,
    fillColor?: number,
  ) {
    this.fillColor = fillColor
    this.isOpenEnded = isOpenEnded

    this.leftMarker = new SimpleMarker({
      interactionmanager,
      initpos: begin,
      toPos: t => t,
      create: () =>
        new PIXI.Graphics()
          .beginFill(COLOR)
          .drawRect(0, 180 - HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE)
          .endFill()
          .lineStyle(1, COLOR)
          .moveTo(0, 180)
          .lineTo(0, 16),
      onDragEnd: (pos: number) => updateBounds({ begin: pos }),
      onDragMove: pos => this._updateConnector(),
    })

    this.rightMarker = new SimpleMarker({
      interactionmanager,
      initpos: end,
      toPos: t => t,
      create: () =>
        new PIXI.Graphics()
          .beginFill(COLOR)
          .drawRect(-HANDLE_SIZE, 180 - HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE)
          .endFill()
          .lineStyle(1, COLOR)
          .moveTo(0, 180)
          .lineTo(0, 16),
      onDragEnd: pos => updateBounds({ end: pos }),
      onDragMove: pos => this._updateConnector(),
    })

    this.connector = new PIXI.Graphics()
    this.fill = new PIXI.Graphics()
    const container = new PIXI.Container()
    container.addChild(this.leftMarker.displayobject, this.rightMarker.displayobject, this.connector, this.fill)

    this.displayobject = container

    this._updateConnector()
    this._updateFill()
  }

  update(ex: Span) {
    this.fillColor = ex.fillColor ? PIXI.utils.string2hex(ex.fillColor) : undefined
    this.leftMarker.update(ex.begin)
    if (ex.end) {
      this.rightMarker.update(ex.end)
    }
    this.isOpenEnded = ex.end === undefined
    this._updateConnector()
    this._updateFill()
  }

  cursorMove(pos: number) {
    if (this.isOpenEnded) {
      this.rightMarker.update(pos)
      this._updateConnector()
    }
  }

  _updateFill() {
    const width = this.rightMarker.displayobject.x - this.leftMarker.displayobject.x
    this.fill.x = this.leftMarker.displayobject.x
    this.fill.clear()
    if (!this.fillColor) {
      return
    }
    this.fill
      .beginFill(this.fillColor, 0.2)
      .drawRect(0, 16, width, 180 - 16)
      .endFill()
  }

  _updateConnector() {
    const width = this.rightMarker.displayobject.x - this.leftMarker.displayobject.x
    if (this.connector.width === width && this.connector.x === this.leftMarker.displayobject.x) {
      return
    }

    this.connector.clear().lineStyle(1, COLOR).moveTo(0, 180).lineTo(width, 180)

    this.connector.x = this.leftMarker.displayobject.x
  }
}

export default ExerciseMarker
