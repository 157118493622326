import React, { useEffect, useState } from "react"
import { FirestoreService } from "../../services/firebase"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material"
import { Link, useSearchParams } from "react-router-dom"
import Typography from "@mui/material/Typography"
import { selectExerciseDefs } from "../../store/definitions/selectors"
import { useAppSelector } from "../../store/hooks"
import { TaggingIndex } from "../../services/firebase/firestore/types/tagging-index"
import _ from "lodash"

interface VerifyExerciseButtonProps {
  exercise: string
  cls: string
  counts: Record<string, number>
  secs: Record<string, number>
}

const formatSecs = (sec: number) => {
  if (sec < 60) {
    return `${Math.round(sec)}s`
  } else if (sec < 60 * 60) {
    return `${Math.round(sec / 60)}m`
  }
  return `${Math.round(sec / 60 / 60)}h`
}

function VerifyExerciseButton({ exercise, counts, secs, cls }: VerifyExerciseButtonProps) {
  const name = () => {
    switch (cls) {
      case "correct_high":
        return "Correct High"
      case "correct_low":
        return "Correct Low"
      case "incorrect_high":
        return "Incorrect High"
      case "incorrect_low":
        return "Incorrect Low"
      case "validated":
        return "Validated"
      case "ignored":
        return "Ignored"
    }
    return cls
  }

  const count = counts[cls]
  const sec = secs[cls]

  return (
    <Box>
      <Button
        sx={{
          mx: 2,
          fontSize: "0.7rem",
          px: 1,
          py: 0,
        }}
        disabled={count === 0}
        variant="outlined"
        size="small"
        component={Link}
        to={`/verify/${exercise}/${cls}`}
      >
        {name()}
      </Button>
      {sec > 0 && <>{formatSecs(sec)}</>}
    </Box>
  )
}

function VerifyExerciseList() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [page, setPage] = useState(0)
  const [exercises, setExercises] = useState<TaggingIndex[]>([])
  const [filtered, setFiltered] = useState<TaggingIndex[]>([])
  const [totalTime, setTotalTime] = useState({
    correct_high: 0,
    correct_low: 0,
    incorrect_high: 0,
    incorrect_low: 0,
    validated: 0,
    ignored: 0,
  })
  const [showCustom, setShowCustom] = useState(false)
  const exerciseDefs = useAppSelector(selectExerciseDefs)

  // fetch index
  useEffect(() => {
    FirestoreService.fetchExerciseIndex().then(v => setExercises(v))
  }, [])

  const searchFor = (search: string) => {
    search = search.toLowerCase()
    setSearchParams(search !== "" ? { search: search } : {})
  }

  useEffect(() => {
    // calculate total time
    let total = {
      correct_high: 0,
      correct_low: 0,
      incorrect_high: 0,
      incorrect_low: 0,
      validated: 0,
      ignored: 0,
    }
    filtered.forEach(e => {
      total.correct_high += e.secs.correct_high
      total.correct_low += e.secs.correct_low
      total.incorrect_high += e.secs.incorrect_high
      total.incorrect_low += e.secs.incorrect_low
      total.validated += e.secs.validated
      total.ignored += e.secs.ignored
    })
    setTotalTime(total)
  }, [filtered, setTotalTime])

  useEffect(() => {
    const search = searchParams.get("search") ?? ""
    const nonCustom = exercises.filter(e => e.name in exerciseDefs)

    let exs = exercises
    if (!showCustom) {
      exs = nonCustom
    }

    if (search === "") {
      setFiltered(exs)
      return
    }
    setFiltered(
      exs.filter(exercise => {
        const def = exerciseDefs[exercise.name]
        if (def && def.name.toLowerCase().includes(search)) {
          return true
        }

        return exercise.name.toLowerCase().includes(search)
      }),
    )
  }, [exercises, setFiltered, searchParams, exerciseDefs, showCustom])

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const exerciseName = (exercise: string): string => {
    return exerciseDefs[exercise]?.name ?? exercise
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const count = parseInt(event.target.value, 10)
    setRowsPerPage(count)
  }

  const sliced = rowsPerPage > 0 ? filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filtered

  return (
    <Paper square>
      <Box m={2}>
        <Typography variant="h1">Verification</Typography>
      </Box>
      <Box m={2} display="flex">
        <TextField label="Search" value={searchParams.get("search") || ""} onChange={e => searchFor(e.target.value)} />
        <Box m={2}>
          <FormControlLabel
            checked={showCustom}
            onChange={() => setShowCustom(v => !v)}
            control={<Checkbox />}
            label="Show custom"
          />
        </Box>
      </Box>
      <Paper style={{ overflow: "hidden" }}>
        <TableContainer style={{ maxHeight: "calc(100vh - 300px)" }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Correct High ({formatSecs(totalTime.correct_high)})</TableCell>
                <TableCell>Correct Low ({formatSecs(totalTime.correct_low)})</TableCell>
                <TableCell>Incorrect High ({formatSecs(totalTime.incorrect_high)})</TableCell>
                <TableCell>Incorrect Low ({formatSecs(totalTime.incorrect_low)})</TableCell>
                <TableCell>Validated ({formatSecs(totalTime.validated)})</TableCell>
                <TableCell>Ignored ({formatSecs(totalTime.ignored)})</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sliced.map(exercise => {
                const name = exercise.name
                const counts = exercise.counts
                const secs = exercise.secs
                return (
                  <TableRow key={name}>
                    <TableCell>
                      <Typography style={{ color: exerciseDefs[name] ? undefined : "#ff0000" }}>
                        {exerciseName(name)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <VerifyExerciseButton exercise={name} counts={counts} secs={secs} cls="correct_high" />
                    </TableCell>
                    <TableCell>
                      <VerifyExerciseButton exercise={name} counts={counts} secs={secs} cls="correct_low" />
                    </TableCell>
                    <TableCell>
                      <VerifyExerciseButton exercise={name} counts={counts} secs={secs} cls="incorrect_high" />
                    </TableCell>
                    <TableCell>
                      <VerifyExerciseButton exercise={name} counts={counts} secs={secs} cls="incorrect_low" />
                    </TableCell>
                    <TableCell>
                      <VerifyExerciseButton exercise={name} counts={counts} secs={secs} cls="validated" />
                    </TableCell>
                    <TableCell>
                      <VerifyExerciseButton exercise={name} counts={counts} secs={secs} cls="ignored" />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filtered.length}
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>
    </Paper>
  )
}

export default VerifyExerciseList
