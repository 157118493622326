import Box from "@mui/material/Box"
import React, { useCallback, useEffect } from "react"
import { Checkbox, FormControlLabel, TextField } from "@mui/material"

export interface CutSettings {
  maxLen: number
  active: boolean
}

interface OwnProps {
  value: CutSettings
  enabled: boolean
  onChange?: (newValue: CutSettings) => void
}

export default function CutOptionsForm({ value, enabled, onChange }: OwnProps) {
  const doCallback = useCallback(
    newValue => {
      if (onChange) {
        onChange(newValue)
      }
    },
    [onChange],
  )

  const onChangeMaxLen = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      doCallback({ ...value, maxLen: Number(e.target.value) })
    },
    [doCallback, value],
  )

  const onChangeActive = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      doCallback({ ...value, active: e.target.checked })
    },
    [doCallback, value],
  )

  return (
    <Box display="flex" alignItems="center">
      <Box mr={2}>
        <TextField
          value={value.maxLen}
          label="max length"
          type="number"
          size="small"
          variant="outlined"
          onChange={onChangeMaxLen}
        />
      </Box>
      <FormControlLabel
        control={<Checkbox disabled={!enabled} checked={value.active} onChange={onChangeActive} />}
        label="Cut long spans"
      />
    </Box>
  )
}
