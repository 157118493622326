import Box from "@mui/material/Box"
import WorkerTest from "./verify/WorkerTest"
import { useState } from "react"
import { HotkeysProvider, useHotkeys, useHotkeysContext } from "react-hotkeys-hook"

function HotCounter() {
  const [count, setCount] = useState(0)

  useHotkeys(
    "c",
    () => {
      setCount(c => c + 1)
    },
    {
      description: "hello world",
    },
    [setCount],
  )

  return <Box>{count}</Box>
}

function TestHotKeys() {
  const { hotkeys } = useHotkeysContext()
  return (
    <Box>
      Counter: <HotCounter />
      Keys: {JSON.stringify(hotkeys)}
    </Box>
  )
}

function Exp() {
  return (
    <Box>
      <TestHotKeys />
      {/*<WorkerTest />*/}
    </Box>
  )
}

export default Exp
