import Button from "@mui/material/Button"
import React, { useState, useCallback } from "react"
import { Box, Fab, Popover, Snackbar, Theme, Tooltip, Typography } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import CircularProgress from "@mui/material/CircularProgress"
import { triggerPrediction } from "../../services/backend"

const models = ["base25", "binary", "bin_lstm", "base109_lstm", "base115_lstm"]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
    },
    progress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      position: "relative",
    },
  }),
)

// TODO: use context for scribe id?
interface TriggerPredictionsSectionProps {
  scribeID: string
}

function TriggerPredictionsSection({ scribeID }: TriggerPredictionsSectionProps) {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null)

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => setAnchorElement(event.currentTarget),
    [setAnchorElement],
  )

  const handleClose = useCallback(() => setAnchorElement(null), [setAnchorElement])

  return (
    <Box>
      <Tooltip title="Trigger prediction" placement="top">
        <Fab
          size="small"
          color="primary"
          style={{
            maxWidth: "24px",
            maxHeight: "24px",
            minWidth: "24px",
            minHeight: "24px",
            marginLeft: "8px",
          }}
          onClick={handleClick}
        >
          <PlayArrowIcon style={{ width: 14, height: 14 }} />
        </Fab>
      </Tooltip>
      <Popover
        id={!!anchorElement ? "trigger-popover" : undefined}
        open={!!anchorElement}
        onClose={handleClose}
        anchorEl={anchorElement}
      >
        <Box display="flex">
          {models.map(name => (
            <TriggerPredictionButton key={name} scribeID={scribeID} name={name} version={2} />
          ))}
        </Box>
        <Typography ml={2} variant="caption">
          Experimental storm VM prediction:
        </Typography>
        <Box display="flex">
          {models.map(name => (
            <TriggerPredictionButton key={name + "_vm"} scribeID={scribeID} name={name} version={3} />
          ))}
        </Box>
      </Popover>
    </Box>
  )
}

interface TriggerPredictionButtonProps {
  scribeID: string
  name: string
  version: number
}

function TriggerPredictionButton({ scribeID, name, version }: TriggerPredictionButtonProps) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState<string | null>(null)

  const onClick = useCallback(() => {
    setLoading(true)

    triggerPrediction(name, scribeID, version)
      .then(jobId => setMessage(`Started Job: ${jobId}`))
      .catch(error => setMessage(`Error: ${error}`))
      .finally(() => setLoading(false))
  }, [setLoading, setMessage, scribeID, name])

  const clearMessage = useCallback(() => setMessage(null), [setMessage])

  return (
    <div className={classes.wrapper}>
      <Button
        className={classes.button}
        size="small"
        variant="contained"
        onClick={onClick}
        disabled={loading}
        color={version == 2 ? "primary" : "secondary"}
      >
        {name}
      </Button>
      {loading && <CircularProgress className={classes.progress} size={24} />}
      <Snackbar open={Boolean(message)} autoHideDuration={5000} onClose={clearMessage} message={message} />
    </div>
  )
}

export default TriggerPredictionsSection
