import { useSelector } from "react-redux"
import { RootState } from "../store"
import { Tooltip } from "@mui/material"

interface OwnProps {
  userID?: string
}

function UserGender({ userID }: OwnProps) {
  const gender = useSelector((state: RootState) => userID && state.users.users[userID]?.gender)

  let emoji = "👤"

  if (gender === "female") {
    emoji = "👩"
  } else if (gender === "male") {
    emoji = "👨"
  }

  return (
    <Tooltip arrow title={gender ?? "unknown"}>
      <span>{emoji}</span>
    </Tooltip>
  )
}

export default UserGender
