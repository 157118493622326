export enum SensorType {
  Accelerometer = 1,
  "Accelerometer Uncalibrated" = 23,
  "Game Rotation Vector" = 15,
  "Geomagnetic Rotation Vector" = 20,
  Gravity = 9,
  Gyroscope = 4,
  "Gyroscope Uncalibrated" = 16,
  "Linear Acceleration" = 10,
  "Motion Detect" = 30,
  "Rotation Vector" = 11,
  "Heart Rate" = 21,
  Prediction = 900,
}

export function sensorTypeToSensorName(t: number | SensorType): string {
  return SensorType[t] || "Unknown"
}

export function sensorNameToSensorType(k: string): SensorType {
  const key = k as keyof typeof SensorType
  return SensorType[key] || 0
}
