import { KnownTags } from "../../utils/tags"
import ChipList from "./ChipList"

interface OwnProps {
  tags: string[] | undefined
}

function ScribeTagsList({ tags }: OwnProps) {
  const chips = tags?.sort().map(t => {
    const known = KnownTags[t]
    return {
      id: t,
      label: known?.title,
      color: known?.color,
    }
  })

  if (!chips) {
    return <></>
  }

  return (
    <>
      <ChipList chips={chips} />
    </>
  )
}

export default ScribeTagsList
