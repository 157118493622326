import firebase from "firebase/compat/app"

export const userFirestoreConverter = {
  toFirestore(user: User): firebase.firestore.DocumentData {
    throw Error("Not implemented!")
  },
  fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot, options: firebase.firestore.SnapshotOptions): User {
    const data = snapshot.data(options)
    return {
      ...data,
      id: snapshot.id,
    }
  },
}

export interface User {
  id: string

  display_name?: string
  username?: string
  affiliate?: string
  nationality?: string
  crossfit_games_profile_link?: string
  units?: string

  name?: string
  gender?: string
  date_of_birth?: firebase.firestore.Timestamp
  date_of_birth_privacy?: string
  height?: string
  height_privacy?: string
  weight?: string
  weight_privacy?: string

  firebase_device_token?: string
  consent?: {
    email_monthly?: boolean
    email_surveys?: boolean
    privacy_policy?: boolean
  }
  tags?: string[]

  totals?: {
    points?: number
    reps?: number
    scribes?: number
  }
}
