import firebase from "firebase/compat/app"
import { marshalExecution, unmarshalExecution, WorkoutExecution } from "./scribe"
import { BlueprintNew } from "./blueprint"

export const scribeExecutionProposalFirestoreConverter = {
  toFirestore(prop: ScribeExecutionProposal): firebase.firestore.DocumentData {
    const d = {
      execution: marshalExecution(prop.execution),
      blueprint: prop.blueprint,
      metadata: prop.metadata,
      section_splits: prop.section_splits,
    }
    console.log("doc", d)
    return d
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): ScribeExecutionProposal {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      execution: unmarshalExecution(data["execution"]) ?? [],
      metadata: data["metadata"],
      blueprint: data["blueprint"],
      blueprint_string: data["blueprint_string"],
      section_splits: data["section_splits"],
    }
  },
}

export interface ScribeExecutionProposal {
  id?: string

  execution: WorkoutExecution
  metadata: ScribeExecutionProposalMetadata
  blueprint?: BlueprintNew
  blueprint_string?: string

  section_splits?: number[]
}

export interface ScribeExecutionProposalMetadata {
  status: ScribeExecutionProposalStatus

  created_at: firebase.firestore.Timestamp
  updated_at: firebase.firestore.Timestamp

  automatic: boolean
  model?: string
  job_id?: string
  job_url?: string

  user_id?: string
  note?: string

  labeling_metrics?: ScribeExecutionProposalLabelingMetrics

  visualization_path?: string
}

export interface ScribeExecutionProposalLabelingMetrics {
  active_time_us: number
  action_count: number
}

export enum ScribeExecutionProposalStatus {
  Draft = "draft",
  Complete = "complete",
}
