import { createSelector } from "reselect"
import { RootState } from ".."

export const selectExerciseDefs = createSelector(
  (state: RootState) => state.definitions.exercises,
  d => d,
)

export const selectEquipmentDefs = createSelector(
  (state: RootState) => state.definitions.equipment,
  d => d,
)
