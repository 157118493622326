import { Box, Checkbox, FormControlLabel, Tooltip } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { RangeMode, setRangeMode } from "../../../store/session/reducer"
import { RootState } from "../../../store"

function RangeOptions() {
  const dispatch = useDispatch()

  const currentMode = useSelector((state: RootState) => state.currentTaggingSession.rangeMode)

  const onChange = (e: any, isChecked: boolean) => {
    dispatch(setRangeMode(isChecked ? RangeMode.CLICK : RangeMode.DRAG))
  }

  return (
    <Box>
      <FormControlLabel
        control={<Checkbox checked={currentMode === RangeMode.CLICK} onChange={onChange} />}
        label="Click mode"
      />
    </Box>
  )
}

export default RangeOptions
