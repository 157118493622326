import React from "react"
import { Box, Theme, Tooltip, Typography } from "@mui/material"
import { Scribe } from "../../services/firebase/firestore/types"
import UserGender from "../../components/UserGender"
import FileCopyIcon from "@mui/icons-material/FileCopy"
import NotInterestedIcon from "@mui/icons-material/NotInterested"
import YouTubeIcon from "@mui/icons-material/YouTube"
import UserFlag from "../../components/UserFlag"
import AppleIcon from "@mui/icons-material/Apple"
import WatchIcon from "@mui/icons-material/Watch"
import AndroidIcon from "@mui/icons-material/Android"
import makeStyles from "@mui/styles/makeStyles"
import createStyles from "@mui/styles/createStyles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoIcon: {
      color: "#757575",
      width: 16,
      height: 16,
      marginLeft: theme.spacing(1),
    },
  }),
)

interface OwnProps {
  scribe: Scribe
}

function ScribeInfo({ scribe }: OwnProps) {
  const classes = useStyles()

  const isClone = !!scribe.clone_of

  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography variant="h1">Info</Typography>
      </Box>
      <Box>
        <span style={{ fontSize: 16 }}>
          <UserGender userID={scribe.user_id} />
          <UserFlag userID={scribe.user_id} />
        </span>
        {scribe.has_video && <YouTubeIcon className={classes.infoIcon} />}
        {scribe.is_test && (
          <Tooltip arrow title="Is Test">
            <NotInterestedIcon className={classes.infoIcon} />
          </Tooltip>
        )}
        {isClone && (
          <Tooltip arrow title="Is Clone">
            <FileCopyIcon className={classes.infoIcon} />
          </Tooltip>
        )}
        {scribe.has_processed_file && (
          <Tooltip arrow title={"Has Processed File"}>
            <WatchIcon className={classes.infoIcon} />
          </Tooltip>
        )}
        {scribe.metadata?.watch_operating_system &&
          scribe.metadata?.watch_operating_system.toLowerCase().startsWith("watchos") && (
            <AppleIcon className={classes.infoIcon} />
          )}
        {scribe.metadata?.watch_operating_system &&
          scribe.metadata?.watch_operating_system.toLowerCase().startsWith("wearos") && (
            <AndroidIcon className={classes.infoIcon} />
          )}
      </Box>
    </Box>
  )
}

export default ScribeInfo
