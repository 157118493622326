import { Box } from "@mui/material"
import { Scribe, scribeFirestoreConverter } from "../../../services/firebase/firestore/types/scribe"
import { FirestoreService } from "../../../services/firebase"
import ScribeList from "../../../components/scribelist/ScribeList"
import { useCallback } from "react"
import ScribeTagInput from "../../../components/fields/ScribeTagInput"
import firebase from "firebase/compat/app"

import { useSearchParams } from "react-router-dom"
import { DefaultColumns } from "./index"

function ScribesByTag() {
  const [searchParams, setSearchParams] = useSearchParams()

  const makeQuery = useCallback(() => {
    let q: firebase.firestore.Query<Scribe> = FirestoreService.db()
      .collection("scribes")
      .withConverter(scribeFirestoreConverter)
    const tag = searchParams.get("tag")
    if (tag) {
      q = q.where("tags", "array-contains", tag)
    }
    q = q.orderBy("started_at", "desc")
    return q
  }, [searchParams])

  const changeTag = useCallback(
    (newTag: string | null) => {
      setSearchParams(newTag ? { tag: newTag } : {})
    },
    [setSearchParams],
  )

  return (
    <Box>
      <Box sx={{ p: 1, mb: 2 }}>
        <ScribeTagInput value={searchParams.get("tag")} onChange={changeTag} />
      </Box>
      <ScribeList query={makeQuery()} columns={DefaultColumns} />
    </Box>
  )
}

export default ScribesByTag
