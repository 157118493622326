import { ScribeExecutionProposal } from "../../../services/firebase/firestore/types"
import React, { useCallback, useEffect, useState } from "react"
import { Box, Button, Tooltip } from "@mui/material"
import { setCuts, setGraphCursor } from "../../../store/session/reducer"
import { useDispatch, useSelector } from "react-redux"
import { allInflatedSpans } from "../../../store/session/selectors"
import { Cut } from "../../../pixi/utils"
import { RootState } from "../../../store"
import _ from "lodash"
import CutOptionsForm, { CutSettings } from "./CutOptionsForm"
import { useHotkeys } from "react-hotkeys-hook"

interface OwnProps {
  proposal?: ScribeExecutionProposal
}

function CutOptions({ proposal }: OwnProps) {
  const dispatch = useDispatch()

  const enabled = proposal != null

  const ispans = useSelector(allInflatedSpans)
  const graphCursor = useSelector((state: RootState) => state.currentTaggingSession.graphCursor)

  const [cutSettings, setCutSettings] = useState<CutSettings>({
    active: false,
    maxLen: 40,
  })

  const nextSpan = useCallback(() => {
    const vals = Object.values(ispans).sort((a, b) => a.begin - b.begin)
    let next = _.find(vals, span => span.begin > graphCursor)
    if (!next) {
      next = vals[0]
    }
    if (next) {
      let newPos = next.begin
      if (next.end) {
        newPos += (next.end - next.begin) / 2
      }
      dispatch(setGraphCursor(newPos))
    }
  }, [ispans, graphCursor, dispatch])

  useHotkeys("n", nextSpan, { description: "Jump to next span" }, [nextSpan])

  const currentCuts = useSelector((state: RootState) => state.currentTaggingSession.cuts)
  useEffect(() => {
    const active = currentCuts.length > 0
    setCutSettings(s => ({
      ...s,
      active: active,
    }))
  }, [currentCuts])

  const onChange = useCallback(
    (newSettings: CutSettings) => {
      setCutSettings(newSettings)
      let cuts: Cut[] = []

      if (newSettings.active && proposal != null) {
        cuts = proposal.execution.flatMap(part => {
          const start = part.time_start / 1_000_000
          const end = part.time_end / 1_000_000
          const len = end - start
          const mid = end - len / 2
          const toRemove = len - cutSettings.maxLen

          if (toRemove < 0) {
            return []
          }
          return {
            time_start: mid - toRemove / 2,
            time_end: mid + toRemove / 2,
          }
        })
      }

      dispatch(setCuts(cuts))
    },
    [dispatch, proposal, cutSettings],
  )

  return (
    <Box display="flex" alignItems="center">
      <CutOptionsForm value={cutSettings} enabled={enabled} onChange={onChange} />

      <Tooltip arrow title="Jump to next span [n]" placement="top">
        <Button variant="contained" size="small" color="primary" onClick={nextSpan}>
          Next Span
        </Button>
      </Tooltip>
    </Box>
  )
}

export default CutOptions
