import { ScribeColumn } from "../ScribeList"
import { TableCell } from "@mui/material"
import ScribeTagsList from "../../fields/ScribeTagsList"

export const TagsColumn: ScribeColumn = {
  header: <TableCell>Tags</TableCell>,
  cell(s) {
    return (
      <TableCell>
        <ScribeTagsList tags={s.tags} />
      </TableCell>
    )
  },
}
