import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { clearDirty, InflatedPartData, verifyPartCanSave } from "../../store/verify/reducer"
import { FirestoreService } from "../../services/firebase"
import { useContext, useState } from "react"
import { Alert, Box, Button, Checkbox, CircularProgress, FormControlLabel } from "@mui/material"
import { ScribeExecutionProposalLabelingMetrics } from "../../services/firebase/firestore/types/scribe-execution-proposal"
import userContext from "../../context/user-context"
import { WorkoutExecution, WorkoutExecutionPart } from "../../services/firebase/firestore/types/scribe"
import { indexScribe } from "../../services/backend"
import { selectAllExercises, selectDirty } from "../../store/verify/selectors"
import { inflatedExerciseToExercise } from "../../store/types"
import Paper from "@mui/material/Paper"

const TRAIN_PROPOSAL_ID = process.env.NODE_ENV === "production" ? "_training" : "_training_alpha"

function isOverlapping(a: WorkoutExecutionPart, b: WorkoutExecutionPart): boolean {
  if (a.time_start >= b.time_start && a.time_start <= b.time_end) {
    return true
  }
  if (a.time_end >= b.time_start && a.time_end <= b.time_end) {
    return true
  }

  return false
}

function findOverlap(exec: WorkoutExecution, part: WorkoutExecutionPart): boolean {
  for (const p of exec) {
    if (isOverlapping(p, part)) {
      return true
    }
  }

  return false
}

function mergeExecutions(exec: WorkoutExecution, oldExec: WorkoutExecution): WorkoutExecution {
  const newExec: WorkoutExecution = []

  for (const oldPart of oldExec) {
    const overlap = findOverlap(exec, oldPart)
    if (!overlap) {
      newExec.push(oldPart)
    }
  }
  for (const p of exec) {
    newExec.push(p)
  }

  newExec.sort((a, b) => a.time_start - b.time_start)
  return newExec
}

function VerifySaveButton() {
  const dirty = useAppSelector(selectDirty)
  const dispatch = useAppDispatch()
  const isDirty = dirty.length > 0
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState<string>()
  const [skipIndex, setSkipIndex] = useState(false)
  const operatorUser = useContext(userContext)
  const exercises = useAppSelector(selectAllExercises)

  const createPart = (part: InflatedPartData): WorkoutExecutionPart | null => {
    if (!verifyPartCanSave(part)) {
      console.log("part not valid")
      return null
    }

    const spans = Object.values(part.spans).map(s => {
      return {
        ref: s.ref,
        time_start: Math.floor(s.begin * 1_000_000),
        time_end: Math.floor(s.end! * 1_000_000),
      }
    })
    if (spans.length !== 1) {
      console.error("add / remove not implemented yet")
      return null
    }

    const span = spans[0]
    const marks = Object.values(part.marks).map(m => {
      return {
        type: "rep",
        time: Math.floor(m.time * 1_000_000),
        verified: m.verified ?? false,
      }
    })

    const inflatedExercise = exercises[span.ref]

    const executionPart: WorkoutExecutionPart = {
      time_start: span.time_start,
      time_end: span.time_end,
      tags: marks,
      exercise: inflatedExerciseToExercise(inflatedExercise),
    }

    executionPart.validation = part.validation

    return executionPart
  }

  const saveScribeParts = async (scribeID: string, parts: InflatedPartData[]) => {
    // Sanity checks
    if (parts.length === 0) {
      console.warn("no parts?!?", scribeID)
      return
    }
    const proposalID = parts[0].info.proposalID
    if (!parts.every(p => p.info.proposalID === proposalID)) {
      console.error("proposalID mismatch", scribeID, parts)
      return
    }

    const exec: WorkoutExecution = []
    const savedParts: InflatedPartData[] = []

    for (const part of parts) {
      const executionPart = createPart(part)
      if (executionPart) {
        exec.push(executionPart)
        savedParts.push(part)
      }
    }

    console.log("new exec", exec)

    // Save the new execution in training proposal
    // see if the training proposal exists
    const trainingProposal = await FirestoreService.getProposal(scribeID, TRAIN_PROPOSAL_ID)

    const stats: ScribeExecutionProposalLabelingMetrics = {
      action_count: 1,
      active_time_us: 1,
    }

    const bp = { class: "STANDARD", spec: {} }
    const splits: number[] = []

    if (trainingProposal) {
      // update
      const oldExec = trainingProposal.execution
      const newExec = mergeExecutions(exec, oldExec)

      await FirestoreService.updateProposal(scribeID, TRAIN_PROPOSAL_ID, newExec, bp, splits, stats, "training")
    } else {
      // create new
      await FirestoreService.createProposalWithId(
        scribeID,
        TRAIN_PROPOSAL_ID,
        exec,
        bp,
        splits,
        operatorUser?.uid ?? "unknown",
        stats,
        "training",
      )
    }

    // remove from index

    // mark clean
    for (const part of savedParts) {
      dispatch(clearDirty(part))
    }
  }

  const saveChanges = async () => {
    setInProgress(true)
    setError(undefined)
    try {
      console.log("save changes")

      // group by scribeID
      const byScribeID = new Map<string, InflatedPartData[]>()

      for (const part of dirty) {
        const scribeID = part.info.scribeID
        if (!byScribeID.has(scribeID)) {
          byScribeID.set(scribeID, [])
        }
        byScribeID.get(scribeID)!.push(part)
      }

      for (const [scribeID, parts] of byScribeID) {
        // Save the training proposal
        await saveScribeParts(scribeID, parts)
        // Reindex it
        if (!skipIndex) {
          await indexScribe(scribeID)
        }
      }
    } catch (e) {
      console.error(e)
      setError(`${e}`)
    } finally {
      setInProgress(false)
    }
  }

  return (
    <Box>
      <Box mb={2} display="flex">
        <Button variant="contained" color="primary" disabled={!isDirty || inProgress} onClick={saveChanges}>
          Save Changes
        </Button>
        {inProgress && <CircularProgress />}
        {process.env.NODE_ENV !== "production" && (
          <Box m={1}>
            <Paper>
              <Box m={1}>
                <FormControlLabel
                  control={<Checkbox checked={skipIndex} onChange={() => setSkipIndex(v => !v)} />}
                  label="Skip index update"
                />
              </Box>
            </Paper>
          </Box>
        )}
      </Box>
      {error && (
        <Alert severity="error" onClose={() => setError("")}>
          {error}
        </Alert>
      )}
    </Box>
  )
}

export default VerifySaveButton
