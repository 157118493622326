import { WorkoutExecutionPart } from "../../services/firebase/firestore/types/scribe"

export interface PartInfo {
  scribeID: string
  proposalID: string
  partIdx: number
}

export interface PartData {
  info: PartInfo

  description: string
  subData: { [sensorType: string]: number[][] }
  offset: number
  padding: number
  part: WorkoutExecutionPart
  length: number // number of samples
}

export function partKey(p: PartInfo): string {
  return `${p.scribeID}-${p.proposalID}-${p.partIdx}`
}
