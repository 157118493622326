import React from "react"
import { connect, ConnectedProps } from "react-redux"
import { RootState } from "../../../store"
import * as PIXI from "pixi.js"
import _ from "lodash"
import { Viewport } from "pixi-viewport"
import { editRep, editSpan, graphClicked, RangeMode, setGraphCursor } from "../../../store/session/reducer"
import { Mark as GraphMark, Span as GraphSpan } from "../../../pixi/utils"
import Graph, { GRAPH_HEIGHT, GRAPH_PADDING_TOP } from "../../../pixi/graph"
import { formatSeconds } from "../../../utils/format"
import { WorkoutExecution } from "../../../services/firebase/firestore/types/scribe"
import { allInflatedSpans } from "../../../store/session/selectors"
import { inflatedExerciseToString, InflatedSpan, Mark } from "../../../store/types"
import { defaultColors } from "../../../pixi/lines"
import { DATA_FREQUENCY } from "../../../utils/constants"
import { addCuts, removeCuts } from "../../../utils/cutting"

PIXI.utils.skipHello()

// register pixi if debugger is installed: https://chrome.google.com/webstore/detail/pixijs-devtools/aamddddknhcagpehecnhphigffljadon
function registerPIXIDebugger() {
  ;(window as any).__PIXI_INSPECTOR_GLOBAL_HOOK__ &&
    (window as any).__PIXI_INSPECTOR_GLOBAL_HOOK__.register({ PIXI: PIXI })
}

registerPIXIDebugger()

const GRAPH_CURSOR_COLOR = 0xef5455
const MOUSE_CURSOR_COLOR = 0x0000ff
const GRAPH_DATA_BACKGROUND_COLOR = 0xd9dddc

type SensorValues = number[][]
type SensorData = { [sensortype: string]: SensorValues }

const colorForMarker: { [color: string]: string } = {
  rep: "#777b7e",
  split: "#ff0000",
}

type OwnProps = ConnectedProps<typeof connector> & {
  prediction?: WorkoutExecution
  sensordata: { [key: string]: number[][] }
  types: string[]
  visibleAxes: { [key: string]: number[] }
  linearAccelerationGraphConfig: {
    zoom: number
    showAxis: [boolean, boolean, boolean]
  }
}

class Graphs extends React.Component<OwnProps> {
  pixiref: React.RefObject<HTMLCanvasElement>
  pixiparentref: React.RefObject<HTMLDivElement>
  pixiapp?: PIXI.Application

  graphs: { [sensortype: string]: Graph }
  graphsContainer: PIXI.Container
  subgraphStaticArtifacts: { [sensortype: string]: PIXI.DisplayObject }
  subgraphStaticArtifactContainer: PIXI.Container

  resizableGraphics: { [sensortype: string]: PIXI.Graphics }

  graphCursorCoord: number
  currentCanvasSize: number
  graphCursor: PIXI.Graphics

  mouseCursorContainer: PIXI.Container
  mouseCursorLine: PIXI.Graphics
  mouseCursorText: PIXI.Text
  graphCursorText: PIXI.Text
  viewport?: Viewport
  updateGraphCursorRedux = _.throttle((markerTime: number) => this.props.setGraphCursor(markerTime), 200)

  cutData: SensorData

  constructor(props: OwnProps) {
    super(props)

    this.pixiref = React.createRef()
    this.pixiparentref = React.createRef()

    this.graphs = {}
    this.graphsContainer = new PIXI.Container()
    this.graphsContainer.name = "graphs-container"
    this.subgraphStaticArtifacts = {}
    this.subgraphStaticArtifactContainer = new PIXI.Container()
    this.subgraphStaticArtifactContainer.name = "subgraph-static"
    this.resizableGraphics = {}

    this.graphCursorCoord = 0
    this.currentCanvasSize = 0
    this.graphCursor = new PIXI.Graphics() // will be created by _updateGraphs
    this.graphCursorText = new PIXI.Text("0.00", { fontSize: 12 })
    // Mouse cursor
    this.mouseCursorLine = new PIXI.Graphics() // will be created by _updateGraphs
    this.mouseCursorText = new PIXI.Text("0.00", { fontSize: 12 })
    this.mouseCursorContainer = new PIXI.Container()
    this.mouseCursorContainer.name = "mouse"
    this.mouseCursorContainer.addChild(this.mouseCursorLine, this.mouseCursorText)
    this.mouseCursorContainer.visible = this.props.rangeMode === RangeMode.CLICK

    this.cutData = this.props.sensordata
  }

  componentDidMount() {
    const pixiapp = new PIXI.Application({
      antialias: true,
      view: this.pixiref.current || undefined,
      resizeTo: this.pixiparentref.current || undefined,
      backgroundAlpha: 0,
      resolution: 2,
      autoDensity: true,
    })
    this.pixiapp = pixiapp

    this.graphCursorCoord = pixiapp.screen.width / 2

    this.graphCursorText.y = 0
    this.graphCursorText.anchor.x = 0.5
    this.graphCursorText.x = this.graphCursorCoord

    this.viewport = new Viewport({
      interaction: pixiapp.renderer.plugins.interaction,
    })
      .drag({ direction: "x", wheel: true })
      .on("moved", ({ viewport }: { viewport: Viewport }) => {
        const graphCursorCoord = viewport.left + this.graphCursorCoord
        const graphCursorTime = this.toTime(graphCursorCoord)
        this.graphCursorText.text = formatSeconds(graphCursorTime)
        this.updateGraphCursorRedux(graphCursorTime)

        Object.values(this.graphs).forEach(g => g.updateCursor(graphCursorCoord))
      })
      .on("clicked", ({ world }: { world: PIXI.Point }) => {
        this.updateRangeFromClick(world.x)
        this.props.graphClicked(this.toTime(world.x))
      })

    this.graphCursor.x = this.graphCursorCoord
    this.viewport.moveCorner(-this.graphCursorCoord, 0)

    this._updateGraphs(true, false, true)

    this.viewport.addChild(this.graphsContainer)
    pixiapp.stage.addChild(
      this.subgraphStaticArtifactContainer,
      this.viewport,
      this.graphCursor,
      this.graphCursorText,
      this.mouseCursorContainer,
    )

    // range marker
    this.viewport.on("mousemove", (e: PIXI.InteractionEvent) => {
      if (!this.viewport) {
        return
      }
      const mousex = e.data.global.x
      const worldx = this.viewport.toWorld({ x: mousex, y: 0 }).x
      const time = this.toTime(worldx)
      this.mouseCursorText.text = formatSeconds(time)
      this.mouseCursorContainer.x = mousex
    })

    this.handleResize = _.debounce(this.handleResize.bind(this), 500)
    window.addEventListener("resize", this.handleResize)
  }

  handleResize() {
    this.pixiapp!.resize()
    const width = this.pixiapp!.screen.width
    this.viewport!.screenWidth = width
    this.viewport!.screenHeight = this.pixiapp!.screen.height

    Object.values(this.resizableGraphics).forEach(v => {
      v.width = width
    })
  }

  _updateGraphs(updateOverlay: boolean, updateAccelerationGraph: boolean, updateCuts: boolean) {
    const newCanvasSize = this.props.types.length * 200
    if (this.currentCanvasSize !== newCanvasSize) {
      this.pixiapp!.resize()
      this.viewport!.screenWidth = this.pixiapp!.screen.width
      this.viewport!.screenHeight = this.pixiapp!.screen.height

      this.currentCanvasSize = newCanvasSize

      this.graphCursor
        .clear()
        .beginFill(GRAPH_CURSOR_COLOR)
        .drawCircle(0, 16, 3)
        .drawCircle(0, newCanvasSize - 28, 3)
        .endFill()
        .lineStyle(1, GRAPH_CURSOR_COLOR)
        .moveTo(0, 16)
        .lineTo(0, newCanvasSize - 28)

      this.mouseCursorLine
        .clear()
        .lineStyle(2, MOUSE_CURSOR_COLOR)
        .moveTo(0, 16)
        .lineTo(0, newCanvasSize - 12)
    }

    const exs = this._spansToCoordSpace()
    const rps = this._marksToCoordSpace()

    Object.entries(this.graphs).forEach(([t, g]) => {
      if (!this.props.types.includes(t)) {
        this.graphsContainer.removeChild(g.container)
        delete this.graphs[t]

        this.subgraphStaticArtifactContainer.removeChild(this.subgraphStaticArtifacts[t])
        delete this.subgraphStaticArtifacts[t]
      }
    })

    // If a type is added (e.g. Prediction) and we didn't already
    // have it in the cut data we need to update the cutData
    let needUpdateData = false
    for (const t of this.props.types) {
      if (this.props.sensordata[t] && !this.cutData[t]) {
        needUpdateData = true
      }
    }

    if (updateCuts || updateAccelerationGraph || updateOverlay || needUpdateData) {
      let data: SensorData = this.props.sensordata

      const cutAxis = (axis: number[]): number[] => {
        let res = [...axis]
        let removed = 0
        for (const cut of this.props.cuts) {
          const start = cut.time_start * DATA_FREQUENCY
          const end = cut.time_end * DATA_FREQUENCY
          res.splice(start - removed, end - start)
          removed += end - start
        }
        return res
      }

      // cut data
      this.cutData = _.mapValues(data, vals => _.map(vals, axis => cutAxis(axis)))
    }

    const data: SensorData = this.cutData

    this.props.types.sort().forEach((t: string, idx: number) => {
      if (!this.graphs[t] && data[t]) {
        const g = new Graph({
          container: new PIXI.Container(),
          data: data[t],
          interactionmanager: this.pixiapp!.renderer.plugins.interaction,
          editMark: this.props.editMark,
          editSpan: this.props.editSpan,
          cuts: this.props.cuts,
          color: defaultColors,
        })
        g.container.name = `graph (${t})`
        this.graphs[t] = g
        this.graphsContainer.addChild(g.container)

        const artifacts = this._makeSubgraphArtifacts(t)
        this.subgraphStaticArtifacts[t] = artifacts
        this.subgraphStaticArtifactContainer.addChild(this.subgraphStaticArtifacts[t])
      }
      if (!data[t]) {
        if (this.graphs[t]) {
          const g = this.graphs[t]
          this.graphsContainer.removeChild(g.container)
          delete this.graphs[t]

          this.subgraphStaticArtifactContainer.removeChild(this.subgraphStaticArtifacts[t])
          delete this.subgraphStaticArtifacts[t]
        }
        return
      }

      this.subgraphStaticArtifacts[t].y = idx * 200 + GRAPH_PADDING_TOP
      this.graphs[t].container.y = idx * 200
      const cutMarks = this.props.cuts.map((cut, idx) => {
        return [`${idx}`, { pos: this.toCoord(cut.time_start), len: cut.time_end - cut.time_start }]
      })
      const cutMarksMap = Object.fromEntries(cutMarks)
      this.graphs[t].updateMarkers(exs, rps, cutMarksMap)

      if (updateOverlay) {
        // overlay
        const overlayExecution =
          this.props.prediction?.map(part => ({
            time_start: part.time_start,
            time_end: part.time_end,
            type: part.exercise.type,
          })) ?? []

        this.graphs[t].updateOverlay(overlayExecution, true)
      }
    })

    if (updateAccelerationGraph) {
      const linearAccelerationType = "Linear Acceleration"
      if (_.has(data, linearAccelerationType)) {
        this.graphs[linearAccelerationType]?.redrawLines(data[linearAccelerationType]!, defaultColors)
      }
    }

    if (updateCuts) {
      this.props.types.sort().forEach((t: string, idx: number) => {
        this.graphs[t].cuts = this.props.cuts
        this.graphs[t]?.redrawLines(data[t], defaultColors)
      })
    }

    Object.entries(this.props.visibleAxes).forEach(([t, idxes]) => {
      this.graphs[t]?.updateLines(idxes)
    })
    const currentCursorCoord = this.viewport!.left + this.graphCursorCoord
    const expectedCursorCoord = this.toCoord(this.props.graphCursorPos)
    if (currentCursorCoord !== expectedCursorCoord) {
      this.viewport!.moveCorner(expectedCursorCoord - this.graphCursorCoord, 0)
      this.graphCursorText!.text = formatSeconds(this.props.graphCursorPos)
      Object.values(this.graphs).forEach(g => g.updateCursor(expectedCursorCoord))
    }
  }

  _makeSubgraphArtifacts(sensortype: string): PIXI.DisplayObject {
    const bg = new PIXI.Graphics()
      .beginFill(GRAPH_DATA_BACKGROUND_COLOR)
      .drawRect(0, 0, this.pixiapp!.screen.width, GRAPH_HEIGHT)
      .endFill()

    this.resizableGraphics[sensortype] = bg

    const label = new PIXI.Text(sensortype, { fontSize: 15 })
    label.x = 16
    label.anchor.y = 1.5

    const artifacts = new PIXI.Container()
    artifacts.addChild(bg, label)

    return artifacts
  }

  componentWillUnmount() {
    this.pixiapp?.destroy()
    window.removeEventListener("resize", this.handleResize)
  }

  componentDidUpdate(prevProps: OwnProps) {
    const updateOverlay = prevProps.prediction !== this.props.prediction
    const updateAccelerationGraph = prevProps.linearAccelerationGraphConfig !== this.props.linearAccelerationGraphConfig
    const updateCuts = prevProps.cuts !== this.props.cuts

    this.mouseCursorContainer.visible = this.props.rangeMode === RangeMode.CLICK

    this._updateGraphs(updateOverlay, updateAccelerationGraph, updateCuts)
  }

  _marksToCoordSpace() {
    const marks: { [uuid: string]: Mark } = this.props.marks
    return _.mapValues(marks, (m): GraphMark => {
      return {
        color: colorForMarker[m.type],
        time: this.toCoord(m.time),
      }
    })
  }

  updateRangeFromClick(x: number) {
    const time = this.toTime(x)
    if (this.props.rangeMode !== RangeMode.CLICK) {
      return
    }
    // is there a span under the cursor?
    const cursorPos = this.props.graphCursorPos
    const span = _.find(this.props.spans, ispan => !!(ispan.end && ispan.begin < cursorPos && ispan.end >= cursorPos))
    if (!span || !span.end) {
      return
    }

    // which side are we on?
    const mid = span.begin + (span.end - span.begin) / 2
    if (time < mid) {
      // Begin side
      this.props.editSpan({
        id: span.id,
        values: {
          begin: time,
        },
      })
    } else if (time > mid) {
      // End side
      this.props.editSpan({
        id: span.id,
        values: {
          end: time,
        },
      })
    }
  }

  colorForSpan(s: InflatedSpan): string | undefined {
    if (s.ignoredBy.includes("user") && s.ignoredBy.includes("ml")) {
      return "#ff0101"
    }
    if (s.ignoredBy.includes("ml")) {
      return "#c900ff"
    }
    if (s.ignoredBy.includes("user")) {
      return "#ff920c"
    }
  }

  _spansToCoordSpace(): Record<string, GraphSpan> {
    const exs = this.props.spans
    return _.mapValues(exs, s => ({
      label: inflatedExerciseToString(s.exercise),
      begin: this.toCoord(s.begin),
      end: s.end === undefined ? undefined : this.toCoord(s.end),
      fillColor: this.colorForSpan(s),
    }))
  }

  /*
  Convert seconds to pixels
   */
  toCoord(x: number): number {
    const t = removeCuts(x, this.props.cuts)
    return t * DATA_FREQUENCY
  }

  toTime(x: number): number {
    const t = x / DATA_FREQUENCY
    return addCuts(t, this.props.cuts)
  }

  render() {
    const height = 200 * this.props.types.length + GRAPH_PADDING_TOP
    return (
      <div style={{ height }}>
        <div
          ref={this.pixiparentref}
          style={{
            height,
            position: "absolute",
            width: "calc(100vw - 268px - 268px)",
            left: 268,
          }}
        >
          <canvas ref={this.pixiref}></canvas>
        </div>
      </div>
    )
  }
}

const connector = connect(
  (state: RootState) => ({
    marks: state.currentTaggingSession.marks,
    spans: allInflatedSpans(state),
    graphCursorPos: state.currentTaggingSession.graphCursor,
    cuts: state.currentTaggingSession.cuts,
    rangeMode: state.currentTaggingSession.rangeMode,
  }),
  {
    editMark: editRep,
    editSpan,
    setGraphCursor,
    graphClicked,
  },
)

export default connector(Graphs)
