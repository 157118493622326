import React from "react"
import { useSelector } from "react-redux"
import { selectSpanUnderCursor } from "../../../store/session/selectors"

function RepCountInfo() {
  const ispan = useSelector(selectSpanUnderCursor)
  return (
    <div>
      Current Span:&nbsp;
      {ispan ? (
        <>
          {ispan.marks.length} {ispan.exercise.def.name}
        </>
      ) : (
        <>--</>
      )}
    </div>
  )
}

export default RepCountInfo
