import firebase from "firebase/compat/app"
import _ from "lodash"
import { Exercise } from "../../../../store/types"

export const scribeFirestoreConverter = {
  toFirestore(scribe: Scribe): firebase.firestore.DocumentData {
    return _.pick(scribe, [
      "user_id",
      "started_at",
      "deleted_at",
      "duration_us",
      "user_description",
      "user_description_image_ref",

      "metadata",

      "has_processed_file",
      "has_video",
      "is_test",
      "is_description_requested",

      "blueprint",
    ])
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): Scribe {
    return unmarshalScribe(snapshot.id, snapshot.data(options))
  },
}

export enum ExerciseModifier {
  Weighted = "WEIGHTED",
  Strict = "STRICT",
  Deficit = "DEFICIT",
  Kipping = "KIPPING",
  Negative = "NEGATIVE",
}

export function unmarshalScribe(id: string, data: firebase.firestore.DocumentData): Scribe {
  if (data["blueprint"] && Object.keys(data["blueprint"]).length === 0) {
    // Fix for data created before fixing:
    // https://app.shortcut.com/glasswingdev/story/1289/promote-should-not-create-empty-blueprints
    delete data["blueprint"]
  }
  return {
    id,

    user_id: data["user_id"],
    started_at: data["started_at"],
    deleted_at: data["deleted_at"],
    duration_us: data["duration_us"] || 0,
    user_title: data["user_title"],
    user_description: data["user_description"],
    user_description_image_ref: data["user_description_image_ref"],
    user_edited_blueprint: data["user_edited_blueprint"],
    proposal_description: data["proposal_description"],

    metadata: data["metadata"],
    tags: data["tags"],
    process_version: data["process_version"] || 0,
    feedback: data["feedback"],
    clone_of: data["clone_of"],

    has_processed_file: data["has_processed_file"] || false,
    has_video: data["has_video"] || false,
    is_test: data["is_test"] || false,
    is_description_requested: data["is_description_requested"] || false,

    // If execution is not set, prediction is read from the legacy field prediction_manual
    execution: unmarshalExecution(data["execution"] || data["prediction_manual"]),
    exercise_types: data["exercise_types"],
    has_execution: data["has_execution"] || false,
    execution_proposal_id: data["execution_proposal_id"] || "",
    training_proposal_id: data["training_proposal_id"],

    events: data["events"],
    points: data["points"],

    blueprint: data["blueprint"],
  }
}

export function marshalExecution(exec?: WorkoutExecution): firebase.firestore.DocumentData {
  if (!exec) {
    return []
  }

  const marshalled = exec
    .map(part => ({
      time_start: part.time_start,
      time_end: part.time_end,
      exercise: marshalExercise(part.exercise),
      volume2: part.volume2,
      alts: part.alts?.map(p => ({
        ...p,
        exercise: marshalExercise(p.exercise),
      })),
      validation: part.validation,
      tags: part.tags,
      ignored_by: part.ignored_by,
    }))
    // Firebase doesn't allow undefined values.
    .map(part => _.pickBy(part, v => v !== undefined))

  return marshalled
}

export function marshalEquipment(eq: Equipment): firebase.firestore.DocumentData {
  if (!eq.measure) {
    return { type: eq.type }
  }

  return {
    type: eq.type,
    measure: `${eq.measure.value} ${eq.measure.unit}`,
  }
}

export function unmarshalExecution(exec: firebase.firestore.DocumentData): WorkoutExecution | undefined {
  if (!Array.isArray(exec)) {
    return undefined
  }

  return exec.map(part => {
    let exercise: Exercise = {
      type: part["type"] ?? "unknown",
    }
    if (part["exercise"]) {
      exercise = unmarshalExercise(part["exercise"])
    }

    let tags: WorkoutExecutionPartTag[] | undefined = undefined
    if (part["tags"] && Array.isArray(part["tags"])) {
      tags = part["tags"]
        .map(t => ({
          type: t["type"],
          time: t["time"],
          //value: t["value"],
        }))
        .filter(t => t !== undefined) as WorkoutExecutionPartTag[]
    }
    if (tags === undefined && Array.isArray(part["reps"])) {
      // Reps is the legacy version of tags. Use only if no tags are found.
      tags = part["reps"].map(r => ({ type: "rep", time: r }))
    }

    // volume
    let volume: Measure | undefined = undefined
    if (part["volume2"]) {
      volume = {
        unit: part["volume2"]["unit"],
        value: part["volume2"]["value"],
      }
    }

    // alts
    let alts: WorkoutExecutionPartAlt[] = []
    if (part["alts"]) {
      for (const alt of part["alts"]) {
        alts.push({
          exercise: unmarshalExercise(alt["exercise"]),
          score: alt["score"],
        })
      }
    }

    return {
      time_start: part["time_start"],
      time_end: part["time_end"],
      ignored_by: part["ignored_by"],
      exercise: exercise,
      volume2: volume,
      alts: alts,
      tags: tags,
      validation: part["validation"],
    } as WorkoutExecutionPart
  })
}

export function marshalExercise(exercise: Exercise) {
  return {
    type: exercise.type,
    equipment: exercise.equipment?.map(eq => marshalEquipment(eq)) || [],
    modifiers: exercise.modifiers?.map(m => m.valueOf().toUpperCase()) || [],
  }
}

export function unmarshalExercise(fq: any): Exercise {
  let modifiers: ExerciseModifier[] | undefined = undefined

  if (fq["modifiers"] && Array.isArray(fq["modifiers"])) {
    modifiers = fq["modifiers"]
      .map(m => m && (m as String).toUpperCase())
      .map(m => {
        switch (m) {
          case "DEFICIT":
            return ExerciseModifier.Deficit
          case "KIPPING":
            return ExerciseModifier.Kipping
          case "STRICT":
            return ExerciseModifier.Strict
          case "WEIGHTED":
            return ExerciseModifier.Weighted
          case "NEGATIVE":
            return ExerciseModifier.Negative
        }
        return undefined
      })
      .filter(m => m !== undefined) as ExerciseModifier[]
  }

  let equipment: Equipment[] | undefined = undefined
  if (fq["equipment"] && Array.isArray(fq["equipment"])) {
    equipment = fq["equipment"].map(eq => ({
      type: eq["type"],
      measure: unmarshalMeasure(eq["measure"]),
    }))
  }

  return {
    type: fq["type"],
    modifiers: modifiers,
    equipment: equipment,
  }
}

export function unmarshalMeasure(m?: string): Measure | undefined {
  if (!m) {
    return undefined
  }

  const pattern = /^(\d+(?:\.\d+)?) ?(\w+)/
  const matches = m.match(pattern)

  if (matches === null || matches.length === 0) {
    return undefined
  }

  return {
    value: parseFloat(matches[1]),
    unit: matches[2],
  }
}

export interface Scribe {
  id: string

  user_id?: string
  started_at: firebase.firestore.Timestamp
  deleted_at?: firebase.firestore.Timestamp
  duration_us: number
  user_title?: string
  user_description?: string
  user_description_image_ref?: string
  user_edited_blueprint?: any
  proposal_description?: string

  metadata?: Metadata
  tags?: string[]
  process_version: number
  feedback?: string

  clone_of?: string

  has_processed_file: boolean
  has_video: boolean
  is_test: boolean
  is_description_requested: boolean

  execution?: WorkoutExecution
  exercise_types?: string[]
  has_execution: boolean
  execution_proposal_id: string
  training_proposal_id?: string

  events?: Event[]
  points?: number

  blueprint?: Blueprint
}

interface Blueprint {
  class: BlueprintClass
  exercises: string[]
  rep_scheme_type: BlueprintRepSchemeType
  rep_scheme: number[]
  rounds: number
}

enum BlueprintClass {
  ForTime = "FOR TIME",
  AMRAP = "AMRAP",
  EMOM = "EMOM",
}

enum BlueprintRepSchemeType {
  IntraRoundInvariate = "INTRA ROUND INVARIATE",
  InterRoundInvariate = "INTER ROUND INVARIATE",
  Invariate = "INVARIATE",
  Unstructured = "UNSTRUCTURED",
}

interface Metadata {
  app_origin?: string
  watch_model: string
  watch_operating_system: string
  watch_wear_position: string
  wodscribe_app_version: string
}

export type WorkoutExecution = WorkoutExecutionPart[]

export interface WorkoutExecutionPartAlt {
  exercise: Exercise
  score: number
}

export interface WorkoutExecutionPartValidation {
  span: boolean
  exercise: boolean
  rep_count: boolean
  rep_times: boolean
}

export interface WorkoutExecutionPart {
  time_start: number
  time_end: number

  exercise: Exercise

  tags?: WorkoutExecutionPartTag[]

  ignored_by?: string[]

  volume2?: Measure
  alts?: WorkoutExecutionPartAlt[]
  validation?: WorkoutExecutionPartValidation
}

interface WorkoutExecutionPartTag {
  type: string
  time: number
  // value?: number
}

interface Equipment {
  type: string
  measure?: Measure
}

export interface Measure {
  value: number
  unit: string
}

interface Event {
  time: number
  type: string
}
