import firebase from "firebase/compat/app"
import _ from "lodash"

export const commentFirestoreConverter = {
  toFirestore(comment: Comment): firebase.firestore.DocumentData {
    return _.omit(comment, "id")
  },

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): Comment {
    const data = snapshot.data(options)
    return { ...data, id: snapshot.id } as Comment
  },
}

export interface Comment {
  id?: string
  user_id: string
  content: string
  timestamp?: firebase.firestore.Timestamp
}
