export function formatSeconds(s: number): string {
  if (s < 0) {
    return "--:--"
  }

  let displaySeconds = `${Math.floor(s % 60)}`
  if (displaySeconds.length === 1) {
    displaySeconds = `0${displaySeconds}`
  }

  const minutes = Math.floor(s / 60)
  if (minutes < 60) {
    return `${minutes}:${displaySeconds}`
  }

  let displayMinutes = `${minutes % 60}`
  if (displayMinutes.length === 1) {
    displayMinutes = `0${displayMinutes}`
  }
  return `${Math.floor(minutes / 60)}:${displayMinutes}:${displaySeconds}`
}

export function formatMicroSeconds(us: number): string {
  let s = Math.floor(us / 1000000)
  return formatSeconds(s)
}
