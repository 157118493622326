import { Box, Checkbox, FormControlLabel, TableHead, Typography } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Button from "@mui/material/Button"
import { Link } from "react-router-dom"
import React, { useCallback } from "react"
import { firestoreUrl } from "../../utils/links"
import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import { formatSeconds } from "../../utils/format"
import { InflatedPartData } from "../../store/verify/reducer"
import FormGroup from "@mui/material/FormGroup"
import ValidationToggles from "./ValidationToggles"

interface SmallButtonLinkProps {
  children?: React.ReactNode
  target?: React.HTMLAttributeAnchorTarget
  to: string
}

function SmallButtonLink({ children, target, to }: SmallButtonLinkProps) {
  return (
    <Button
      sx={{ fontSize: "0.7rem", px: 1, py: 0 }}
      variant="outlined"
      size="small"
      component={Link}
      to={to}
      target={target}
    >
      {children}
    </Button>
  )
}

interface OwnProps {
  part: InflatedPartData
  type: string
  cls: string
}

function VerifyPartDataCard({ part, type, cls }: OwnProps) {
  const offsetSeconds = (part.offset + part.padding) / 1_000_000
  const alts = part.part.alts
  return (
    <TableContainer component={Paper}>
      <Typography variant="h4">Info</Typography>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>Validation</TableCell>
            <TableCell colSpan={2}>
              <ValidationToggles part={part} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Scribe</TableCell>
            <TableCell>{part.info.scribeID}</TableCell>
            <TableCell>
              <SmallButtonLink target="_blank" to={firestoreUrl(`scribes/${part.info.scribeID}`)}>
                Firestore
              </SmallButtonLink>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Proposal</TableCell>
            <TableCell>{part.info.proposalID}</TableCell>
            <TableCell>
              <SmallButtonLink
                target="_blank"
                to={firestoreUrl(`scribes/${part.info.scribeID}/execution_proposals/${part.info.proposalID}`)}
              >
                Firestore
              </SmallButtonLink>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Part</TableCell>
            <TableCell colSpan={2}>{part.info.partIdx}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Offset</TableCell>
            <TableCell>{formatSeconds(offsetSeconds)}</TableCell>
            <TableCell>
              <SmallButtonLink target="_blank" to={`/scribe/${part.info.scribeID}?time=${offsetSeconds + 1}`}>
                Tagging&nbsp;UI
              </SmallButtonLink>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Length</TableCell>
            <TableCell colSpan={2}>{part.length}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Index</TableCell>
            <TableCell>
              {type} / {cls} / {part.info.scribeID}
            </TableCell>
            <TableCell>
              <SmallButtonLink target="_blank" to={firestoreUrl(`tagging_index/${type}/${cls}/${part.info.scribeID}`)}>
                Firestore
              </SmallButtonLink>
            </TableCell>
          </TableRow>
          {alts && (
            <TableRow>
              <TableCell>Alts</TableCell>
              <TableCell>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>Score</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {alts.map((alt, idx) => (
                      <TableRow key={idx}>
                        <TableCell>{alt.exercise.type}</TableCell>
                        <TableCell>{alt.score.toFixed(3)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>Ignored</TableCell>
            <TableCell>{String(part.ignored)}</TableCell>
          </TableRow>
          {/*<TableRow>*/}
          {/*  <TableCell colSpan={3}>*/}
          {/*    <pre>{JSON.stringify(part.part, null, 2)}</pre>*/}
          {/*  </TableCell>*/}
          {/*</TableRow>*/}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default VerifyPartDataCard
