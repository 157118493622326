import firebase from "firebase/compat/app"
import "firebase/compat/analytics"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"
import Storage from "./storage"
import Firestore from "./firestore"

firebase.initializeApp({
  apiKey: "AIzaSyDJoGCEMPOzXVqiGZJ7qmZj925J1z5Jmag",
  authDomain: "wod-scribe.firebaseapp.com",
  databaseURL: "https://wod-scribe.firebaseio.com",
  projectId: "wod-scribe",
  storageBucket: "wod-scribe.appspot.com",
  messagingSenderId: "966817540637",
  appId: "1:966817540637:web:3192c589d243821750f4f4",
  measurementId: "G-091BLC0WP5",
})

firebase.analytics()

const FirestoreService = new Firestore()
const StorageService = new Storage()

export { FirestoreService, StorageService }
