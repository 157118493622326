import { useScribeAuditLogListener } from "../../hooks/firestore-listener"
import { ScribeAuditLog as firestoreLog } from "../../services/firebase/firestore/types/scribe-auditlog"
import dayjs from "dayjs"
import { Box, Divider, Tooltip, Typography } from "@mui/material"

function formatDatetime(d?: Date): string {
  return dayjs(d).format("MMM D, YYYY - HH:mm")
}

function formatDate(d?: Date): string {
  return dayjs(d).format("MMM D, YYYY")
}

interface AuditLogProps {
  log: firestoreLog
}

function AuditLog({ log }: AuditLogProps) {
  return (
    <Tooltip
      arrow
      placement="right"
      title={
        <>
          <Typography>{formatDatetime(log.timestamp.toDate())}</Typography>
          <Typography variant="body2" component="pre">
            {log.value}
          </Typography>
        </>
      }
    >
      <Box display="flex">
        <Box flexGrow={1}>{formatDate(log.timestamp.toDate())}</Box>
        <Box>{log.field}</Box>
      </Box>
    </Tooltip>
  )
}

interface OwnProps {
  scribeID: string
}

function ScribeAuditLog({ scribeID }: OwnProps) {
  const logs = useScribeAuditLogListener(scribeID)

  return (
    <>
      {logs.map((l, idx) => (
        <div key={l.id}>
          <AuditLog log={l} />
          {idx !== logs.length - 1 && <Divider />}
        </div>
      ))}
    </>
  )
}

export default ScribeAuditLog
