import * as PIXI from "pixi.js"
import { defaultColors, drawLines } from "../lines"
import Markers from "../markers"
import { InflatedPartData } from "../../store/verify/reducer"
import { BeginEnd } from "../utils"
import _ from "lodash"
import { DATA_FREQUENCY } from "../../utils/constants"
import { InflatedExercise, inflatedExerciseToString } from "../../store/types"
import { VerifyRep, verifyRepMarkers, VerifySpan, verifySpanHints, verifySpanMarkers } from "./markers"

export type EditSpanCallback = (part: InflatedPartData, change: BeginEnd) => void
export type EditMarkCallback = (part: InflatedPartData, id: string, time: number) => void

interface Params {
  part: InflatedPartData
  types: string[]
  interactionManager: PIXI.InteractionManager
  onChangeSpan: EditSpanCallback
  onChangeMark: EditMarkCallback
}

class PartGraph {
  part: InflatedPartData
  container: PIXI.Container

  private readonly onChangeSpan: EditSpanCallback
  private readonly onChangeMark: EditMarkCallback

  private spanMarkers: Markers<VerifySpan>
  private repMarkers: Markers<VerifyRep>
  private spanHints: Markers<VerifySpan>

  constructor(params: Params) {
    this.part = params.part
    this.onChangeSpan = params.onChangeSpan
    this.onChangeMark = params.onChangeMark

    this.container = new PIXI.Container()
    this.container.name = "Part"

    const height = 100
    let yOffset = 0
    const yPadding = 20

    for (const type of params.types) {
      const typeContainer = this._buildType(type, height)
      typeContainer.y = yOffset
      yOffset += height + yPadding
      this.container.addChild(typeContainer)
    }

    // inflate spans

    // markers
    this.spanMarkers = verifySpanMarkers(params.interactionManager, (id, vals) => {
      // send callback
      // TODO: handle splitting / delete span
      this.onChangeSpan(
        this.part,
        _.mapValues(vals, v => (v ? this.toTime(v) : v)),
      )
    })
    this.repMarkers = verifyRepMarkers(params.interactionManager, (id, pos) => {
      this.onChangeMark(this.part, id, this.toTime(pos))
    })
    this.spanHints = verifySpanHints()

    this.spanMarkers.container.name = "span markers"
    this.repMarkers.container.name = "rep markers"
    this.spanHints.container.name = "span hints"

    this.container.addChild(this.spanMarkers.container, this.repMarkers.container, this.spanHints.container)
  }

  private spansToCoordSpace(exercises: { [p: string]: InflatedExercise }): Record<string, VerifySpan> {
    const exs = this.part.spans
    return _.mapValues(exs, s => {
      const ex = exercises[s.ref]

      return {
        label: ex ? inflatedExerciseToString(ex) : s.ref,
        begin: this.toCoord(s.begin),
        end: this.toCoord(s.end!),
        verified_begin: s.verified_begin,
        verified_end: s.verified_end,
        verified_exercise: s.verified_exercise,
        ignored: s.ignored,
      }
    })
  }

  private marksToCoordSpace(): Record<string, VerifyRep> {
    const marks = this.part.marks
    return _.mapValues(marks, (m): VerifyRep => {
      return {
        verified: m.verified ?? false,
        time: this.toCoord(m.time),
      }
    })
  }

  toCoord(t: number): number {
    const offset = this.part.offset / 1_000_000
    return (t - offset) * DATA_FREQUENCY
  }

  toTime(x: number): number {
    const t = x / DATA_FREQUENCY
    const offset = this.part.offset / 1_000_000
    return t + offset
  }

  updateData(part: InflatedPartData, exercises: { [p: string]: InflatedExercise }) {
    this.part = part
    let spans = this.spansToCoordSpace(exercises)
    this.spanMarkers.update(spans)
    this.spanHints.update(spans)
    this.repMarkers.update(this.marksToCoordSpace())
  }

  _buildType(type: string, height: number): PIXI.Container {
    const typeContainer = new PIXI.Container()
    typeContainer.name = type

    const sd = this.part.subData[type]
    const lines = drawLines(sd, height, 2, 1, defaultColors)
    lines.name = "sensor data"
    //lines.interactive = false
    //lines.cacheAsBitmap = true

    const width = lines.width
    const bg = new PIXI.Graphics().beginFill(PIXI.utils.string2hex("#d9dddc")).drawRect(0, 0, width, height).endFill()
    bg.name = "bg"
    typeContainer.addChild(bg, lines)

    return typeContainer
  }
}

export default PartGraph
