import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import UserInfo from "../../components/UserInfo"
import { useCallback, useEffect, useState } from "react"
import { User } from "../../services/firebase/firestore/types"
import { Link, useSearchParams } from "react-router-dom"
import { styled } from "@mui/styles"
import { firestoreUrl } from "../../utils/links"

function UserList() {
  const [searchParams, setSearchParams] = useSearchParams()
  const users = useSelector((state: RootState) => state.users.users)
  const [filtered, setFiltered] = useState<User[]>([])

  useEffect(() => {
    const search = searchParams.get("search") ?? ""
    const allUsers = Object.values(users)

    if (search === "") {
      setFiltered(allUsers)
      return
    }
    setFiltered(
      allUsers.filter(user => {
        return user.id.includes(search) || user.name?.toLowerCase().includes(search)
      }),
    )
  }, [searchParams, users, setFiltered])

  const searchFor = useCallback(
    (search: string) => {
      search = search.toLowerCase()
      setSearchParams(search !== "" ? { search: search } : {})
    },
    [setSearchParams],
  )

  if (!filtered) {
    return <CircularProgress />
  }

  return (
    <Paper square>
      <Box m={2} alignItems="center" justifyContent="space-between" sx={{ display: "flex" }}>
        <TextField label="Search" value={searchParams.get("search")} onChange={e => searchFor(e.target.value)} />
        <Box m={2}>
          {filtered.length} out of {Object.values(users).length}
        </Box>
      </Box>
      <Paper>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Info</TableCell>
                <TableCell align="right">Scribes</TableCell>
                <TableCell align="right">Reps</TableCell>
                <TableCell align="right">Points</TableCell>
                <TableCell align="right">Links</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filtered.map(user => {
                const userID = user.id
                return (
                  <TableRow key={userID}>
                    <TableCell>
                      <UserInfo userID={userID} />
                    </TableCell>
                    <TableCell align="right">{user.totals?.scribes ?? 0}</TableCell>
                    <TableCell align="right">{user.totals?.reps ?? 0}</TableCell>
                    <TableCell align="right">{user.totals?.points ?? 0}</TableCell>
                    <TableCell align="right">
                      <Button
                        sx={{ mx: 2, fontSize: "0.7rem", px: 1, py: 0 }}
                        variant="outlined"
                        size="small"
                        target="_blank"
                        rel="noreferrer"
                        href={firestoreUrl(`users/${userID}`)}
                      >
                        Firestore
                      </Button>
                      <Button
                        sx={{ mx: 2, fontSize: "0.7rem", px: 1, py: 0 }}
                        variant="outlined"
                        size="small"
                        component={Link}
                        to={`/scribes/byuser?userid=${userID}`}
                      >
                        Scribes
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Paper>
  )
}

export default UserList
