import React from "react"
import ThumbUpIcon from "@mui/icons-material/ThumbUp"
import ThumbDownIcon from "@mui/icons-material/ThumbDown"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import { Box, Theme, Tooltip } from "@mui/material"

interface OwnProps {
  feedback?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flip: {
      transform: "scaleX(-1)",
    },
  }),
)

function FeedbackIndicator({ feedback }: OwnProps) {
  const classes = useStyles()
  return (
    <Box sx={{ display: "inline", mx: 1 }}>
      {feedback === "good" && (
        <Tooltip title="Positive user feedback">
          <ThumbUpIcon htmlColor="#66D19E" fontSize="small" />
        </Tooltip>
      )}
      {feedback === "bad" && (
        <Tooltip title="Negative user feedback">
          <ThumbDownIcon className={classes.flip} htmlColor="#F1998E" fontSize="small" />
        </Tooltip>
      )}
      {/* Hidden icon just to get correct spacing */}
      {!feedback && <ThumbDownIcon htmlColor="#FFFFFF" fontSize="small" />}
    </Box>
  )
}

export default FeedbackIndicator
