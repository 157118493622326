import React from "react"
import { Scribe } from "../../services/firebase/firestore/types"
import { formatMicroSeconds } from "../../utils/format"

interface OwnProps {
  scribe: Scribe
}

function ScribeDuration({ scribe }: OwnProps) {
  return <>{formatMicroSeconds(scribe.duration_us)}</>
}

export default ScribeDuration
