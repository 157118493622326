import { RootState } from "../index"
import { createSelector } from "reselect"
import { Exercise, InflatedExercise } from "../types"
import _ from "lodash"
import {
  EquipmentDefinitions,
  ExerciseDefinitions,
  getEquipmentDef,
} from "../../services/firebase/firestore/types/definitions"
import { InflatedPartData } from "./reducer"

function inflateExercise(
  id: string,
  ex: Exercise,
  exerciseDefs: ExerciseDefinitions,
  equipmentDefs: EquipmentDefinitions,
): InflatedExercise {
  return {
    id: id,
    def: exerciseDefs[ex.type] || { id: ex.type, name: ex.type },
    modifiers: ex.modifiers,
    equipment: ex.equipment?.map(eq => ({
      def: getEquipmentDef(equipmentDefs, eq.type),
      measure: eq.measure,
    })),
  }
}

export const selectParts = (state: RootState) => state.verifySession.parts
export const selectCursorOffset = (state: RootState) => state.verifySession.cursorOffset
export const selectCursorPartOffset = (state: RootState) => state.verifySession.cursorPartOffset
export const selectInflight = (state: RootState) => state.verifySession.inflight
export const selectDirty = (state: RootState) => state.verifySession.dirty

export const selectCursorPart = (state: RootState): InflatedPartData | undefined => {
  if (state.verifySession.cursorPartIdx < 0) {
    return undefined
  }
  return state.verifySession.parts[state.verifySession.cursorPartIdx]
}

export const selectMainExercise = createSelector(
  (state: RootState) => state.verifySession.mainExercise,
  (state: RootState) => state.definitions.exercises,
  (state: RootState) => state.definitions.equipment,
  (
    mainExercise,
    exerciseDefs,
    equipmentDefs,
  ):
    | {
        key: string
        exercise: InflatedExercise
      }
    | undefined => {
    if (!mainExercise) {
      return undefined
    }
    return {
      key: mainExercise.key,
      exercise: inflateExercise(mainExercise.key, mainExercise.exercise, exerciseDefs, equipmentDefs),
    }
  },
)

export const selectAllExercises = createSelector(
  (state: RootState) => state.currentTaggingSession.exercises,
  (state: RootState) => state.verifySession.mainExercise,
  (state: RootState) => state.verifySession.inUseExercises,
  (state: RootState) => state.verifySession.ephemeralExercises,
  (state: RootState) => state.definitions.exercises,
  (state: RootState) => state.definitions.equipment,
  (
    exercises,
    mainExercise,
    inUseExercises,
    ephemeralExercises,
    exerciseDefs,
    equipmentDefs,
  ): {
    [id: string]: InflatedExercise
  } => {
    let all = { ...exercises, ...inUseExercises, ...ephemeralExercises }
    if (mainExercise) {
      all = { ...all, [mainExercise.key]: mainExercise.exercise }
    }
    return _.mapValues(all, (ex, id) => inflateExercise(id, ex, exerciseDefs, equipmentDefs))
  },
)

export const selectEphemeralExercises = createSelector(
  (state: RootState) => state.verifySession.ephemeralExercises,
  (state: RootState) => state.definitions.exercises,
  (state: RootState) => state.definitions.equipment,
  (ephemeralExercises, exerciseDefs, equipmentDefs): { [id: string]: InflatedExercise } => {
    return _.mapValues(ephemeralExercises, (ex, id) => inflateExercise(id, ex, exerciseDefs, equipmentDefs))
  },
)
