import { FirestoreService, StorageService } from "../../services/firebase"
import { ProcessedSensordataFile } from "../../services/firebase/storage/types/processed-sensors"
import { DATA_FREQUENCY } from "../../utils/constants"
import { sensorTypeToSensorName } from "../../utils/sensorType"
import _ from "lodash"
import { PartData } from "../../store/types/verify"

class SensorCache {
  private values: Map<string, ProcessedSensordataFile> = new Map()
  private maxItems = 10

  public async get(scribeID: string): Promise<ProcessedSensordataFile> {
    const hasKey = this.values.has(scribeID)
    let entry: ProcessedSensordataFile
    if (hasKey) {
      entry = this.values.get(scribeID)!
      // Map iterate in insertion-order, so we remove this item and inserts it again
      this.values.delete(scribeID)
      this.values.set(scribeID, entry)
      return Promise.resolve(entry)
    } else {
      const processed = await StorageService.fetchProcessedSensorFile(scribeID)
      // insert
      if (this.values.size >= this.maxItems) {
        // delete lru
        this.values.delete(this.values.keys().next().value)
      }
      this.values.set(scribeID, processed)
      return processed
    }
  }
}

const cache = new SensorCache()

export const VERIFY_PADDING = 5 // seconds

export async function loadPart(scribeID: string, proposalID: string, partIdx: number): Promise<PartData> {
  // start fetching processed
  const processedPromise = cache.get(scribeID)

  const scribe = await FirestoreService.getScribe(scribeID)
  // get the proposal
  const proposal = await FirestoreService.getProposal(scribeID, proposalID)
  if (!proposal) {
    throw new Error("proposal not found")
  }

  const exec = proposal.execution
  const part = exec[partIdx]

  const sensorFile = await processedPromise

  const padding = VERIFY_PADDING * 1_000_000

  const max_length = Object.values(sensorFile.sensors.data)[0].length
  const max_time = (max_length / DATA_FREQUENCY) * 1_000_000

  const time_start = Math.max(part.time_start - padding, 0)
  const time_end = Math.min(part.time_end + padding, max_time)

  const start = (time_start / 1_000_000) * DATA_FREQUENCY
  const end = (time_end / 1_000_000) * DATA_FREQUENCY

  // select data in range
  const subData = _.mapValues(sensorFile.sensors.data, (data, type) => {
    return data.slice(start, end)
  })

  // todo: pull out process in a separate file

  const unzipped = _.mapValues(subData, d => _.unzip(d))

  const normalized = _.mapValues(unzipped, data => {
    let [minval, maxval] = data
      .map((axis, idx) => {
        const minval = axis.reduce((acc, v) => (acc < v ? acc : v))
        const maxval = axis.reduce((acc, v) => (acc > v ? acc : v))
        return [minval, maxval]
      })
      .reduce((res, [minval, maxval]) => {
        const combinedminval = Math.min(res[0], minval)
        const combinedmaxval = Math.max(res[1], maxval)
        const amp = Math.max(Math.abs(combinedminval), Math.abs(combinedmaxval))
        return [-amp, amp]
      })
    return data.map(axis => {
      return axis.map(p => (p - minval) / (maxval - minval))
    })
  })

  // convert keys to strings
  const keyedSensorData = _.mapKeys(normalized, (v, t) => sensorTypeToSensorName(Number(t)))

  return {
    subData: keyedSensorData,
    description: scribe?.proposal_description ?? "",
    info: {
      scribeID: scribeID,
      proposalID: proposalID,
      partIdx: partIdx,
    },
    part: part,
    offset: time_start,
    padding: padding,
    length: end - start,
  }
}
