import React from "react"
import { Theme } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { RootState } from "../../store"
import { useSelector } from "react-redux"
import { Scribe } from "../../services/firebase/firestore/types"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    secondarytext: {
      color: theme.palette.text.secondary,
      fontSize: "0.8em",
    },
    h2: {
      marginTop: 40,
      marginBottom: 40,
    },
    clickableRow: {
      cursor: "pointer",
    },
  }),
)

interface OwnProps {
  scribes: Scribe[] | undefined
  wrappedComponent: any
}

function ScribesListContainer({ scribes, wrappedComponent }: OwnProps) {
  const users = useSelector((state: RootState) => state.users.users)

  const classes = useStyles()

  if (scribes === undefined || users === undefined) {
    return (
      <Grid container item justifyContent="center">
        <CircularProgress />
      </Grid>
    )
  }

  if (scribes.length === 0) {
    return (
      <Typography variant="h2" className={classes.h2}>
        No Scribes
      </Typography>
    )
  }

  const ChildComp = wrappedComponent
  return <ChildComp scribes={scribes} users={users} classes={classes} />
}

export default ScribesListContainer
