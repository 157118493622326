import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit"
import { startAppListening } from "../listener"

interface UserSettings {
  bumpSize: number
  loadSize: number
}

const defaultSettings: UserSettings = {
  bumpSize: 2,
  loadSize: 3,
}

export type UserSettingsUpdate = Partial<UserSettings>

interface UserSettingsState {
  settings: UserSettings
}

function loadFromLocalstorage() {
  const data = localStorage.getItem("user_settings") ?? "{}"
  return JSON.parse(data) as Partial<UserSettings>
}

const initialState: UserSettingsState = {
  settings: {
    ...defaultSettings,
    ...loadFromLocalstorage(),
  },
}

export const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    updateUserSetting: (state, action: PayloadAction<UserSettingsUpdate>) => {
      state.settings = { ...state.settings, ...action.payload }
    },
    resetUserSettings: state => {
      console.log("reset")
      state.settings = defaultSettings
    },
  },
})

startAppListening({
  matcher: isAnyOf(userSettingsSlice.actions.updateUserSetting, userSettingsSlice.actions.resetUserSettings),
  effect: (action, api) => {
    const settings = api.getState().userSettings.settings
    localStorage.setItem("user_settings", JSON.stringify(settings))
  },
})

export const { updateUserSetting, resetUserSettings } = userSettingsSlice.actions
