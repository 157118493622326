import * as PIXI from "pixi.js"
import { drawLine } from "./lines"
import _ from "lodash"
import { DATA_FREQUENCY } from "../utils/constants"
import { Span } from "../pages/speedtagging/SpeedTaggingContainer"

export const GRAPH_HEIGHT = 60
const GRAPH_SPACING = 8
const GRAPH_DATA_PADDING = 0
const STROKE_WIDTH: number = 1.5
const GRAPH_COMPRESSION: number = 0.4
const ROW_OVERLAP: number = 500
const ROWS_PER_WINDOW: number = 5

type addSpanT = (s: Span) => void
type isShowingLastDataCallbackT = (b: boolean) => void

type colorMapper = (idx: number) => string

interface ConstructorParams {
  container: PIXI.Container
  interactionmanager: PIXI.InteractionManager
  addSpan: addSpanT
  isShowingLastDataCallback: isShowingLastDataCallbackT
  color: colorMapper
  width: number
}

const lineColors = [
  "#2b3252", // Blue
  "#ef5455", // Red
  "#197a43", // Green
]

class SpeedGraph {
  container: PIXI.Container
  interactionmanager: PIXI.InteractionManager
  markings: PIXI.Graphics
  activeMarking: PIXI.Graphics
  binaryPred: PIXI.Graphics
  markedSpans: Span[]

  addSpanCallback: addSpanT
  isShowingLastDataCallback: isShowingLastDataCallbackT

  graphWidth: number
  totalGraphHeight: number
  draggingFrom: number | undefined
  numRows: number
  pointsPerRow: number
  firstRowIdx: number

  constructor(params: ConstructorParams) {
    this.container = params.container
    this.interactionmanager = params.interactionmanager
    this.graphWidth = params.width
    this.markings = new PIXI.Graphics()
    this.activeMarking = new PIXI.Graphics()
    this.binaryPred = new PIXI.Graphics()
    this.markedSpans = []

    this.firstRowIdx = 0
    this.pointsPerRow = Math.floor(this.graphWidth / GRAPH_COMPRESSION)
    this.totalGraphHeight = (GRAPH_HEIGHT + GRAPH_SPACING) * ROWS_PER_WINDOW - GRAPH_SPACING

    this.numRows = 0 // Updated when data comes in

    this._setupInteractions(params.interactionmanager)
    this.addSpanCallback = params.addSpan
    this.isShowingLastDataCallback = params.isShowingLastDataCallback
  }

  updateData(data: number[][]): void {
    this.firstRowIdx = 0
    this.numRows = Math.ceil((data[0].length - ROW_OVERLAP) / (this.pointsPerRow - ROW_OVERLAP))
    this.container.removeChildren()
    this.container.addChild(this._buildUI(data))
    this.container.addChild(this.markings)
    this.container.addChild(this.activeMarking)
    this.container.addChild(this.binaryPred)
    if (this.numRows <= ROWS_PER_WINDOW) {
      this.isShowingLastDataCallback(true)
    }
  }

  updateSpans(spans: Span[]): void {
    this.markings.clear()
    spans.forEach(s => {
      for (let r = 0; r < this.numRows; r++) {
        const startPx = this._dataIdxToPxInRow(s.a, r)
        const endPx = this._dataIdxToPxInRow(s.b, r)

        this.markings
          .beginFill(0xf96120, 0.3)
          .drawRect(startPx, r * (GRAPH_HEIGHT + GRAPH_SPACING), endPx - startPx, GRAPH_HEIGHT)
          .endFill()
      }
    })
  }

  updateBinaryPred(spans: Span[]): void {
    this.binaryPred.clear()
    if (!spans || spans.length == 0) {
      return
    }

    const numRows = Math.ceil((spans[spans.length - 1].b - ROW_OVERLAP) / (this.pointsPerRow - ROW_OVERLAP))
    spans.forEach(s => {
      for (let r = 0; r < numRows; r++) {
        const startPx = this._dataIdxToPxInRow(s.a, r)
        const endPx = this._dataIdxToPxInRow(s.b, r)

        if (startPx === endPx) {
          continue
        }

        this.binaryPred
          .beginFill(0xf96120)
          .drawRoundedRect(startPx, r * (GRAPH_HEIGHT + GRAPH_SPACING), endPx - startPx, 3, 4)
          .endFill()
      }
    })
  }

  setWindow(w: number): void {
    this.firstRowIdx = w * ROWS_PER_WINDOW
    this.container.y = -this.firstRowIdx * (GRAPH_HEIGHT + GRAPH_SPACING)
    this.isShowingLastDataCallback(this.numRows > 0 && this.firstRowIdx + ROWS_PER_WINDOW >= this.numRows)
  }

  _pointToDataIdx(x: number, y: number): number {
    const row = Math.floor(y / (GRAPH_HEIGHT + GRAPH_SPACING)) + this.firstRowIdx
    const pointsPerRow = Math.floor(this.graphWidth / GRAPH_COMPRESSION)
    return (x / this.graphWidth + row) * pointsPerRow - row * ROW_OVERLAP
  }

  _dataIdxToPxInRow(idx: number, row: number): number {
    const pointsPerRow = Math.floor(this.graphWidth / GRAPH_COMPRESSION)
    const rowStart = (pointsPerRow - ROW_OVERLAP) * row

    if (idx < rowStart) {
      return 0
    }

    const pxPerIdx = this.graphWidth / pointsPerRow
    return Math.min(this.graphWidth, (idx - rowStart) * pxPerIdx)
  }

  _setupInteractions(m: PIXI.InteractionManager) {
    const isOutsideGraph = (x: number, y: number): boolean =>
      x < 0 || y < 0 || x > this.graphWidth || y > this.totalGraphHeight

    m.on("mousedown", (e: PIXI.InteractionEvent) => {
      if (isOutsideGraph(e.data.global.x, e.data.global.y)) {
        return
      }

      if (e.data.global.y % (GRAPH_HEIGHT + GRAPH_SPACING) > GRAPH_HEIGHT) {
        // Clicked between graphs
        return
      }

      console.log("mousedown at", this._pointToDataIdx(e.data.global.x, e.data.global.y))
      this.draggingFrom = this._pointToDataIdx(e.data.global.x, e.data.global.y)
    })

    m.on("mouseup", (e: PIXI.InteractionEvent) => {
      if (!this.draggingFrom) {
        return
      }

      const pointA = Math.min(this.draggingFrom, this._pointToDataIdx(e.data.global.x, e.data.global.y))
      const pointB = Math.max(this.draggingFrom, this._pointToDataIdx(e.data.global.x, e.data.global.y))

      this.draggingFrom = undefined
      this.activeMarking.clear()

      if (isOutsideGraph(e.data.global.x, e.data.global.y)) {
        return
      }

      console.log("mouseup at", this._pointToDataIdx(e.data.global.x, e.data.global.y))

      this.addSpanCallback(new Span(pointA, pointB))
      this.markedSpans.push(new Span(pointA, pointB))

      this.markedSpans.sort((a, b) => a.a - b.a)
      const cleanedMarkedSpans: Span[] = [this.markedSpans[0]]
      this.markedSpans.forEach(s => {
        const head = cleanedMarkedSpans[cleanedMarkedSpans.length - 1].b
        if (s.a <= head) {
          cleanedMarkedSpans[cleanedMarkedSpans.length - 1].b = Math.max(head, s.b)
          return
        }

        cleanedMarkedSpans.push(s)
      })

      this.markings.clear()
      cleanedMarkedSpans.forEach(s => {
        for (let r = 0; r < this.numRows; r++) {
          const dragStartPx = this._dataIdxToPxInRow(s.a, r)
          const dragEndPx = this._dataIdxToPxInRow(s.b, r)

          this.markings
            .beginFill(0xf96120, 0.3)
            .drawRect(dragStartPx, r * (GRAPH_HEIGHT + GRAPH_SPACING), dragEndPx - dragStartPx, GRAPH_HEIGHT)
            .endFill()
        }
      })
    })

    m.on("mouseupoutside", (e: PIXI.InteractionEvent) => {
      this.draggingFrom = undefined
      // TODO Write the marking to state
      console.log("mouseupoutside at", this._pointToDataIdx(e.data.global.x, e.data.global.y))
      this.activeMarking.clear()
    })

    m.on("mousemove", (e: PIXI.InteractionEvent) => {
      if (!this.draggingFrom) {
        return
      }

      this.activeMarking.clear()

      if (isOutsideGraph(e.data.global.x, e.data.global.y)) {
        return
      }

      const currentLocation = this._pointToDataIdx(e.data.global.x, e.data.global.y)

      this.activeMarking.clear()
      for (let i = 0; i < this.numRows; i++) {
        const dragStartPx = this._dataIdxToPxInRow(this.draggingFrom, i)
        const dragEndPx = this._dataIdxToPxInRow(currentLocation, i)

        this.activeMarking
          .beginFill(0xf96120, 0.3)
          .drawRect(dragStartPx, i * (GRAPH_HEIGHT + GRAPH_SPACING), dragEndPx - dragStartPx, GRAPH_HEIGHT)
          .endFill()
      }
    })
  }

  // _buildUI(sensorData: number[][], color: (idx: number) => string): PIXI.Container {
  _buildUI(sensorData: number[][]): PIXI.Container {
    const maxVal = _.max(sensorData.map(axis => _.max(axis))) ?? 0
    const minVal = _.min(sensorData.map(axis => _.min(axis))) ?? 0
    const normalizedSensorData = _.map(sensorData, axis => _.map(axis, v => (v - minVal) / (maxVal - minVal)))

    const wrapper = new PIXI.Container()

    // Draw graph backgrounds
    const bg = new PIXI.Graphics().beginFill(0xd9dddc)
    for (let i = 0; i < this.numRows; i++) {
      const yPos = i * (GRAPH_HEIGHT + GRAPH_SPACING)
      bg.drawRect(0, yPos, this.graphWidth, GRAPH_HEIGHT)
    }
    bg.endFill()
    wrapper.addChild(bg)
    // wrapper.addChild(bg, this.markings)

    // Draw sensor data
    for (let i = 0; i < this.numRows; i++) {
      const yPos = i * (GRAPH_HEIGHT + GRAPH_SPACING)
      const dataStart = i * (this.pointsPerRow - ROW_OVERLAP)

      const lines = normalizedSensorData.map((data, idx) =>
        drawLine(
          data.slice(dataStart, dataStart + this.pointsPerRow),
          GRAPH_HEIGHT,
          GRAPH_DATA_PADDING,
          STROKE_WIDTH,
          lineColors[idx],
          GRAPH_COMPRESSION,
        ),
      )

      lines.forEach(l => (l.y = yPos))
      wrapper.addChild(...lines)
    }

    return wrapper
  }

  toCoord(x: number): number {
    return x * DATA_FREQUENCY
  }

  toTime(x: number): number {
    return x / DATA_FREQUENCY
  }
}

export default SpeedGraph
