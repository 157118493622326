import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
  isOpen: boolean
}

const slice = createSlice({
  name: "definitions",
  initialState: {
    isOpen: false,
  } as State,
  reducers: {
    setOpenState(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload
    },
  },
})

export const { setOpenState } = slice.actions
export default slice.reducer
