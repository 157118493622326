import { InflatedPartData, toggleValidation } from "../../store/verify/reducer"
import { useCallback } from "react"
import FormGroup from "@mui/material/FormGroup"
import { Checkbox, FormControlLabel } from "@mui/material"
import { formatSeconds } from "../../utils/format"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { selectAllExercises } from "../../store/verify/selectors"

interface OwnProps {
  part: InflatedPartData
}

function ValidationToggles({ part }: OwnProps) {
  const dispatch = useAppDispatch()
  const span = part.spans["main"] // TODO: no splitting yet
  const currentReps = Object.values(part.marks ?? {}).length
  const exercises = useAppSelector(selectAllExercises)
  const inflatedExercise = exercises[span.ref]

  const doToggleValidation = useCallback(
    field => {
      dispatch(
        toggleValidation({
          info: part.info,
          field,
        }),
      )
    },
    [dispatch, part],
  )

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={part.validation.span} onChange={() => doToggleValidation("span")} />}
        label={
          <>
            {formatSeconds(span.begin)} - {formatSeconds(span.end)}
          </>
        }
      />
      <FormControlLabel
        control={<Checkbox checked={part.validation.exercise} onChange={() => doToggleValidation("exercise")} />}
        label={<>{inflatedExercise.def.name}</>}
      />
      <FormControlLabel
        control={<Checkbox checked={part.validation.rep_count} onChange={() => doToggleValidation("rep_count")} />}
        label={<>{currentReps} reps</>}
      />
      <FormControlLabel
        control={<Checkbox checked={part.validation.rep_times} onChange={() => doToggleValidation("rep_times")} />}
        label="Rep Times"
      />
    </FormGroup>
  )
}

export default ValidationToggles
